import { Table } from "antd";
import React, { useState } from "react";
import { useTheme } from "../../../../hook/useTheme";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { TableTotals } from "../../../table-utils/TableTotals";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";

export default function TableProducts(props) {
  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const { useDarkMode } = useTheme();
  const redColumn = useDarkMode ? "red-column-dark" : "red-column";
  const blueColumn = useDarkMode ? "blue-column-dark" : "blue-column";
  const greenColumn = useDarkMode ? "green-column-dark" : "green-column";

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const getRowClassName = (record) => {
    if (!props?.settings?.colorDifferents) return;

    if (
      props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE &&
      !record?.qtyFromSource
    )
      return redColumn;

    if (record?.qtyFromSource) {
      if (+record.qty === 0) {
        return redColumn;
      }
      if (+record.qty - +record.qtyFromSource > 0) {
        return greenColumn;
      }
      if (+record.qty - +record.qtyFromSource < 0) {
        return blueColumn;
      }
    }
  };

  return (
    <div>
      <Table
        tableLayout="fixed"
        columns={props.columns}
        onRow={(record, rowIndex) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: props.settings?.pageSize,
        }}
        rowClassName={(record) => getRowClassName(record)}
        dataSource={props?.products}
        size={"middle"}
        onChange={onChange}
        loading={props.loading}
        title={() => {
          const totals = getTableTotals(props?.products);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(3)}
              total={totals.total.toFixed(2)}
              productsCount={
                props?.products.length ? props?.products.length : 0
              }
            />
          );
        }}
      />
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            // handleChangeQty={props.handleChangeQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
            dbTable={props.dbTable}
            afterChangeProductQty={props.afterChangeProductQty}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
