import { Button, Col, Form, Input, InputNumber, Modal, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { ModalTitle } from "../../../../../components/documents/manage-documents/modal-enter-product/ModalTitle";
import { updateNewQtyDisplayData } from "../../../../../components/documents/UI/ui-functions";
import FindedProductsTable from "../../../../../components/products/FindedProductsTable";
import { TABLES } from "../../../../../db/constants/tables";
import { getRecordByKeyValue } from "../../../../../db/dexie-db/functions";
import {
  searchProductInDb,
  searchProductInDbByTitle,
} from "../../../../../db/products-functions";
const { Search } = Input;

export default function ModalProduct(props) {
  const { messageApi, notificationApi } = useOutletContext();
  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };

  const [formBarcode] = Form.useForm();
  const [formQty] = Form.useForm();
  const [input1Ref, setInput1Focus] = UseFocus();
  const [inputQtyRef, setInputQtyFocus] = UseFocus();
  const [currentProduct, setCurrentProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [findedProducts, setFindedProducts] = useState([]);
  const [isVisibleFindedProducts, setIsVisibleFindedProducts] = useState(false);
  const [qtyInputValue, setQtyInputValue] = useState("");

  const onResetBarcode = () => {
    formBarcode.resetFields();
  };

  const onResetQtyForm = () => {
    formQty.resetFields();
  };

  const setCurrentProductFunc = async (product) => {
    const enteredProduct = await getRecordByKeyValue(
      TABLES.CURRENT_VERIFICATION,
      {
        guid: product.guid,
      }
    );
    if (enteredProduct) {
      product.enteredQty = enteredProduct.qty;
    }
    return product;
  };

  useEffect(() => {
    if (props.isModalProductOpen === true) {
      setTimeout(() => {
        setInput1Focus();
      }, 1);
    }
  }, [props.isModalProductOpen, setInput1Focus]);

  useEffect(() => {
    const processBackgroundEnteredBarcode = async (barcode) => {
      const product = await props.searchProduct(barcode);
      if (!product) return;
      props.setBackgroundEnteredBarcodeValue("");

      if (props.settings.autoAddProduct) {
        product.qty = 1;
        const newProducts = await props.addProductToDocument(
          product,
          props.products
        );
        if (!newProducts) return;

        props.setProducts(newProducts);
        return notificationApi.success({
          description: `${product.title}`,
          message: `Додано: 1`,
          duration: 3,
          placement: "bottom",
          closeIcon: false,
        });
      }

      setCurrentProduct(product);
      if (
        !product.isWeight &&
        !props.isModalProductOpen &&
        !product?.characteristics
      )
        props.setIsModalProductOpen(true);
      setTimeout(() => {
        setInputQtyFocus();
      }, 100);
    };

    if (
      props?.backgroundEnteredBarcode &&
      props?.backgroundEnteredBarcode !== ""
    ) {
      processBackgroundEnteredBarcode(props.backgroundEnteredBarcode);
    }
  }, [props?.backgroundEnteredBarcode, setInputQtyFocus]);

  const addToTable = async (product = currentProduct) => {
    if (!product) {
      messageApi.info("Не вибрано товар");
      return setInput1Focus();
    }

    const qtyInputValue = inputQtyRef?.current?.value;
    if (qtyInputValue) input1Ref.current.value = "";

    const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    const qtyFromBarcode = product.qty;

    product.qty = qtyFromBarcode ? qtyFromBarcode : enteredQty;

    const newProducts = await props.addProductToDocument(
      product,
      props.products
    );
    if (newProducts) {
      props.setProducts(newProducts);
    }
    onResetQtyForm();
    setCurrentProduct(undefined);
    setInput1Focus();

    //  const qtyInputValue = inputQtyRef?.current?.value;
    // if (qtyInputValue) input1Ref.current.value = "";

    // const existingProduct = props.products.find(
    //   (existingProduct) => product.guid === existingProduct.guid
    // );

    // if (existingProduct) {
    //   const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    //   const qtyFromBarcode = product.qty;
    //   const existingProductQty = !existingProduct.qty
    //     ? 0
    //     : +existingProduct.qty;
    //   existingProduct.qty =
    //     existingProductQty + (qtyFromBarcode ? qtyFromBarcode : enteredQty);

    //   const arrayWithoutExistingProduct = updateIndexesInArray(
    //     props.products,
    //     existingProduct
    //   );

    //   existingProduct.index = arrayWithoutExistingProduct.length + 1;
    //   existingProduct.key = arrayWithoutExistingProduct.length + 1;

    //   const newProducts = [...arrayWithoutExistingProduct, existingProduct];
    //   const updated = await bulkPutDataToObjectStore(
    //     TABLES.CURRENT_VERIFICATION,
    //     newProducts
    //   );
    //   if (!updated) {
    //     return messageApi.error("Помилка оновлення даних в таблиці");
    //   }
    // props.setProducts(newProducts);

    // onResetQtyForm();
    // setCurrentProduct(undefined);
    // setInput1Focus();
    // return;
    // }

    // const existingProducts = JSON.parse(JSON.stringify(props.products));
    // const newProduct = { ...product };
    // newProduct.key = props.products.length + 1;
    // newProduct.index = newProduct.key;
    // const enteredQty = qtyInputValue !== "" ? +qtyInputValue : 1;
    // const qtyFromBarcode = newProduct.qty;
    // newProduct.qty = qtyFromBarcode ? qtyFromBarcode : enteredQty;

    // await addRecord(TABLES.CURRENT_VERIFICATION, newProduct);
    // props.setProducts([...existingProducts, newProduct]);
    // setQtyInputValue("");
    // setCurrentProduct(undefined);
    // //  updateLastEnteredProducts([...existingProducts, newProduct]);
    // setInput1Focus();
  };

  const onRowClick = async (record, rowIndex) => {
    const productFromCurrentDoc = props.products.find(
      (prod) => prod.guid === record.guid
    );

    if (!productFromCurrentDoc) {
      messageApi.error("Даного товару немає в документі");
      setSearchValue("");
      setInput1Focus();
      return setLoading(false);
    }
    setCurrentProduct(await setCurrentProductFunc(record));
    setInputQtyFocus();
  };

  const enterHandler = async (enteredData) => {
    if (!enteredData) {
      return messageApi.info("Введіть дані");
    }
    setLoading(true);
    setSearchValue("");

    if (!Number(enteredData)) {
      const products = await searchProductInDbByTitle(enteredData);
      setLoading(false);
      if (!products.length) {
        return messageApi.error("Товари не знайдені");
      }
      setIsVisibleFindedProducts(true);
      setFindedProducts(products);
      return;
    }

    const product = await searchProductInDb(enteredData, props.weightTemplate);
    if (!product) {
      messageApi.error("Товар " + enteredData + " не знайдено!");
      setLoading(false);
      return;
    }
    setIsVisibleFindedProducts(false);
    setFindedProducts([]);

    const enteredProduct = props.products.find(
      (prod) => prod.guid === product.guid
    );
    if (!enteredProduct) {
      messageApi.error("Даного товару немає в документі");
      setSearchValue("");
      setInput1Focus();
      return setLoading(false);
    }
    if (enteredProduct) {
      product.enteredQty = !enteredProduct.qty ? 0 : +enteredProduct.qty;
    }
    updateNewQtyDisplayData(false, product);
    setLoading(false);
    if (product.isWeight && product.qty) {
      return await addToTable(product);
    }
    if (props.settings.autoAddProduct) {
      const added = await addToTable({ ...product, qty: 1 });
      if (!added) return;

      return notificationApi.success({
        description: `${product.title}`,
        message: `Додано: 1`,
        duration: 3,
        placement: "bottom",
        closeIcon: false,
      });
    }

    setCurrentProduct(product);
    if (
      !product.isWeight &&
      !props.isModalProductOpen &&
      !product?.characteristics
    )
      props.setIsModalProductOpen(true);
    setTimeout(() => {
      setInputQtyFocus();
    }, 100);
  };
  const footer = [
    <div key={"footer"}>
      <Space>
        <Button key="submit" type="primary" onClick={() => addToTable()}>
          Додати в таблицю
        </Button>
      </Space>
      {isVisibleFindedProducts ? (
        <FindedProductsTable
          findedProducts={findedProducts}
          onRowClick={onRowClick}
        ></FindedProductsTable>
      ) : (
        ""
      )}
    </div>,
  ];

  const decrementQty = () => {
    const qtyInputValue = inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "" || qtyInputValue === 1) {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(qtyInputValue - 1);
    }
  };

  const incrementQty = () => {
    const qtyInputValue = inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "") {
      setQtyInputValue(1);
    } else {
      setQtyInputValue(qtyInputValue + 1);
    }
  };

  let qtyData =
    currentProduct?.enteredQty +
    (qtyInputValue === "" || !qtyInputValue ? 1 : +qtyInputValue);
  if (currentProduct?.isWeight) {
    qtyData = qtyData.toFixed(3);
  }

  return (
    <>
      <Modal
        title={
          <ModalTitle
            product={currentProduct}
            action={props.action}
            showStockQty={props.showStockQty}
            products={props.products}
            isCheckPrice={false}
          />
        }
        open={props.isModalProductOpen}
        onCancel={() => props.setIsModalProductOpen(false)}
        footer={footer}
        style={{ top: "5px" }}
        afterClose={() => {
          setCurrentProduct(undefined);
          onResetBarcode();
          onResetQtyForm();
          if (props?.setBackgroundEnteredBarcodeValue)
            props.setBackgroundEnteredBarcodeValue("");
        }}
      >
        <Row>
          <Col span={13}>
            <Form form={formBarcode} onReset={onResetBarcode}>
              <Form.Item name="barcode">
                <Search
                  onSearch={(value) => {
                    enterHandler(value);
                    formBarcode.resetFields();
                  }}
                  onPressEnter={async (e) => {
                    e.preventDefault();
                    enterHandler(e.target.value);
                    formBarcode.resetFields();
                  }}
                  allowClear={true}
                  placeholder="Введіть дані"
                  loading={loading}
                  size="large"
                  autoFocus={true}
                  ref={input1Ref}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={1}></Col>
          <Col span={10}>
            <Form form={formQty} onReset={onResetQtyForm}>
              <Form.Item name="qty">
                <InputNumber
                  size="large"
                  ref={inputQtyRef}
                  placeholder="К-ть"
                  onPressEnter={(e) => {
                    e.preventDefault();
                    addToTable();
                    formQty.resetFields();
                  }}
                  onInput={(value) => {
                    // const value = e.target.value;
                    const formattedValue = value.replace(/,/g, ".");
                    let enteredQty = formattedValue;
                    if (currentProduct)
                      if (!currentProduct.isWeight)
                        enteredQty = parseInt(formattedValue);
                    updateNewQtyDisplayData(
                      enteredQty
                        ? +enteredQty
                        : currentProduct?.isWeight
                        ? 0.01
                        : 1,
                      currentProduct
                    );
                  }}
                  controls={false}
                  type={"tel"}
                  min={1}
                  addonBefore={
                    <span style={{ padding: "10px" }} onClick={decrementQty}>
                      -
                    </span>
                  }
                  addonAfter={
                    <span style={{ padding: "10px" }} onClick={incrementQty}>
                      +
                    </span>
                  }
                ></InputNumber>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
