import { App as AntdApp, ConfigProvider } from "antd";
import ukUA from "antd/lib/locale/uk_UA";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./components/UI/ui.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AntdApp>
      <BrowserRouter>
        <ConfigProvider
          locale={ukUA}
          theme={{
            token: {
              colorPrimary: "#202124",
              motion: false,
            },
          }}
        >
          <CookiesProvider defaultSetCookies={{ path: "/" }}>
            <App />
          </CookiesProvider>
        </ConfigProvider>
      </BrowserRouter>
    </AntdApp>
  </React.StrictMode>
);

// if (navigator.serviceWorker) {
// window.addEventListener("load", () => {
// swWaapp();
// swWaappCheckStorage();
// });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
