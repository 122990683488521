const DOCS_FOR_VERIFICATION_PATH = "/docs-verification";
const CREATE_VERIFICATION = "/verification";

export const ROUTES = {
  HOME: "/",
  VERIFICATION: {
    LIST: DOCS_FOR_VERIFICATION_PATH,
    CREATE: DOCS_FOR_VERIFICATION_PATH + CREATE_VERIFICATION,
  },
};
