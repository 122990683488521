export const styles = {
  textBase: { fontSize: 14, display: "block" },

  drawerContent: {
    // height: window.screen.height * 0.4,
  },
  drawerBody: {
    minHeight: "max-content",
  },
  drawerHeader: {
    padding: "10px",
    borderBottom: 0,
  },
  drawerFooter: {
    borderTop: 0,
    padding: 5,
  },
  row: {
    margin: "0px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colDeleteButton: {
    textAlign: "center",
  },
  colInput: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  inputGroupText: {
    width: "40px",
    textAlign: "center",
    cursor: "pointer",
  },
  formControl: {
    textAlign: "center",
  },
  infoText: {
    fontSize: "larger",
  },
};
