import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, message, Popconfirm, Row } from "antd";
import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { getUserSetting } from "../../../api/settings/settings";
import { updateRecord } from "../../../db/dexie-db/functions";
import { ModalTitle } from "../../../features/documents/components/modal-edit-product-qty/components/ModalTitle";
import { styles } from "../../../features/documents/components/modal-edit-product-qty/styles/styles";
import {
  getModalStyles,
  MODALS_STYLES,
} from "../../../features/documents/components/modals/styles/ModalsStyles";
import { useKeyboardHeight } from "../../../hook/keyboardHeight";
import { useAuth } from "../../../hook/useAuth";
import VirtualKeyboard from "../VirtualKeyboard";
import { DRAWER_POSITIONS } from "../_CONSTANTS/constants";
import { servicesSettingsTitles, SETTINGS_KEYS } from "../_CONSTANTS/settings";

export default function ModalEditProductQty(props) {
  const {
    productToChangeQty,
    dbTable,
    settings,
    isModalChangeLastProductOpen,
    setIsModalChangeLastProductOpen,
    afterChangeProductQty,
    removeProductFromDocument,
  } = props;
  const { user } = useAuth();
  const closeModal = () => setIsModalChangeLastProductOpen(false);
  const inputNewQty = useRef();
  const drawerPosition = getUserSetting(
    SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION,
    user.settings,
    servicesSettingsTitles.GLOBAL
  );
  const keyboardHeight = useKeyboardHeight();

  const decrementQty = () => {
    let currentValue = inputNewQty.current.value;
    if (currentValue === "" || !currentValue || currentValue < 1) {
      inputNewQty.current.value = 0;
    } else {
      currentValue = +currentValue - 1;
      inputNewQty.current.value = currentValue;
    }
  };

  const incrementQty = () => {
    let currentValue = inputNewQty.current.value;
    if (currentValue === 0 || currentValue === "" || !currentValue) {
      inputNewQty.current.value = 1;
    } else {
      currentValue = +currentValue + 1;
      inputNewQty.current.value = currentValue;
    }
  };

  const saveButtonHandler = async () => {
    await processChangeProductQty(
      dbTable,
      productToChangeQty,
      inputNewQty.current.value
    );
  };

  const plusButtonHandler = async () => {
    let qtyInputValue = inputNewQty.current.value;
    const enteredQty = qtyInputValue === "" ? 1 : +qtyInputValue;
    const newQty = !+productToChangeQty.qty
      ? 0 + enteredQty
      : +productToChangeQty.qty + enteredQty;
    await processChangeProductQty(dbTable, productToChangeQty, newQty);
  };

  const processChangeProductQty = async (dbTable, product, newQty) => {
    newQty = +newQty ? +newQty : 0;
    const newProduct = { ...product, qty: newQty };

    if (dbTable) {
      await changeProductQty(dbTable, newProduct);
    }
    afterChangeProductQty(newProduct);
    closeModal();
  };

  const changeProductQty = async (dbTable, newProduct) => {
    const updated = await updateRecord(dbTable, newProduct.guid, newProduct);
    if (!updated) {
      message.error("Помилка оновлення кількості");
      return false;
    }
    return true;
  };

  const deleteProductHandler = async (product) => {
    await removeProductFromDocument(product);
    afterChangeProductQty(product);
    closeModal();
  };

  const footer = (
    <div>
      <Row style={{ textAlign: "center" }}>
        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="submit"
            type="primary"
            onClick={plusButtonHandler}
          >
            +
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            style={{ width: "90%" }}
            key="save"
            type="primary"
            onClick={saveButtonHandler}
          >
            Зберегти
          </Button>
        </Col>
        {settings?.showVirtualKeyboard && (
          <VirtualKeyboard
            key="VirtualKeyboardModalEditQty"
            isVirtualKeyboardVisible={true}
            focusedInput={inputNewQty}
            updateNewQtyDisplayData={() => {}}
            currentProduct={productToChangeQty}
            onConfirm={saveButtonHandler}
          ></VirtualKeyboard>
        )}
      </Row>
    </div>
  );
  return (
    <>
      <Drawer
        afterOpenChange={(open) => {
          if (open && productToChangeQty) {
            requestAnimationFrame(() => {
              inputNewQty.current.focus();
            });
          }
        }}
        title={
          <ModalTitle
            product={productToChangeQty}
            showStockQty={props.settings?.showRealCount}
          ></ModalTitle>
        }
        styles={getModalStyles()}
        style={{
          position:
            drawerPosition === DRAWER_POSITIONS.BOTTOM ? "fixed" : undefined,
          bottom:
            drawerPosition === DRAWER_POSITIONS.BOTTOM
              ? keyboardHeight + 50
              : undefined,
          height: "auto",
        }}
        placement="top"
        open={isModalChangeLastProductOpen}
        maskClosable={false}
        onClose={() => {
          closeModal();
        }}
        footer={footer}
        closeIcon={null}
        extra={
          <span style={MODALS_STYLES.CLOSE_MODAL} onClick={closeModal}>
            <CloseOutlined />
          </span>
        }
      >
        <Row style={styles.row}>
          {/* Колонка для кнопки видалення */}
          <Col span={6} style={styles.colDeleteButton}>
            <Popconfirm
              title={"Видалити позицію?"}
              onCancel={() => {
                return;
              }}
              onConfirm={() => deleteProductHandler(productToChangeQty)}
            >
              <Button size="large">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>

          {/* Колонка для форми вводу кількості */}
          <Col span={12} offset={6} style={styles.colInput}>
            <form onSubmit={() => false} style={{ width: "100%" }}>
              <InputGroup>
                <InputGroup.Text
                  id="decrement-qty-button"
                  onClick={decrementQty}
                  style={styles.inputGroupText}
                >
                  -
                </InputGroup.Text>
                <Form.Control
                  className={"ant-btn ant-btn-default ant-btn-lg"}
                  placeholder="К-ть"
                  ref={inputNewQty}
                  type={"tel"}
                  autoFocus={true}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      saveButtonHandler();
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value;
                    const formattedValue = value.replace(/,/g, ".");
                    let enteredQty = formattedValue;
                    if (!productToChangeQty.isWeight)
                      enteredQty = parseInt(formattedValue);

                    if (!+enteredQty) return;
                    e.target.value = enteredQty;
                  }}
                  value={inputNewQty.current?.value}
                  style={styles.formControl}
                />
                <InputGroup.Text
                  id="increment-qty-button"
                  onClick={incrementQty}
                  style={styles.inputGroupText}
                >
                  +
                </InputGroup.Text>
              </InputGroup>
            </form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
