import { message } from "antd";
import dayjs from "dayjs";
import { DOCUMENT_OPEN_ACTIONS } from "../components/documents/_CONSTANTS/constants";
import { sortArrayOfObjectsByTimeAdded } from "../utils/array-functions";
import { TABLES } from "./constants/tables";
import {
  addRecord,
  getAllRecords,
  getPriceByProductAndType,
  getRecordByKeyValue,
  getRecordByMyltipleKey,
  getRecordsByKeywords,
  updateRecord,
} from "./dexie-db/functions";
import { getRecordsForTable } from "./documents-funtions";

export const updateIndexesInArray = (existingProducts, newProduct) => {
  // const arrAfterNewElement = existingProducts.filter(
  //   (prod) => prod.index > newProduct.index
  // );
  // const arrBerforeNewElement = existingProducts.filter(
  //   (prod) => prod.index < newProduct.index
  // );

  // const newArrAfterNewElement = arrAfterNewElement.map((element) => {
  //   return { ...element, index: element.index - 1, key: element.index - 1 };
  // });

  // const resArr = arrBerforeNewElement.concat(newArrAfterNewElement);
  // return resArr;
  const newProducts = existingProducts.filter(
    (prod) => prod.guid !== newProduct.guid
  );

  const productsToTableWithIndexes = newProducts.map((product, ind) => {
    const { index, key, ...productWithoutIndex } = product;
    return { index: ind + 1, key: ind + 1, ...productWithoutIndex };
  });

  return productsToTableWithIndexes;
};

export const getProductsWithIndexes = (products) => {
  return products.map((product, ind) => {
    const { index, ...productWithoutIndex } = product;
    return { index: ind + 1, ...productWithoutIndex };
  });
};

export const getSortedProductsByTimeAdded = (products) => {
  return products.sort(sortArrayOfObjectsByTimeAdded);
};

export const getProductsWithTimeAdded = (products) => {
  return products.map((product, ind) => {
    // const { index, ...productWithoutIndex } = product;
    const timeAdded = +dayjs().valueOf().toString() + ind;
    return {
      timeAdded,
      key: timeAdded,
      ...product,
    };
  });
};

export const getProductsWithPricesByType = async (products, priceType) => {
  return await Promise.all(
    products.map(async (product) => {
      const productPrice = await getProductPriceByType(product.guid, priceType);
      if (!productPrice) return product;
      return { ...product, price: productPrice };
    })
  );
};

export async function searchProductInDbByTitle(
  data,
  priceType = undefined,
  onlyInStock = false
) {
  try {
    const records = await getRecordsByKeywords(
      "Products",
      "title",
      data,
      onlyInStock
    );
    if (records.length) {
      if (!priceType) return records;

      return await getProductsWithPricesByType(records, priceType);
    }

    const recordByArt = await getRecordByKeyValue("Products", { art: data });
    if (!recordByArt) return false;
    if (!priceType) return [recordByArt];
    const productPriceByType = await getProductPriceByType(
      recordByArt.guid,
      priceType
    );
    if (productPriceByType)
      return [{ ...recordByArt, price: productPriceByType }];
    return [recordByArt];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function searchProductInDb(
  data,
  weightTemplate = "21PPPPPQQQQQ",
  priceType = undefined
) {
  try {
    const productByBarcode = await getRecordByMyltipleKey(
      TABLES.PRODUCTS,
      data
    );

    if (productByBarcode) {
      if (!priceType) return productByBarcode;
      const productPriceByType = await getProductPriceByType(
        productByBarcode.guid,
        priceType
      );
      if (productPriceByType)
        return { ...productByBarcode, price: productPriceByType };
      return productByBarcode;
    }
    const productByArt = await getRecordByKeyValue(TABLES.PRODUCTS, {
      art: data,
    });
    if (productByArt) {
      if (!priceType) return productByArt;
      const productPriceByType = await getProductPriceByType(
        productByArt.guid,
        priceType
      );
      if (productPriceByType)
        return { ...productByArt, price: productPriceByType };
      return productByArt;
    }

    const isWeightProduct = checkIsWeightProduct(data, weightTemplate);
    if (isWeightProduct) {
      let weigthProduct = getWeightProduct(data, weightTemplate);
      const product = await getRecordByKeyValue(TABLES.PRODUCTS, {
        art: weigthProduct.code,
      });
      if (product) {
        if (!priceType) return { ...product, qty: weigthProduct.count };
        const productPriceByType = await getProductPriceByType(
          product.guid,
          priceType
        );
        if (productPriceByType)
          return { ...product, price: productPriceByType };
        return { ...product, qty: weigthProduct.count };
      }
      return false;
    }

    return false;
  } catch (error) {
    console.log(error);
    alert("Помилка пошуку товару" + error);
    return false;
  }
}

export const getProductPriceByType = async (productGuid, priceTypeGuid) => {
  const productPriceByPriceType = await getPriceByProductAndType(
    productGuid,
    priceTypeGuid
  );
  if (!productPriceByPriceType) return false;
  return productPriceByPriceType.priceValue;
};

function getWeightProduct(barcode, weightTemplate) {
  const newBarcode = barcode.slice(0, 1) === "0" ? barcode.slice(1) : barcode;

  const userPrefix = weightTemplate.slice(0, 2);
  const firstDigitUserPrefix = userPrefix.substring(0, 1);
  // перевірка якщо 0 тоді брати 1 символ з штрихкоду а не 2
  const prefixLength = firstDigitUserPrefix === "0" ? 1 : 2;

  let code, weight;

  const weightBarcodeTemplate = weightTemplate.slice(2, prefixLength + 11);

  switch (weightBarcodeTemplate) {
    case "PPPPPPQQQQ":
      code = newBarcode.slice(prefixLength, prefixLength + 6);
      weight = newBarcode.slice(prefixLength + 6, prefixLength + 10);
      break;
    case "PPPPPQQQQQ":
      code = newBarcode.slice(prefixLength, prefixLength + 5);
      weight = newBarcode.slice(prefixLength + 5, prefixLength + 10);
      break;
    case "QQQQQPPPPP":
      weight = newBarcode.slice(prefixLength, prefixLength + 5);
      code = newBarcode.slice(prefixLength + 5, prefixLength + 10);
      break;
  }

  code = code.replace(/^0+/, "");
  const count = weight / 1000;

  const product = {
    code: code,
    count: count,
    isWeight: true,
  };

  return product;
}

function checkIsWeightProduct(barcode, weightTemplate) {
  const newBarcode = barcode.slice(0, 1) === "0" ? barcode.slice(1) : barcode;
  const userPrefix = weightTemplate.slice(0, 2);
  const firstDigitUserPrefix = userPrefix.substring(0, 1);
  // перевірка якщо 0 тоді брати 1 символ з штрихкоду а не 2
  const prefixLength = firstDigitUserPrefix === "0" ? 1 : 2;
  const userNewPrefix =
    firstDigitUserPrefix === "0" ? userPrefix.substring(1, 2) : userPrefix;

  let prefix = String(newBarcode).slice(0, prefixLength);

  if (
    prefix === userNewPrefix &&
    (barcode.length === 13 || barcode.length === 12)
  ) {
    return true;
  } else {
    return false;
  }
}

export const setNewProductToTable = async ({
  product,
  products,
  setProducts,
  dbTable,
  action,
}) => {
  const existingProduct = products.find(
    (existingProduct) => product.guid === existingProduct.guid
  );

  if (existingProduct) {
    const existingQty = existingProduct.qty ? +existingProduct.qty : 0;
    existingProduct.qty = existingQty + +product.qty;
    if (action === DOCUMENT_OPEN_ACTIONS.CREATE) {
      const updated = await updateRecord(
        dbTable,
        existingProduct.id,
        existingProduct
      );
      if (!updated) {
        return message.error("Помилка оновлення товару в таблиці");
      }
    }
    const updatedProducts = products.filter(
      (prod) => prod.guid !== existingProduct.guid
    );
    setProducts(getRecordsForTable([...updatedProducts, existingProduct]));
    return;
  }

  const existingProducts = products;
  const newProduct = { ...product };
  const newKey = existingProducts.length + 1;
  newProduct.qty = product.qty;
  newProduct.key = newKey;
  newProduct.id = newKey;
  if (action === DOCUMENT_OPEN_ACTIONS.CREATE) {
    const added = await addRecord(dbTable, newProduct);
    if (!added) {
      return message.error("Помилка додавання товару");
    }
  }
  setProducts(getRecordsForTable([...existingProducts, newProduct]));
};

// export const updateLastEnteredProducts = (
//   arrayProducts,
//   setLastEnteredProducts
// ) => {
//   setLastEnteredProducts(
//     arrayProducts.sort(sortArrayOfObjectsByIndex).slice(-3)
//   );
// };

export const getCurrentDocProducts = async (table) => {
  try {
    return await getAllRecords(table);
  } catch (error) {
    message.error("Помилка отримання даних з поточного замовлення");
    throw error;
  }
};
