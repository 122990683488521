import { message } from "antd";
import React, { createContext, useState } from "react";
import { registerGAEvent } from "../analytics/React-ga";
import { logOut } from "../api/login/Login";
import { LOCALSTORAGE_VARIABLES } from "../components/documents/_CONSTANTS/constants";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("_auth_user"));

  const [user, setUser] = useState(loggedInUser);

  const updateCurrentUser = (user) => {
    localStorage.setItem("_auth_user", JSON.stringify(user));
    setUser(user);
  };

  const signIn = (newUser, cb) => {
    localStorage.setItem("_auth_user", JSON.stringify(newUser));
    localStorage.setItem(LOCALSTORAGE_VARIABLES.AUTH_TOKEN, newUser.token);
    registerGAEvent({
      category: newUser.company.name.name,
      action: newUser.username + " - вхід",
      label: "Вхід в систему",
    });

    setUser(newUser);
    cb();
  };

  const signOut = async (cb) => {
    const loggedOut = await logOut(user.id, user.token);

    if (loggedOut !== true) {
      message.info(loggedOut.message);
    }
    setUser(null);

    localStorage.removeItem(LOCALSTORAGE_VARIABLES.AUTH_USER);
    localStorage.removeItem(LOCALSTORAGE_VARIABLES.AUTH_TOKEN);

    cb();
  };

  const value = { user, signIn, signOut, updateCurrentUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
