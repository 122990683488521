export const TABLES = {
  CURRENT_REVISION: "CurrentRevision",
  EDITING_REVISION: "EditingRevision",
  CURRENT_INVOICE: "CurrentInvoice",
  CURRENT_ORDER: "CurrentOrder",
  CURRENT_DIPLACEMENT: "CurrentDiplacement",
  CURRENT_RETURN: "CurrentReturn",
  CURRENT_CHECK_PRICE: "CurrentCheckPrice",
  CURRENT_SALE: "CurrentSale",
  CURRENT_VERIFICATION: "CurrentVerification",
  REVISIONS: "Revisions",
  INVOICES: "Invoices",
  ORDERS: "Orders",
  DIPLACEMENTS: "Diplacements",
  RETURNS: "Returns",
  CHECK_PRICE: "CheckPrice",
  SALES: "Sales",
  VERIFICATION: "DocumentsForVerification",
  STORAGES: "Storages",
  SUPPLIERS: "Suppliers",
  CATEGORIES: "Categories",
  PRODUCTS: "Products",
  BARCODING: "Barcoding",
  CURRENT_BARCODING: "CurrentBarcoding",
  EDITING_BARCODING: "EditingBarcoding",
  PRICE_TYPES: "PriceTypes",
  PRODUCTS_PRICES: "ProductsPrices",
};
