import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getServiceSettingsObject } from "../../../../../api/settings/settings";
import BackButton from "../../../../../components/UI/BackButton";
import {
  DOCUMENTS_VIEWING_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../../../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../../../../components/documents/_CONSTANTS/settings";
import { getDefaultInvoiceColumns } from "../../../../../components/documents/manage-documents/table-components/documents-columns";
import BottomPageTitle from "../../../../../components/header/BottomPageTitle";
import { TABLES } from "../../../../../db/constants/tables";
import { getRecord } from "../../../../../db/dexie-db/functions";
import { getRecordsForTable } from "../../../../../db/documents-funtions";
import { useAuth } from "../../../../../hook/useAuth";
import Main from "./Main";

export default function ViewReturn() {
  const { messageApi } = useOutletContext();
  const { user } = useAuth();

  const invoiceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.INVOICES
  );
  const globalSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.GLOBAL
  );
  const defaultInvoiceColumns = getDefaultInvoiceColumns({
    action: DOCUMENT_OPEN_ACTIONS.VIEW,
    settings: invoiceSettings,
  });

  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [priceType, setPriceType] = useState();
  const [settings] = useState({ ...invoiceSettings, global: globalSettings });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const doc = await getRecord(TABLES.INVOICES, Number(id));
    if (!doc) {
      messageApi.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    const badProducts = products.filter((prod) => !prod.title || !prod.guid);
    if (badProducts.length) {
      messageApi.error(
        "Помилка отримання товарів з документу, перевірте правильність вигрузки!"
      );
      return navigate(`/invoices`);
    }
    setProducts(getRecordsForTable(products));
    setLoading(false);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        createdFromSource={!!document?.sourceGuid}
        document={document}
        columns={defaultInvoiceColumns}
        priceType={priceType}
        setPriceType={setPriceType}
        settings={settings}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle
        title={DOCUMENTS_VIEWING_TITLES.VIEWING_INVOICE}
      ></BottomPageTitle>
    </>
  );
}
