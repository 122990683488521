import { Table } from "antd";
import React, { useState } from "react";
import { getTableTotals } from "../../../../utils/getTableTotals";
import { TableTotals } from "../../../table-utils/TableTotals";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";
import ModalEditProductQty from "../../manage-documents/ModalEditProductQty";

export default function TableProducts(props) {
  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={props.columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: props.settings?.pageSize,
        }}
        dataSource={props?.products}
        size={"middle"}
        onChange={onChange}
        loading={props.loading}
        title={() => {
          const totals = getTableTotals(props?.products);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(3)}
              total={totals.total.toFixed(2)}
            />
          );
        }}
      />
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            afterChangeProductQty={props.afterChangeProductQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
            dbTable={props.dbTable}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
