import { Radio, Space, Switch } from "antd";

import React from "react";
import {
  getUpdatedSettings,
  updateUserSettInSql,
  updateUserWithNewSettings,
} from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";
import Menu from "../../../menu/Menu";
import {
  PAGE_SIZE_VALUES,
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../_CONSTANTS/settings";

export default function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const onChangeShowVirtualKeyboard = (value) => {
    props.setSettings({ ...props.settings, showVirtualKeyboard: value });
    updateUserSettInSql(
      user,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.ORDERS
    );

    const updatedSettings = getUpdatedSettings(
      user.settings,
      "virtualKeyboard",
      value,
      servicesSettingsTitles.ORDERS
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const onChangePageSize = ({ target: { value } }) => {
    props.setSettings({ ...props.settings, pageSize: value });
    updateUserSettInSql(
      user,
      SETTINGS_KEYS.PAGE_SIZE,
      value,
      servicesSettingsTitles.ORDERS
    );
    updateUserWithNewSettings(
      user.settings,
      SETTINGS_KEYS.PAGE_SIZE,
      value,
      servicesSettingsTitles.ORDERS,
      user,
      updateCurrentUser
    );
  };

  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showVirtualKeyboard}
            size="default"
            onChange={onChangeShowVirtualKeyboard}
          />
          Віртуальна клавіатура
        </Space>
        <Space direction="horizontal">
          <Radio.Group
            options={PAGE_SIZE_VALUES}
            onChange={onChangePageSize}
            value={props?.settings?.pageSize}
            optionType="button"
            buttonStyle="solid"
          />
          Товарів на сторінку
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu settingsTab={settingsTab}></Menu>
    </>
  );
}
