export const getOrderProductsQty = (products, orderProducts) => {
  return products.map((prod) => {
    const sourceProd = orderProducts.find((sourceProd) =>
      sourceProd.guid === prod.guid ? sourceProd.qty : 0
    );

    return {
      ...prod,
      qtyFromSource: sourceProd?.qty,
    };
  });
};

// export const checkIsDocVerified = async (products) => {
//   let verified = "true";
//   products.map((prod) => {
//     if (prod.qty !== prod.qtyFromSource) {
//       verified = "false";
//       return;
//     }
//   });

//   return verified;
// };
export const checkIsDocVerified = async (products) => {
  return products.some((prod) => prod.qty !== prod.qtyFromSource)
    ? "false"
    : "true";
};
