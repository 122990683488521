import { message } from "antd";
import dayjs from "dayjs";
import {
  addRecord,
  getAllRecords,
  getRecord,
  updateRecord,
} from "../../../../db/dexie-db/functions";
import {
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
} from "../../../../db/products-functions";

export const getProductPrice = (inputDataPrice, exists, product, products) => {
  const priceFromInput = +inputDataPrice.current.value.replace(/,/g, ".");
  if (priceFromInput) {
    return priceFromInput;
  }

  if (exists) {
    // отримати ціну з існуючого товару
    const existingProduct = products.find((prod) => prod.guid === product.guid);
    return existingProduct?.price ? existingProduct?.price : 0;
  }
  return 0;
};

export const getProductToTable = async (product, currentDocumentTable) => {
  const existingProduct = await getRecord(currentDocumentTable, product.guid);
  const productToReturn = { ...product };
  productToReturn.timeAdded = +dayjs().valueOf().toString();
  productToReturn.key = productToReturn.timeAdded;
  if (existingProduct) {
    const existingProductQty = +existingProduct.qty ? +existingProduct.qty : 0;
    productToReturn.qty = existingProductQty + product.qty;
    productToReturn.qtyFromSource = existingProduct?.qtyFromSource;
    //не перезаписувати ціну існуючого товару
    productToReturn.price = existingProduct?.price;
  }
  return { productToReturn, existingProduct: existingProduct && true };
};

export const getProductsWithNew = (product, products) => {
  const productsWithoutCurrent = products.filter(
    (prod) => prod.guid !== product.guid
  );
  const newProducts = [...productsWithoutCurrent, product];
  return newProducts;
};

export const getProductsToTable = (products) => {
  const sortedProducts = getSortedProductsByTimeAdded(products);
  const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
  return sortedProductsWithIndexes;
};

export const getExistingProductFromDocument = async ({
  product,
  products,
  dbTable,
}) => {
  if (dbTable) {
    return await getRecord(dbTable, product.guid);
  }
  return products.find((prod) => prod.guid === product.guid);
};

export const addProductToIndexDb = async (dbTable, product) => {
  const exists = await getRecord(dbTable, product.guid);
  if (exists) {
    const updated = await updateRecord(dbTable, product.guid, product);
    if (!updated) {
      message.error("Помилка оновлення товару у документі!");
      return false;
    }
    return true;
  }
  const added = await addRecord(dbTable, product);
  if (!added) {
    message.error("Помилка додавання товару " + product.title);
    return false;
  }
  return true;
};

export const updateProductsInTable = async (dbTable, setProducts) => {
  const products = await getAllRecords(dbTable);
  const sortedProducts = getSortedProductsByTimeAdded(products);
  const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);

  setProducts(sortedProductsWithIndexes);
};
