import { Col, List, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  CheckSquareOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { exportDocument } from "../../../../../api/Export/xml/export";
import {
  DOCUMENT_ACTIONS,
  DOCUMENT_OPEN_ACTIONS,
  DOCUMENT_TYPES,
} from "../../../../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../../../../db/constants/tables";
import {
  clearObjectStore,
  deleteRecord,
  getAllRecords,
  updateRecord,
} from "../../../../../db/dexie-db/functions";
import {
  getDocForExport,
  getRecordsForTable,
} from "../../../../../db/documents-funtions";
import { useAuth } from "../../../../../hook/useAuth";

export const ModalActions = (props) => {
  const { messageApi, modalApi } = useOutletContext();
  const [isSpinning, setIsSpinning] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const document = props?.document;
  if (!document) return;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case DOCUMENT_OPEN_ACTIONS.VERIFY:
        const currentVerificationProducts = await getAllRecords(
          TABLES.CURRENT_VERIFICATION
        );

        if (currentVerificationProducts.length > 0) {
          return modalApi.confirm({
            title: "У перевірці вже є товари у таблиці, продовжити?",
            onCancel: () => {
              return;
            },
            onOk: async () => {
              const cleared = await clearObjectStore(
                TABLES.CURRENT_VERIFICATION
              );
              if (!cleared) {
                return messageApi.error("Помилка очищення поточного документу");
              }
              navigate(`/docs-verification/verification/`, {
                state: { document },
              });
            },
          });
        }
        navigate(`/docs-verification/verification/`, {
          state: { document },
        });

        break;
      case DOCUMENT_ACTIONS.VIEW:
        navigate(`view/${document.id}`, {
          state: { documentNumber: props.documentNumber },
        });
        break;
      case DOCUMENT_ACTIONS.EXPORT:
        setIsSpinning(true);
        const docForExport = await getDocForExport(document);
        const result = await exportDocument(
          docForExport,
          DOCUMENT_TYPES.VERIFICATION,
          user
        );
        setIsSpinning(false);
        if (result.status === "failed") {
          return messageApi.error(
            "Помилка експорту документу. " + result.message
          );
        }
        messageApi.success("Документ відправлено!");

        const newDoc = { ...document, exported: "true" };

        const updated = await updateRecord(
          TABLES.VERIFICATION,
          newDoc.id,
          newDoc
        );
        if (!updated) {
          messageApi.error("Помилка оновлення статусу");
        }

        const newDocuments = props.documents.filter(
          (doc) => doc.id !== newDoc.id
        );
        newDoc.rowsCount = newDoc.products.length;
        props.setDocuments([...newDocuments, newDoc]);
        props.setIsModalActionsOpen(false);

        break;
      case DOCUMENT_ACTIONS.DELETE:
        modalApi.confirm({
          title: "Видалити документ №" + props.documentNumber + "?",
          onCancel: () => {
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord(
              "DocumentsForVerification",
              document.id
            );
            if (!deleted) {
              return messageApi.error("Помилка видалення документа");
            }
            const newDocuments = props.documents.filter(
              (doc) => doc.id !== document.id
            );
            props.setDocuments(getRecordsForTable(newDocuments));
            props.setIsModalActionsOpen(false);
            messageApi.success("Документ видалено!");
            if (!newDocuments.length) {
              navigate("/");
            }
          },
        });

        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Перевірити",
      key: DOCUMENT_OPEN_ACTIONS.VERIFY,
      icon: <CheckSquareOutlined />,
    },
    {
      title: " Експортувати",
      key: DOCUMENT_ACTIONS.EXPORT,
      icon: <CloudUploadOutlined />,
    },
    {
      title: " Переглянути",
      key: DOCUMENT_ACTIONS.VIEW,
      icon: <FundViewOutlined />,
    },
    {
      title: " Видалити",
      key: DOCUMENT_ACTIONS.DELETE,
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={"Дії з документом № " + props.documentNumber}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
        closable={!isSpinning}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
export default ModalActions;
