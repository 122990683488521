import React, { createContext, useContext, useEffect, useState } from "react";

const KeyboardHeightContext = createContext(0);

export const useKeyboardHeight = () => {
  return useContext(KeyboardHeightContext);
};

export const KeyboardHeightProvider = ({ children }) => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      // Отримуємо висоту вікна браузера
      const windowHeight = window.innerHeight;

      // Отримуємо висоту візуального viewport
      const visualViewportHeight =
        window.visualViewport?.height || windowHeight;

      // Визначаємо висоту клавіатури як різницю між загальною висотою вікна та висотою viewport
      const newKeyboardHeight = Math.max(
        0,
        windowHeight - visualViewportHeight
      );
      // Якщо клавіатура з'явилась і її висота не була зафіксована, зберігаємо значення
      if (newKeyboardHeight > 0 && keyboardHeight === 0) {
        setKeyboardHeight(newKeyboardHeight);
      }
    };

    if (window.visualViewport) {
      // Додаємо обробник події resize для відстеження зміни висоти
      window.visualViewport.addEventListener("resize", updateHeight);
    }

    // Очищаємо після демонтажу компонента
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", updateHeight);
      }
    };
  }, [keyboardHeight]);

  return (
    <KeyboardHeightContext.Provider value={keyboardHeight}>
      {children}
    </KeyboardHeightContext.Provider>
  );
};
