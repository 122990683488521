import { App, message, Modal, notification, Space, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getDataFromTxt } from "../../api/Import/txt/import";
import {
  getDataFromXml,
  getFormattedDocs,
  getFormattedDocsForVerification,
} from "../../api/Import/xml/import";
import { useAuth } from "../../hook/useAuth";

import { CloudDownloadOutlined } from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { ErrorBoundary } from "react-error-boundary";
import { getUserSetting } from "../../api/settings/settings";
import { TABLES } from "../../db/constants/tables";
import { db } from "../../db/dexie-db/db";
import { getAllRecords } from "../../db/dexie-db/functions";
import { ErrorFallback } from "../../features/error-boundary/ErrorFallBack";
import { filterArrayOfObjectFromArrayOfObjectsByIndex } from "../../utils/array-functions";
import { getUpdateDate } from "../../utils/getLatestUpdateDate";
import { LOCALSTORAGE_VARIABLES } from "../documents/_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../documents/_CONSTANTS/settings";

export default function ImportModal(props) {
  const { user, signOut } = useAuth();
  const [loadingText, setLoadingText] = useState("");

  const isTokenValid = () => {
    const token = localStorage.getItem(LOCALSTORAGE_VARIABLES.AUTH_TOKEN);
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (props.open) {
      getData();
    }
  }, [props.open]);

  const getData = async () => {
    setLoadingText("Перевірка авторизації...");
    if (!isTokenValid()) {
      setTimeout(() => {
        signOut(() => {
          message.info("Час сесії сплив, авторизуйтесь будь-ласка!");
        });
      }, 3000);
      return;
    }

    const deleteDocumentsFilesAfterImport = await getUserSetting(
      SETTINGS_KEYS.IMPORT.DELETE_DOCUMENTS_FILES_AFTER_IMPORT,
      user.settings,
      servicesSettingsTitles.GLOBAL
    );

    setLoadingText("Завантаження даних");
    let data;
    if (user.exchangeType === "xml") {
      data = await getDataFromXml(
        user.username,
        user.useFtp,
        user?.userGroup?.path,
        deleteDocumentsFilesAfterImport
      );
    } else {
      data = await getDataFromTxt(user.username, user.useFtp);
    }

    if (!data) {
      props.setIsOpenUpdateDataModal(false);
      return notification.error({
        message: "Помилка оновлення!",
        description: "Помилка завантаження даних...",
        duration: 0,
      });
    }

    const products = data.products;
    const categories = data.categories;
    const suppliers = data.suppliers;
    const storages = data.storages;
    const documents = data.documents;
    const documentsForVerification = data.documentsForVerification;
    const priceTypes = data.priceTypes;
    const productsPrices = data.productsPrices;

    const isProductsFileUpdated = data.isProductsFileUpdated;

    if (
      isProductsFileUpdated &&
      (!products || products.length === 0 || !data)
    ) {
      props.setIsOpenUpdateDataModal(false);
      return notification.error({
        message: "Помилка оновлення!",
        description: "Помилка завантаження товарів...",
        duration: 0,
      });
    }

    if (!categories || categories.length === 0) {
      message.info("Категорії не завантажені!");
    }
    if (!suppliers.length) {
      message.info("Постачальники не завантажені!");
    }

    try {
      setLoadingText("Підготовка...");
      await clearIndexDb(products.length > 0);
    } catch (error) {
      setLoadingText("");
      alert(JSON.stringify(error));
      message.error("[ERROR] - failed clear db");
    }

    const savedRequiredData = await saveRequiredDataToIndexDb(
      products,
      categories,
      suppliers
    );
    if (!savedRequiredData) {
      console.log("Помилка збереження даних");
      message.error("Помилка збереження даних");
      setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
      return;
    }

    const savedDocuments = await saveDocumentsToIndexedDb(documents);
    if (!savedDocuments) {
      message.error("Помилка збереження документів");
      setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
      return;
    }

    if (documentsForVerification?.length > 0) {
      const dontRewriteDocsForVerification = getUserSetting(
        "dontRewriteDocuments",
        user.settings,
        servicesSettingsTitles.VERIFICATION
      );

      const savedDocumentsForVerification =
        await saveDocumentsForVerificationToIndexedDb(
          documentsForVerification,
          dontRewriteDocsForVerification
        );
      if (!savedDocumentsForVerification) {
        message.error("Помилка збереження документів для перевірки");
        setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
        return;
      }
    }

    if (storages.length > 0) {
      const savedStorages = await db.Storages.bulkPut(storages);
      if (!savedStorages) {
        message.error("Помилка збереження складів");
        setLoadingText("Помилка оновлення, перевірте правильність вигрузки");
        return;
      }
    } else {
      message.info("Склади не завантажені");
    }

    if (priceTypes?.length && productsPrices?.length) {
      const savePrices = await savePricesAndPriceTypes(
        priceTypes,
        productsPrices
      );
      if (!savePrices) {
        message.warning("Помилка збереження цін");
      }
    }

    setTimeout(() => {
      props.setIsOpenUpdateDataModal(false);
      message.success("Дані оновлено!");
      localStorage.setItem("updated", dayjs());
      try {
        document.getElementById(
          "homeUpdatedText"
        ).innerText = `Оновлено: ${getUpdateDate(dayjs())}`;
      } catch (error) {}
      setLoadingText("");
    }, 100);
  };

  const clearIndexDb = async (clearProducts = true) => {
    const promises = [
      ...(clearProducts ? [db.Products.clear()] : []),
      db.Categories.clear(),
      db.Suppliers.clear(),
      db.Storages.clear(),
    ];

    if (db.PriceTypes) {
      promises.push(db.PriceTypes.clear());
    }

    if (db.ProductsPrices) {
      promises.push(db.ProductsPrices.clear());
    }

    return await Promise.all(promises);
  };

  const saveDocumentsForVerificationToIndexedDb = async (
    documentsForVerification,
    dontRewrite = false
  ) => {
    const docs = await getFormattedDocsForVerification(
      documentsForVerification
    );
    let docsToDb = docs;
    if (dontRewrite) {
      const storedDocs = await getAllRecords(TABLES.VERIFICATION);
      docsToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
        docs,
        storedDocs,
        "id"
      );
    }
    if (!docsToDb.length) return true;
    return await db.DocumentsForVerification.bulkPut(docsToDb);
  };

  const savePricesAndPriceTypes = async (priceTypes, productsPrices) => {
    try {
      await db.ProductsPrices.bulkPut(productsPrices);
      await db.PriceTypes.bulkPut(priceTypes);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const saveDocumentsToIndexedDb = async (documents) => {
    let ordersToDb, revisionsToDb;
    const invoices =
      documents.invoices?.length > 0
        ? (await getFormattedDocs(documents.invoices)).filter(
            (document) => document
          )
        : [];
    const diplacements =
      documents.diplacements?.length > 0
        ? (await getFormattedDocs(documents.diplacements)).filter(
            (document) => document
          )
        : [];
    const orders =
      documents.orders?.length > 0
        ? (await getFormattedDocs(documents.orders)).filter(
            (document) => document
          )
        : [];
    ordersToDb = orders;

    const revisions =
      documents.revisions?.length > 0
        ? (await getFormattedDocs(documents.revisions)).filter(
            (document) => document
          )
        : [];
    revisionsToDb = revisions;

    if (ordersToDb.length) {
      const dontRewriteOrders = getUserSetting(
        "dontRewriteOrders",
        user.settings,
        servicesSettingsTitles.ORDERS
      );
      if (dontRewriteOrders) {
        const storedOrders = await getAllRecords(TABLES.ORDERS);
        ordersToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
          orders,
          storedOrders,
          "id"
        );
      }
    }

    const storedRevisions = await getAllRecords(TABLES.REVISIONS);
    revisionsToDb = filterArrayOfObjectFromArrayOfObjectsByIndex(
      revisions,
      storedRevisions,
      "id"
    );

    return await Promise.all([
      await db.Orders.bulkPut(ordersToDb),
      await db.Invoices.bulkPut(invoices),
      await db.Diplacements.bulkPut(diplacements),
      await db.Revisions.bulkPut(revisionsToDb),
    ]);
  };

  const saveRequiredDataToIndexDb = async (products, categories, suppliers) => {
    return await Promise.all([
      await db.Products.bulkPut(products),
      await db.Categories.bulkPut(categories),
      await db.Suppliers.bulkPut(suppliers),
    ]);
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App>
          <Modal
            title={
              <span>
                <CloudDownloadOutlined style={{ marginRight: 8 }} />
                Оновлення даних
              </span>
            }
            closable={false}
            maskClosable={false}
            footer={null}
            open={props.open}
            onCancel={() => props.setIsOpenUpdateDataModal(false)}
          >
            <>
              <Space>
                <Spin spinning={true}></Spin> {loadingText}
              </Space>
            </>
          </Modal>
        </App>
      </ErrorBoundary>
    </>
  );
}
