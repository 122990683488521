import { LOCALSTORAGE_VARIABLES } from "../_CONSTANTS/constants";

export const saveInvoiceSourceDocDataToLocalStorage = (doc) => {
  localStorage.setItem(
    LOCALSTORAGE_VARIABLES.INVOICE_SOURCE_DOC,
    JSON.stringify(doc)
  );
};

export const getInvoiceSourceDocDataFromLocalStorage = () => {
  const docData = localStorage.getItem(
    LOCALSTORAGE_VARIABLES.INVOICE_SOURCE_DOC
  );
  if (!docData) return false;
  return JSON.parse(docData);
};

export const saveVerificationSourceDocDataToLocalStorage = (doc) => {
  localStorage.setItem(
    LOCALSTORAGE_VARIABLES.VERIFICATION_SOURCE_DOC,
    JSON.stringify(doc)
  );
};

export const getVerificationSourceDocFromLocalStorage = () => {
  const docData = localStorage.getItem(
    LOCALSTORAGE_VARIABLES.VERIFICATION_SOURCE_DOC
  );
  if (!docData) return false;
  return JSON.parse(docData);
};

export const clearInvoiceSourceDocDataFromlocalStorage = () => {
  localStorage.removeItem(LOCALSTORAGE_VARIABLES.INVOICE_SOURCE_DOC);
};

export const setSearchProductsByHierarchyOnlyByStock = (value) => {
  localStorage.setItem(LOCALSTORAGE_VARIABLES.HIERARHY_SEARCH_BY_STOCK, value);
};

export const searchProductsByHierarchyOnlyByStock = () => {
  return localStorage.getItem(LOCALSTORAGE_VARIABLES.HIERARHY_SEARCH_BY_STOCK);
};

export const getProductsFileModifiedDate = () => {
  return localStorage.getItem(
    LOCALSTORAGE_VARIABLES.PRODUCTS_FILE_MODIFIED_DATE
  );
};

export const setProductsFileModifiedDate = (value) => {
  localStorage.setItem(
    LOCALSTORAGE_VARIABLES.PRODUCTS_FILE_MODIFIED_DATE,
    value
  );
};
