export function showCallStack() {
  function f() {
    return new Error().stack;
  }
  function g() {
    return f();
  }
  console.log(g());
}

export const getStorageSpace = async () => {
  if (!navigator?.storage) {
    return false;
  }
  const estimate = await navigator.storage.estimate();
  const usedMegaBytes = (estimate.usage / (1024 * 1024)).toFixed(0);
  const availableMegaBytes = (estimate.quota / (1024 * 1024)).toFixed(0);

  return { usedMegaBytes, availableMegaBytes };
};

export function checkForUpdate() {
  return new Promise((resolve) => {
    if (navigator.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({ type: "CHECK_UPDATE" });
    }
    resolve();
  });
}
