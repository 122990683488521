import { VideoTrackSingleton } from "./videoTrackSingleton";

export async function scanBarcode() {
  const videoTrack = new VideoTrackSingleton();
  const track = await videoTrack.getTrack();

  try {
    const barcode = await getBarcode(track);

    if (barcode) {
      return barcode;
    }
  } catch (error) {
    videoTrack.stop();
    return scanBarcode();
  }
}

export async function stopScanning() {
  new VideoTrackSingleton().stop();
}

/**
 *
 * @param {MediaStreamTrack} videoTrack
 */
export async function getBarcode(videoTrack) {
  if (videoTrack.readyState !== "live") {
    return;
  }

  const detector = window["BarcodeDetector"];

  const barcodeDetector = new detector();
  const capturer = new ImageCapture(videoTrack);

  let bitmap;

  try {
    bitmap = await capturer.grabFrame();
  } catch (error) {
    throw new Error("Failed to grabFrame");
  }
  drawImageOnCanvas(bitmap);

  const barcodes = await barcodeDetector.detect(bitmap);
  if (barcodes.length === 1) {
    return barcodes[0].rawValue;
  }
  return getBarcode(videoTrack);
}

export function drawImageOnCanvas(bitmap) {
  const canvas = document.getElementById("canvas");
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    bitmap,
    0,
    0,
    bitmap.width,
    bitmap.height,
    0,
    0,
    canvas.width,
    canvas.height
  );
}

export const getVideoDevices = async () => {
  const devices = await navigator?.mediaDevices?.enumerateDevices();
  if (!devices) return [];
  const vDevices = devices.filter((device) => device.kind === "videoinput");
  return vDevices;
};
