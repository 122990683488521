import { Button, Radio, Space, Switch } from "antd";
import React, { useState } from "react";
import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../../../../api/settings/settings";
import {
  PAGE_SIZE_VALUES,
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../../../../components/documents/_CONSTANTS/settings";
import ImportModal from "../../../../../components/import/ImportModal";
import Menu from "../../../../../components/menu/Menu";
import { useAuth } from "../../../../../hook/useAuth";

export default function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();
  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);

  const dataTab = (
    <Button
      onClick={() => {
        setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити дані
    </Button>
  );

  const onChangeShowBarcodesInTable = (value) => {
    props.setSettings({ ...props.settings, showBarcodesInTable: value });
    updateUserSettInSql(user, "showBarcodesInTable", value, "verification");
  };

  const onChangeAdditionalColorDifferents = (value) => {
    props.setSettings({ ...props.settings, additionalColorDifferents: value });
    updateUserSettInSql(
      user,
      "additionalHighlightDifferents",
      value,
      "revizia"
    );
  };

  const onChangePageSize = ({ target: { value } }) => {
    // console.log("radio checked", value);
    localStorage.setItem("tablePageSize", value);
    props.setSettings({ ...props.settings, tablePageSize: value });
  };

  const onChangeColorDifferents = (value) => {
    props.setSettings({ ...props.settings, colorDifferents: value });
    updateUserSettInSql(user, "color_differents", value, "revizia");
  };

  const onChangeShowSumColumn = (value) => {
    props.setSettings({ ...props.settings, showSumColumn: value });
    updateUserSettInSql(user, "showSummOrRozb", value, "revizia");
  };

  const onChangeSounds = (value) => {
    props.setSettings({ ...props.settings, sounds: value });
    updateUserSettInSql(user, [SETTINGS_KEYS.SOUND], value, "revizia");
  };

  const onChangeDigitalKeyboard = (value) => {
    props.setSettings({ ...props.settings, digitalKeyboard: value });
    updateUserSettInSql(user, "digitalKeyboard", value, "revizia");
  };

  const onChangeShowVirtualKeyboard = (value) => {
    props.setSettings({ ...props.settings, showVirtualKeyboard: value });
    updateUserSettInSql(user, "virtualKeyboard", value, "revizia");
  };
  const onChangeAutoAddProduct = (value) => {
    props.setSettings({ ...props.settings, autoAddProduct: value });
    updateUserSettInSql(
      user,
      "autoAddProduct",
      value,
      servicesSettingsTitles.VERIFICATION
    );
    const updatedSettings = getUpdatedSettings(
      user.settings,
      "autoAddProduct",
      value,
      servicesSettingsTitles.VERIFICATION
    );
    const updatedLoggedInUser = { ...user, settings: updatedSettings };
    updateCurrentUser(updatedLoggedInUser);
  };

  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.colorDifferents}
            defaultChecked
            size="default"
            onChange={onChangeColorDifferents}
          />
          Підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.additionalColorDifferents}
            defaultChecked
            size="default"
            onChange={onChangeAdditionalColorDifferents}
          />
          Додаткова підсвітка розбіжностей
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.digitalKeyboard}
            defaultChecked
            size="default"
            onChange={onChangeDigitalKeyboard}
          />
          Цифрова клавіатура
        </Space>
        {/* <Space direction="horizontal">
          <Switch
            // checkedChildren="Запитувати ціну"
            // unCheckedChildren="Не запитувати ціну"
            checked={props?.settings?.showArtColumn}
            size="default"
            onChange={onChangeShowArtInTable}
          />
          Показувати артикул
        </Space> */}
        <Space direction="horizontal">
          <Switch
            // checkedChildren="Запитувати ціну"
            // unCheckedChildren="Не запитувати ціну"
            checked={props?.settings?.showBarcodesInTable}
            size="default"
            onChange={onChangeShowBarcodesInTable}
          />
          Показувати штрихкоди
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showSumColumn}
            size="default"
            onChange={onChangeShowSumColumn}
          />
          Показувати суму
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.sound}
            defaultChecked
            size="default"
            onChange={onChangeSounds}
          />
          Звуки
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showVirtualKeyboard}
            defaultChecked
            size="default"
            onChange={onChangeShowVirtualKeyboard}
          />
          Віртуальна клавіатура
        </Space>

        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.autoAddProduct}
            size="default"
            onChange={onChangeAutoAddProduct}
          />
          Автоматичне додавання
        </Space>

        <Space direction="horizontal">
          <Radio.Group
            options={PAGE_SIZE_VALUES}
            onChange={onChangePageSize}
            value={props?.settings?.tablePageSize}
            optionType="button"
            // defaultValue={pageSize}
            buttonStyle="solid"
          />
          Товарів на сторінку
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu dataTab={dataTab} settingsTab={settingsTab}></Menu>
      <ImportModal
        open={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
    </>
  );
}
