export const MODALS_STYLES = {
  TITLE: {
    fontSize: "large",
    display: "block",
    paddingRight: "15px",
    overflow: "hidden",
  },
  TEXT_BASE: { fontSize: "medium", display: "block" },
  CLOSE_MODAL: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: "10px",
    cursor: "pointer",
    fontSize: "16px",
    marginLeft: "10px",
  },
};

export const getModalStyles = () => {
  return {
    body: { padding: "3px 10px", overflow: "hidden" },
    header: { padding: "10px", borderBottom: 0 },
    footer: {
      padding: "10px",
      borderTop: 0,
    },
    wrapper: { height: "max-content" },
    content: {
      left: 0,
      right: 0,
      overflowY: "auto",
    },
  };
};
