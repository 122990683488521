import { Checkbox, Col, ColorPicker, Divider, Row, Space, Switch } from "antd";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  updateUserSetting,
  updateUserSettInSql,
  updateUserWithNewSettings,
} from "../../../api/settings/settings";
import { DRAWER_POSITIONS } from "../../../components/documents/_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../../components/documents/_CONSTANTS/settings";
import { toggleFullscreen } from "../../../components/UI/functions";
import { useSize } from "../../../hoc/SizeProvider";
import { useTheme } from "../../../hook/useTheme";

function Interface(props) {
  const { globalSettings, user, updateCurrentUser } = props;

  const { messageApi } = useOutletContext();
  const { toggleThemeMode } = useTheme();
  const { size, setSize } = useSize();
  const [useDarkMode, setUseDarkMode] = useState(globalSettings.darkMode);
  const [drawerEnterProductsPosition, setDrawerEnterProductsPosition] =
    useState(
      globalSettings[SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION] ===
        DRAWER_POSITIONS.BOTTOM
    );
  const [useFullScreen, setUseFullScreen] = useState(
    globalSettings[SETTINGS_KEYS.USE_FULL_SCREEN]
  );

  const [
    isNegativeDiscrepancyColorPickerOpen,
    setNegativeDiscrepancyColorPickerOpen,
  ] = useState(false);
  const [
    isPositiveDiscrepancyColorPickerOpen,
    setPositiveDiscrepancyColorPickerOpen,
  ] = useState(false);
  const [isZeroDiscrepancyColorPickerOpen, setZeroDiscrepancyColorPickerOpen] =
    useState(false);
  const [isMissingQuantityColorPickerOpen, setMissingQuantityColorPickerOpen] =
    useState(false);

  const onChangeUseDarkMode = async (value) => {
    toggleThemeMode(value);
    setUseDarkMode(value);

    const res = await updateUserSettInSql(user, "darkMode", value, "global");
    if (res) {
      messageApi.success("Оновлено!");
      updateUserWithNewSettings(
        user.settings,
        "darkMode",
        value,
        "global",
        user,
        updateCurrentUser
      );
    }
  };

  const onChangeDrawerPosition = async (value) => {
    setDrawerEnterProductsPosition(value);
    const position = value ? DRAWER_POSITIONS.BOTTOM : DRAWER_POSITIONS.TOP;

    const res = await updateUserSettInSql(
      user,
      SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION,
      position,
      servicesSettingsTitles.GLOBAL
    );
    if (res) {
      messageApi.success("Оновлено!");
      updateUserWithNewSettings(
        user.settings,
        SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION,
        position,
        servicesSettingsTitles.GLOBAL,
        user,
        updateCurrentUser
      );
    }
  };

  const changeRowColor = async (color, setting) => {
    const res = await updateUserSettInSql(user, setting, color, "global");
    if (res) {
      messageApi.success("Оновлено!");
      updateUserWithNewSettings(
        user.settings,
        setting,
        color,
        "global",
        user,
        updateCurrentUser
      );
    }
  };
  const genPresets = (presets) => {
    return Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
      key: label,
    }));
  };
  const lightThemePresets = {
    Червоні: [
      "#ff4d4f",
      "#ff7875",
      "#ffa39e",
      "#ffccc7",
      "rgba(255, 77, 79, 0.5)",
    ],
    Сині: [
      "#1677ff",
      "#69c0ff",
      "#91d5ff",
      "#bae7ff",
      "rgba(22, 119, 255, 0.5)",
    ],
    Жовті: [
      "#faad14",
      "#ffc53d",
      "#ffe58f",
      "#fff1b8",
      "rgba(250, 173, 20, 0.5)",
    ],
    Світлі: [
      "#ffffff",
      "rgba(255, 255, 255, 0.9)",
      "rgba(255, 255, 255, 0.7)",
      "rgba(255, 255, 255, 0.5)",
    ],
    Зелені: [
      "#52c41a",
      "#73d13d",
      "#95de64",
      "#d9f7be",
      "rgba(82, 196, 26, 0.5)",
    ],
  };

  const darkThemePresets = {
    Червоні: ["#820014", "#a8071a", "#d32029", "#f5222d", "#ff4d4f"],
    Сині: ["#002766", "#003a8c", "#0050b3", "#096dd9", "#1677ff"],
    Жовті: ["#614700", "#ad6800", "#d48806", "#faad14", "#ffc53d"],
    Темні: ["#000000", "#141414", "#1c1c1c", "#262626", "#333333"],
    Зелені: ["#092b00", "#135200", "#237804", "#389e0d", "#52c41a"],
  };

  const lightPresets = genPresets(lightThemePresets);
  const darkPresets = genPresets(darkThemePresets);

  const customPanelRender = (_, { components: { Presets } }) => <Presets />;

  const handleChangeSize = async (newSize) => {
    setSize(newSize);
    const res = await updateUserSettInSql(
      user,
      SETTINGS_KEYS.INTERFACE_SIZE,
      newSize,
      servicesSettingsTitles.GLOBAL
    );
    if (res) {
      messageApi.success("Оновлено!");
      updateUserWithNewSettings(
        user.settings,
        SETTINGS_KEYS.INTERFACE_SIZE,
        newSize,
        servicesSettingsTitles.GLOBAL,
        user,
        updateCurrentUser
      );
    }
  };

  const handleColorChange = (value, settingKey, setPickerOpen) => {
    changeRowColor(value.toHexString(), settingKey);
    setPickerOpen(false); // Закриваємо компонент після зміни кольору
  };

  const onChangeUseFullScreen = (value) => {
    updateUserSetting(
      user,
      SETTINGS_KEYS.USE_FULL_SCREEN,
      value,
      updateCurrentUser,
      servicesSettingsTitles.GLOBAL
    );
    setUseFullScreen(value);
    toggleFullscreen();
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Space direction="horizontal">
            <Switch
              checked={useDarkMode}
              size="default"
              onChange={(value) => onChangeUseDarkMode(value)}
            />
            Нічний режим
          </Space>
        </Col>
      </Row>
      <Divider>Підсвітка</Divider>
      <Space direction="vertical">
        <ColorPicker
          defaultValue="#af0003"
          showText={() => "Розбіжність < 0"}
          value={
            useDarkMode
              ? globalSettings?.negativeDiscrepancyColorDark
              : globalSettings?.negativeDiscrepancyColor
          }
          presets={useDarkMode ? darkPresets : lightPresets}
          panelRender={customPanelRender}
          open={isNegativeDiscrepancyColorPickerOpen}
          onOpenChange={(open) => setNegativeDiscrepancyColorPickerOpen(open)}
          onChangeComplete={(value) =>
            handleColorChange(
              value,
              useDarkMode
                ? SETTINGS_KEYS.COLORING_DARK_MODE.NEGATIVE_DISCREPANCY_DARK
                : SETTINGS_KEYS.COLORING.NEGATIVE_DISCREPANCY,
              setNegativeDiscrepancyColorPickerOpen
            )
          }
        />

        <ColorPicker
          defaultValue="#af0003"
          showText={() => "Розбіжність > 0"}
          value={
            useDarkMode
              ? globalSettings?.positiveDiscrepancyColorDark
              : globalSettings?.positiveDiscrepancyColor
          }
          presets={useDarkMode ? darkPresets : lightPresets}
          panelRender={customPanelRender}
          open={isPositiveDiscrepancyColorPickerOpen}
          onOpenChange={(open) => setPositiveDiscrepancyColorPickerOpen(open)}
          onChangeComplete={(value) =>
            handleColorChange(
              value,
              useDarkMode
                ? SETTINGS_KEYS.COLORING_DARK_MODE.POSITIVE_DISCREPANCY_DARK
                : SETTINGS_KEYS.COLORING.POSITIVE_DISCREPANCY,
              setPositiveDiscrepancyColorPickerOpen
            )
          }
        />

        <ColorPicker
          defaultValue="#23a100"
          showText={() => "Розбіжність = 0"}
          value={
            useDarkMode
              ? globalSettings?.zeroDiscrepancyColorDark
              : globalSettings?.zeroDiscrepancyColor
          }
          presets={useDarkMode ? darkPresets : lightPresets}
          panelRender={customPanelRender}
          open={isZeroDiscrepancyColorPickerOpen}
          onOpenChange={(open) => setZeroDiscrepancyColorPickerOpen(open)}
          onChangeComplete={(value) =>
            handleColorChange(
              value,
              useDarkMode
                ? SETTINGS_KEYS.COLORING_DARK_MODE.ZERO_DISCREPANCY_DARK
                : SETTINGS_KEYS.COLORING.ZERO_DISCREPANCY,
              setZeroDiscrepancyColorPickerOpen
            )
          }
        />

        <ColorPicker
          defaultValue="#ffffff"
          showText={() => "Не внесена к-ть"}
          value={
            useDarkMode
              ? globalSettings?.missingQuantityColorDark
              : globalSettings?.missingQuantityColor
          }
          presets={useDarkMode ? darkPresets : lightPresets}
          panelRender={customPanelRender}
          open={isMissingQuantityColorPickerOpen}
          onOpenChange={(open) => setMissingQuantityColorPickerOpen(open)}
          onChangeComplete={(value) =>
            handleColorChange(
              value,
              useDarkMode
                ? SETTINGS_KEYS.COLORING_DARK_MODE.MISSING_QUANTITY_DARK
                : SETTINGS_KEYS.COLORING.MISSING_QUANTITY,
              setMissingQuantityColorPickerOpen
            )
          }
        />
      </Space>
      <Divider>Розмір інтерфейсу</Divider>
      <Space direction="horizontal">
        <Checkbox
          checked={size === "small"}
          onChange={() => handleChangeSize("small")}
          style={{ marginLeft: 8 }}
        >
          Малий
        </Checkbox>
        <Checkbox
          checked={size === "middle"}
          onChange={() => handleChangeSize("middle")}
          style={{ marginLeft: 8 }}
        >
          Середній
        </Checkbox>
        <Checkbox
          checked={size === "large"}
          onChange={() => handleChangeSize("large")}
          style={{ marginLeft: 8 }}
        >
          Великий
        </Checkbox>
      </Space>
      <br></br>
      <br></br>
      <Space direction="horizontal">
        <Switch
          checked={drawerEnterProductsPosition}
          size="default"
          onChange={(value) => onChangeDrawerPosition(value)}
        />
        Модальні вікна внизу
      </Space>
      <br></br>
      <br></br>
      <Space direction="horizontal">
        <Switch
          checked={useFullScreen}
          size="default"
          onChange={(value) => onChangeUseFullScreen(value)}
        />
        Повноекранний режим
      </Space>
    </div>
  );
}

export default Interface;
