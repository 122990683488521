import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import RequireAuth from "../hoc/RequireAuth";
import Home from "../pages/Home2";
import Nopage from "../pages/Nopage.js";

import Invoices from "../features/documents/invoice/components/list/List";
import CreateInvoice from "../features/documents/invoice/components/single/Create";
import EditInvoice from "../features/documents/invoice/components/single/Edit";
import ViewInvoice from "../features/documents/invoice/components/single/View";
import CheckPriceCreate from "../pages/check-price/CheckPriceCreate";
import CheckPriceEdit from "../pages/check-price/CheckPriceEdit";
import CheckPriceList from "../pages/check-price/CheckPriceList";
import CheckPriceView from "../pages/check-price/CheckPriceView";
import CreateOrder from "../pages/orders/Create";
import EditOrder from "../pages/orders/Edit";
import Orders from "../pages/orders/List";
import ViewOrder from "../pages/orders/View";
import CreateReturn from "../pages/returns/CreateReturn";
import EditReturn from "../pages/returns/EditReturn";
import Returns from "../pages/returns/Returns";
import ViewReturn from "../pages/returns/ViewReturn";

import CreateBarcoding from "../pages/barcoding/CreateBarcoding.js";
import EditBarcoding from "../pages/barcoding/EditBarcoding.js";
import Barcoding from "../pages/barcoding/List.js";
import ViewBarcoding from "../pages/barcoding/ViewBarcoding.js";

import CreateDiplacement from "../pages/diplacements/Create";
import EditDiplacement from "../pages/diplacements/Edit";
import Diplacements from "../pages/diplacements/List";
import ViewDiplacement from "../pages/diplacements/View";
import RevisionCreate from "../pages/revisions/Create";
import RevisionEdit from "../pages/revisions/Edit";
import Revisions from "../pages/revisions/List";
import RevisionView from "../pages/revisions/View";

import DocumentsVerification from "../features/documents/verification/components/list/List.js";
import Verification from "../features/documents/verification/components/single/Verification.js";
import ViewVerification from "../features/documents/verification/components/single/View.js";
import LoginNew from "../pages/LoginNew";
import Registration from "../pages/Registration.js";
import CreateSale from "../pages/sales/create";
import EditSale from "../pages/sales/edit";
import Sales from "../pages/sales/list";
import ViewSale from "../pages/sales/view";
import Settings from "../pages/Settings";
import { ROUTES } from "./ROUTES.jsx";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout></Layout>
          </RequireAuth>
        }
      >
        <Route index element={<Home></Home>}></Route>
        <Route
          path="check-price"
          element={<CheckPriceList></CheckPriceList>}
        ></Route>
        <Route
          path="check-price/create"
          element={<CheckPriceCreate></CheckPriceCreate>}
        ></Route>
        <Route
          path="check-price/view/:id"
          element={<CheckPriceView></CheckPriceView>}
        ></Route>
        <Route
          path="check-price/edit/:id"
          element={<CheckPriceEdit></CheckPriceEdit>}
        ></Route>
        <Route path="returns" element={<Returns></Returns>}></Route>
        <Route
          path="returns/create"
          element={<CreateReturn></CreateReturn>}
        ></Route>
        <Route
          path="returns/view/:id"
          element={<ViewReturn></ViewReturn>}
        ></Route>
        <Route
          path="returns/edit/:id"
          element={<EditReturn></EditReturn>}
        ></Route>
        <Route path="invoices" element={<Invoices></Invoices>}></Route>
        <Route
          path="invoices/create"
          element={<CreateInvoice></CreateInvoice>}
        ></Route>
        <Route
          path="invoices/view/:id"
          element={<ViewInvoice></ViewInvoice>}
        ></Route>
        <Route
          path="invoices/edit/:id"
          element={<EditInvoice></EditInvoice>}
        ></Route>
        <Route path="orders" element={<Orders></Orders>}></Route>
        <Route
          path="orders/create"
          element={<CreateOrder></CreateOrder>}
        ></Route>
        <Route path="orders/view/:id" element={<ViewOrder></ViewOrder>}></Route>
        <Route path="orders/edit/:id" element={<EditOrder></EditOrder>}></Route>
        <Route
          path="diplacements"
          element={<Diplacements></Diplacements>}
        ></Route>
        <Route
          path="diplacements/create"
          element={<CreateDiplacement></CreateDiplacement>}
        ></Route>
        <Route
          path="diplacements/view/:id"
          element={<ViewDiplacement></ViewDiplacement>}
        ></Route>
        <Route
          path="diplacements/edit/:id"
          element={<EditDiplacement></EditDiplacement>}
        ></Route>
        <Route path="revisions" element={<Revisions></Revisions>}></Route>
        <Route
          path="revisions/create"
          element={<RevisionCreate></RevisionCreate>}
        ></Route>
        <Route
          path="revisions/view/:id"
          element={<RevisionView></RevisionView>}
        ></Route>
        <Route
          path="revisions/edit/:id"
          element={<RevisionEdit></RevisionEdit>}
        ></Route>
        <Route path="sales" element={<Sales></Sales>}></Route>
        <Route path="sales/create" element={<CreateSale></CreateSale>}></Route>
        <Route path="sales/view/:id" element={<ViewSale></ViewSale>}></Route>
        <Route path="sales/edit/:id" element={<EditSale></EditSale>}></Route>
        <Route
          path={ROUTES.VERIFICATION.LIST}
          element={<DocumentsVerification></DocumentsVerification>}
        ></Route>
        <Route path="barcoding" element={<Barcoding></Barcoding>}></Route>
        <Route
          path="barcoding/create"
          element={<CreateBarcoding></CreateBarcoding>}
        ></Route>
        <Route
          path="barcoding/view/:id"
          element={<ViewBarcoding></ViewBarcoding>}
        ></Route>
        <Route
          path="barcoding/edit/:id"
          element={<EditBarcoding></EditBarcoding>}
        ></Route>
        <Route
          path={ROUTES.VERIFICATION.CREATE}
          element={<Verification></Verification>}
        ></Route>
        <Route
          path={`${ROUTES.VERIFICATION.LIST}/view/:id`}
          element={<ViewVerification></ViewVerification>}
        ></Route>
        <Route path="settings" element={<Settings></Settings>}></Route>
        <Route path="*" element={<Nopage></Nopage>}></Route>
      </Route>
      <Route path="/login" element={<LoginNew></LoginNew>}></Route>
      <Route
        path="/registration"
        element={<Registration></Registration>}
      ></Route>
    </Routes>
  );
};

export default AppRouter;
