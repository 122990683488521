import { message } from "antd";
import Dexie from "dexie";
import { sendTGMessage } from "../../statistics/statistics";

export const CURRENT_DB_VERSION = 19;

const stores = {
  Products: "++id, title, *barcodes, art, guid, stockQty, parentCode",
  Categories: "++id, code, title, parentCode",
  CurrentOrder: "&guid",
  Orders: "++id, supplierGuid, date, exported, imported",
  CheckPrice: "++id, date, exported",
  CurrentCheckPrice: "&guid",
  Revisions: "++id, date, exported, imported",
  CurrentRevision: "&guid",
  Invoices: "++id, date, exported, imported",
  CurrentInvoice: "&guid",
  Suppliers: "++id, title, guid",
  Sales: "++id, date, exported",
  CurrentSale: "&guid",
  Returns: "++id, date, exported",
  CurrentReturn: "&guid",
  Diplacements: "++id, date, exported, imported",
  CurrentDiplacement: "&guid",
  Storages: "++id, title, guid",
  DocumentsForVerification: "++id, date, processed, verified",
  CurrentVerification: "&guid",
  EditingRevision: "&guid",
};

const DB_MIGRATIONS_TO_RUN_ALWAYS = {
  14: {
    stores,
  },
  15: {
    stores: {
      Suppliers: null,
      SuppliersTemp: "&guid", // Create temp table
    },
    upgrade: async (tx) => {
      const Suppliers = await tx.table("Suppliers").toArray();
      await tx.table("SuppliersTemp").bulkAdd(Suppliers);
    },
  },
  16: {
    stores: {
      Suppliers: "&guid",
      SuppliersTemp: null, // Create temp table
    },
    upgrade: async (trx) => {
      const Suppliers = await trx.table("SuppliersTemp").toArray();
      await trx.table("Suppliers").bulkAdd(Suppliers);
    },
  },
  17: {
    stores: {
      DocumentsForVerification: "++id, date, processed, verified, exported",
    },
  },
  18: {
    stores: {
      Barcoding: "++id, date, exported",
      CurrentBarcoding: "&guid, *newBarcodes",
      EditingBarcoding: "&guid, *newBarcodes",
    },
  },
  19: {
    stores: {
      ProductsPrices:
        "++id, priceTypeGuid, productGuid, priceValue,[productGuid+priceTypeGuid]",
      PriceTypes: "++id, guid, name",
    },
  },
};

export const db = new Dexie("WAAPP");

try {
  const migrations = Object.entries(DB_MIGRATIONS_TO_RUN_ALWAYS);

  for (let [migrationVersion, migration] of migrations) {
    const version = db.version(migrationVersion).stores(migration.stores);

    if (migration.upgrade) {
      version.upgrade(migration.upgrade);
    }
  }
} catch (error) {
  message.error("Помилка оновлення бази даних");
  sendTGMessage(
    "Помилка оновлення бази даних. " + JSON.stringify(error.message)
  );
}
