import { LOCALSTORAGE_VARIABLES } from "../../components/documents/_CONSTANTS/constants";
import { getServer } from "../../utils/Environment";

const server = getServer();

export async function loginNew(username, password, token) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      token,
      action: "loginNew",
    }),
  });

  return await responce.json();
}

export const getStoredToken = () => {
  return localStorage.getItem(LOCALSTORAGE_VARIABLES.AUTH_TOKEN);
};

export async function logOut(userId, token) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ userId, token, action: "logout" }),
  });

  return await responce.json();
}
