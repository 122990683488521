import { Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import BottomPageTitle from "../components/header/BottomPageTitle";
import BackButton from "../components/UI/BackButton";
import CONFIG from "../config.json";
import { TABLES } from "../db/constants/tables";
import { getAllRecords } from "../db/dexie-db/functions";
import SettingsTabs from "../features/settings/components/SettingsTabs";
import { useAuth } from "../hook/useAuth";

export default function Settings() {
  const { user, updateCurrentUser } = useAuth();
  const [priceTypes, setPriceTypes] = useState();

  useEffect(() => {
    const fetchPriceTypes = async () => {
      const priceTypesFromDb = await getAllRecords(TABLES.PRICE_TYPES);
      if (priceTypesFromDb) setPriceTypes(priceTypesFromDb);
    };
    fetchPriceTypes();
  }, []);

  return (
    <>
      <div style={{ padding: "0px 15px" }}>
        <SettingsTabs
          user={user}
          updateCurrentUser={updateCurrentUser}
          priceTypes={priceTypes}
        ></SettingsTabs>
        <BottomPageTitle title={"Налаштування"}></BottomPageTitle>

        <Row
          justify={"center"}
          style={{
            position: "fixed",
            bottom: 30,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography>{`Версія: ${CONFIG.APP_VERSION}`}</Typography>
        </Row>
        <BackButton></BackButton>
      </div>
    </>
  );
}
