import {
  getUpdatedSettings,
  updateUserSettInSql,
} from "../../api/settings/settings";

export const onChangeColumnVisibility = (
  user,
  settings,
  setSettings,
  settingName,
  settingValue,
  serviceTitle,
  updateCurrentUser
) => {
  setSettings({ ...settings, [settingName]: settingValue });
  updateUserSettInSql(user, settingName, settingValue, serviceTitle);

  const updatedSettings = getUpdatedSettings(
    user.settings,
    settingName,
    settingValue,
    serviceTitle
  );
  const updatedLoggedInUser = { ...user, settings: updatedSettings };

  updateCurrentUser(updatedLoggedInUser);
};
