import { Table } from "antd";
import React from "react";
import { sortArrayOfObjectsByTitle } from "../../utils/array-functions";
const columns = [
  {
    key: "title",
    title: "Назва",
    dataIndex: "title",
    width: "60%",
    sorter: (a, b) => sortArrayOfObjectsByTitle(a, b),
  },
  {
    key: "price",
    title: "Ціна",
    dataIndex: "price",
    width: "25%",
  },
  {
    key: "stockQty",
    title: "Зал.",
    dataIndex: "stockQty",
    width: "15%",
  },
];

const FindedProductsTable = function FindedProductsTable(props) {
  const onRowClickHandler = (record) => {
    props.onRowClick(record);
  };

  return (
    <>
      <Table
        onRow={(record) => {
          return {
            onClick: () => onRowClickHandler(record),
            onContextMenu: () => props.onContextMenu(record),
          };
        }}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          hideOnSinglePage: true,
          pageSize: 999,
          // pageSizeOptions: [10, 100, 1000],
          responsive: true,
        }}
        scroll={{
          // x: "max-content",
          y: "50vh",
        }}
        dataSource={props?.findedProducts}
        size={"small"}
        loading={props?.isloading}
      />
    </>
  );
};

export default FindedProductsTable;
