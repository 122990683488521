import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useEffect } from "react";
import { AuthProvider } from "./hoc/AuthProvider";
import { SizeProvider } from "./hoc/SizeProvider";
import ThemeProvider from "./hoc/ThemeProvider";
import { KeyboardHeightProvider } from "./hook/keyboardHeight";
import AppRouter from "./router/AppRouter";
import { registerSW } from "./serviceWorkers/swWaapp";

function App() {
  const handleUnload = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    registerSW();
  }, []);
  async function lockPortrait() {
    try {
      if ("orientation" in window.screen && window.screen.orientation.lock) {
        await window.screen.orientation.lock("portrait-primary");
        console.log("Орієнтація зафіксована у портретному режимі.");
      } else {
        console.warn(
          "Блокування орієнтації не підтримується у цьому браузері."
        );
      }
    } catch (error) {
      console.error("Помилка блокування орієнтації:", error);
    }
  }

  document.addEventListener("click", lockPortrait, { once: true });

  return (
    <AuthProvider>
      <ThemeProvider>
        <SizeProvider>
          <KeyboardHeightProvider>
            <AppRouter />
          </KeyboardHeightProvider>
        </SizeProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
