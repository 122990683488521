import { getServer } from "../../utils/Environment";

const server = getServer();
const urlServer = server + "/server/server.php";

export async function saveSubscriptionIdDb(
  subscription,
  userId,
  updateNotification = false
) {
  try {
    const result = await fetch(urlServer, {
      method: "POST",
      body: JSON.stringify({
        subscription,
        action: "save-subscription",
        userId,
        updateNotification,
      }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const removeSubscription = async (subscription, userId) => {
  try {
    const result = await fetch(urlServer, {
      method: "POST",
      body: JSON.stringify({
        subscription,
        action: "remove-subscription",
        userId,
      }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserSubscription = async (messageApi) => {
  // Перевіряємо, чи підтримуються пуш-сповіщення
  if (!("Notification" in window) || !("serviceWorker" in navigator)) {
    messageApi.error("Ваш браузер не підтримує пуш-сповіщення!");
    return;
  }
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIos =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (isIos && isSafari) {
    messageApi.info("На iOS потрібно встановити додаток на головний екран!");
  }

  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    messageApi.error("Потрібно дозволити сповіщення у налаштуваннях додатку!");
    return;
  }

  const sw = await navigator.serviceWorker.ready;

  try {
    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BHKfOtkcdGA3vZ-D5jgOrpTaOANZTzCHLPTQQ-Ub1-4HRZltYAhZGg-JEKNaFqCUI42cx_Slq3xP77qH_GruT2k",
    });

    // console.log("Subscription:", JSON.stringify(subscription));
    return subscription;
  } catch (error) {
    return false;
  }
};
