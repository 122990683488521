import { ConfigProvider } from "antd";
import { createContext, useContext, useState } from "react";
import { getUserSetting } from "../api/settings/settings";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../components/documents/_CONSTANTS/settings";
import { useAuth } from "../hook/useAuth";

// Контекст для масштабу
const SizeContext = createContext();

export const SizeProvider = ({ children }) => {
  const { user } = useAuth();
  const selectedSize = user?.settings
    ? getUserSetting(
        SETTINGS_KEYS.INTERFACE_SIZE,
        user.settings,
        servicesSettingsTitles.GLOBAL
      )
    : null;
  const [size, setSize] = useState(selectedSize ? selectedSize : "middle");

  return (
    <SizeContext.Provider value={{ size, setSize }}>
      <ConfigProvider componentSize={size}>{children}</ConfigProvider>
    </SizeContext.Provider>
  );
};

export const useSize = () => useContext(SizeContext);
