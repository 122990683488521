import { ConfigProvider, message, Modal, notification, theme } from "antd";
import { default as React, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { isUpdateNeeded } from "../api/Import/isUpdateNeeded";
import { getUserSetting, updateUserSetting } from "../api/settings/settings";
import ImportModal from "../components/import/ImportModal";
import { useAuth } from "../hook/useAuth";
import useFullscreen from "../hook/useFullScreen";
import { useTheme } from "../hook/useTheme";
import { DRAWER_POSITIONS } from "./documents/_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "./documents/_CONSTANTS/settings";
import { toggleFullscreen } from "./UI/functions";
const { darkAlgorithm } = theme;

export default function Layout() {
  const { user, signOut, updateCurrentUser } = useAuth();

  const drawerPosition = getUserSetting(
    SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION,
    user?.settings,
    servicesSettingsTitles.GLOBAL
  );

  const [messageApi, contextHolder] = message.useMessage({
    top:
      drawerPosition === DRAWER_POSITIONS.BOTTOM ? window.innerHeight / 3 : 8,
  });
  const [notificationApi, notificationHolder] = notification.useNotification({
    top:
      drawerPosition === DRAWER_POSITIONS.BOTTOM ? window.innerHeight / 3 : 8,
  });
  const [modalApi, contextHolderModal] = Modal.useModal();
  const { useDarkMode, toggleThemeMode } = useTheme();

  const userUseFullScreen = getUserSetting(
    SETTINGS_KEYS.USE_FULL_SCREEN,
    user.settings,
    servicesSettingsTitles.GLOBAL
  );

  const userUseDarkMode = getUserSetting(
    "darkMode",
    user.settings,
    servicesSettingsTitles.GLOBAL
  );

  useEffect(() => {
    toggleThemeMode(userUseDarkMode);
  }, [toggleThemeMode, userUseDarkMode]);

  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "REFRESH_DATA") {
        setIsOpenUpdateDataModal(true); // Ваша функція оновлення
      }
    };

    navigator?.serviceWorker?.addEventListener("message", handleMessage);

    return () => {
      navigator?.serviceWorker?.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (
      !!document.documentElement.requestFullscreen &&
      userUseFullScreen === undefined
    )
      modalApi.confirm({
        title: "Перейти в повноекранний режим?",
        okText: "Так",
        cancelText: "Ні",
        onOk: () => {
          updateUserSetting(
            user,
            SETTINGS_KEYS.USE_FULL_SCREEN,
            true,
            updateCurrentUser,
            servicesSettingsTitles.GLOBAL
          );
          toggleFullscreen();
        },
      });
  }, [userUseFullScreen]);

  useFullscreen();

  const darkMode = {
    algorithm: darkAlgorithm,
    token: {
      colorPrimary: "#6b6b6b",
    },
  };

  const lightMode = {
    token: {
      colorPrimary: "#202124",
    },
  };

  useEffect(() => {
    const getData = async () => {
      const updated = localStorage.getItem("updated");
      const updatedNeeded = await isUpdateNeeded(
        updated,
        user.exchangeType,
        { username: user.username, id: user.id, token: user.token },
        user?.useFtp,
        user?.userGroup?.path,
        signOut
      );
      if (!updatedNeeded) return;

      if (!updated || updatedNeeded) {
        return showUpdateAvailableModal();
      }
    };

    getData();
  }, [user, signOut]);

  // useEffect(() => {
  //   if (user?.autoUpdateData) registerSyncTask(1000);
  // }, [user?.autoUpdateData]);

  useEffect(() => {
    const setTheme = () => {
      if (useDarkMode) {
        const root = document.getElementById("root");
        root.style.backgroundColor = "black";
        document.documentElement.style.backgroundColor = "black";
        document.documentElement.setAttribute("data-bs-theme", "dark");
      } else {
        const root = document.getElementById("root");
        root.style.backgroundColor = "white";
        document.documentElement.style.backgroundColor = "white";
        document.documentElement.setAttribute("data-bs-theme", "light");
      }
    };

    setTheme();
  }, [useDarkMode]);

  const showUpdateAvailableModal = () => {
    modalApi.confirm({
      title: "Необхідне оновлення!",
      content: "Оновити дані зараз?",
      okText: "Оновити",
      cancelText: "Не зараз",
      onOk: () => {
        setIsOpenUpdateDataModal(true);
        toggleFullscreen();
        return;
      },
      onCancel: () => {
        return;
      },
    });
  };

  return (
    <>
      <ConfigProvider theme={useDarkMode ? darkMode : lightMode}>
        <div style={{ padding: "0px 5px" }}>
          {contextHolder}
          {notificationHolder}
          {contextHolderModal}
          <Outlet context={{ messageApi, notificationApi, modalApi }}></Outlet>
        </div>
        <ImportModal
          open={isOpenUpdateDataModal}
          setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
        ></ImportModal>
        <div style={{ paddingBottom: "100px" }}></div>
      </ConfigProvider>
    </>
  );
}
