import { message, Modal } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserSetting } from "../../api/settings/settings";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import {
  DOCUMENT_OPEN_ACTIONS,
  DOCUMENTS_EDITING_TITLES,
  LOCALSTORAGE_VARIABLES,
} from "../../components/documents/_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../components/documents/_CONSTANTS/settings";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import Footer from "../../components/documents/revisions/create/Footer";
import Main from "../../components/documents/revisions/create/Main";
import {
  getColumnsData,
  getRevisionSettings,
} from "../../components/documents/revisions/functions";
import { getDefaultColumns } from "../../components/documents/revisions/table-products/columns";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { TABLES } from "../../db/constants/tables";
import {
  bulkPutDataToObjectStore,
  clearObjectStore,
  getAllRecords,
  getRecord,
} from "../../db/dexie-db/functions";
import {
  changePriceTypeInDocument,
  getDocumentProductsWithoutDeleted,
  savePriceTypeForDocument,
} from "../../db/documents-funtions";
import {
  getCurrentDocProducts,
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
} from "../../db/products-functions";
import { useAuth } from "../../hook/useAuth";
import {
  isAnyModalOpen,
  listenToBackgroundEnteredBarcode,
} from "../../utils/eventListeners";

const DOCUMENT_TITLE = DOCUMENTS_EDITING_TITLES.EDITING_REVISION;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;
const CURRENT_DOCUMENT_TABLE = TABLES.EDITING_REVISION;

let backgroundEnteredBarcode = "";

export default function RevisionEdit() {
  const { user, updateCurrentUser } = useAuth();
  const [modal, contextHolderModal] = Modal.useModal();

  const revisionSettings = getRevisionSettings(user);
  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );
  const revisionPriceType = getUserSetting(
    SETTINGS_KEYS.REVISIONS.PRICE_TYPE,
    user.settings
  );

  const currentRevisionPriceType = revisionPriceType ? revisionPriceType : null;

  const showStockQty = revisionSettings?.showRealCount;

  const params = useParams();
  const id = +params.id;

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);

  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(revisionSettings);
  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  const [priceType, setPriceType] = useState(currentRevisionPriceType);

  const searchInput = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      window.document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    if (isAnyModalOpen()) return;
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      // setIsModalProductOpen,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  const onChangePriceType = async (priceType) => {
    await savePriceTypeForDocument(
      user,
      servicesSettingsTitles.REVISIONS,
      SETTINGS_KEYS.REVISIONS.PRICE_TYPE,
      priceType,
      updateCurrentUser
    );
    await changePriceTypeInDocument(
      priceType,
      setPriceType,
      products,
      setProducts,
      CURRENT_DOCUMENT_TABLE
    );
  };

  const getData = async () => {
    const productsInEditingTable = await getAllRecords(TABLES.EDITING_REVISION);
    const prevEditingRevisionId = Number(
      localStorage.getItem(LOCALSTORAGE_VARIABLES.EDITING_REVISION_ID)
    );
    let title;
    let okText;
    let cancelText;
    if (!prevEditingRevisionId) {
      localStorage.setItem(LOCALSTORAGE_VARIABLES.EDITING_REVISION_ID, id);
      return processNewEditing();
    }

    if (prevEditingRevisionId === id) {
      title = "Дана ревізія вже відкрита для редагування, виберіть варіант";
      okText = "Продовжити";
      cancelText = "Почати спочатку";
    } else {
      title = "Інша ревізія вже відкрита для редагування, виберіть варіант";
      okText = "Продовжити";
      cancelText = "Редагувати вибрану";
    }
    if (productsInEditingTable.length && prevEditingRevisionId) {
      return modal.confirm({
        title: title,
        okText: okText,
        cancelText: cancelText,
        onOk: async () => {
          processContinueEditing(productsInEditingTable, prevEditingRevisionId);
        },
        onCancel: () => {
          localStorage.setItem(LOCALSTORAGE_VARIABLES.EDITING_REVISION_ID, id);
          processNewEditing();
        },
      });
    }
    processNewEditing();
  };

  const processContinueEditing = async (
    productsInEditingTable,
    editingRevisionId
  ) => {
    const doc = await getRecord(TABLES.REVISIONS, editingRevisionId);
    if (!doc) {
      return message.error("Помилка отримання даних документа");
    }
    doc.products = productsInEditingTable;
    setDocument(doc);
    const sortedProducts = getSortedProductsByTimeAdded(productsInEditingTable);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    setProducts(sortedProductsWithIndexes);
    setLoading(false);
  };

  const processNewEditing = async () => {
    const deleted = await clearObjectStore(TABLES.EDITING_REVISION);
    if (!deleted)
      return message.error("Помилка очищення таблиці для редагування!");
    const doc = await getRecord(TABLES.REVISIONS, id);
    if (!doc) {
      return message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    const addedToIndexedDb = await bulkPutDataToObjectStore(
      TABLES.EDITING_REVISION,
      products
    );
    if (!addedToIndexedDb) {
      return message.error("Помилка заповнення товарів документа");
    }
    setDocument(doc);
    setProducts(products);
    setLoading(false);
  };

  const updateProductsInEditingRevision = async () => {
    const products = await getCurrentDocProducts(CURRENT_DOCUMENT_TABLE);
    const sortedProducts = getSortedProductsByTimeAdded(products);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    setProducts(sortedProductsWithIndexes);
  };

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      TABLES.EDITING_REVISION,
      DOCUMENT_OPEN_ACTIONS.EDIT
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const defaultColumns = getDefaultColumns({
    settings,
    searchInput,
    useCharacteristics,
  });

  const columns = getColumnsData(defaultColumns, settings);

  const afterChangeProductQty = async () => {
    const newProducts = await getAllRecords(CURRENT_DOCUMENT_TABLE);
    const sortedProducts = getSortedProductsByTimeAdded(newProducts);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    setProducts(sortedProductsWithIndexes);
  };

  const FooterMemo = useMemo(() => {
    return (
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
        action={DOCUMENT_ACTION}
      ></Footer>
    );
  }, [settings]);

  return (
    <>
      {contextHolderModal}
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.EDIT}
        dbTable={CURRENT_DOCUMENT_TABLE}
        columns={columns}
        settings={settings}
        document={document}
        removeProductFromDocument={removeProductFromDocument}
        afterChangeProductQty={afterChangeProductQty}
        priceType={priceType}
        setPriceType={setPriceType}
        onChangePriceType={onChangePriceType}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterProduct
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          action={DOCUMENT_ACTION}
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
          focusInputQty={true}
          showStockQty={showStockQty === false ? false : true}
          priceType={priceType}
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          focusInputQty={true}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          settings={settings}
          weightTemplate={user.weightTemplate}
          showStockQty={showStockQty === false ? false : true}
          updateProducts={updateProductsInEditingRevision}
          priceType={priceType}
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          action={DOCUMENT_ACTION}
          products={products}
          setProducts={setProducts}
          afterClose={afterChangeProductQty}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          showStockQty={showStockQty === false ? false : true}
          priceType={priceType}
        ></ModalChooseProducts>
      )}

      {FooterMemo}
      <BottomPageTitle
        title={DOCUMENTS_EDITING_TITLES.EDITING_REVISION}
      ></BottomPageTitle>
    </>
  );
}
