export const PAGE_SIZE_VALUES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 300,
    value: 300,
  },
];

export const servicesSettingsTitles = {
  ORDERS: "orders",
  REVISIONS: "revizia",
  CHECK_PRICE: "checkPrice",
  VERIFICATION: "verification",
  SALES: "seller",
  DIPLACEMENTS: "diplacements",
  BARCODING: "barcoding",
  GLOBAL: "global",
  RETURNS: "returns",
  INVOICES: "invoice",
};

export const SETTINGS_KEYS = {
  USE_CHARACTERISTICS: "useCharacteristics",
  USE_ENTEREXPIRATION_DATES: "useEnteringExpirationDates",
  USE_VIRTUAL_KEYBOARD: "showVirtualKeyboard",
  SHOW_SUM_COLUMN: "showSumColumn",
  SHOW_ART_COLUMN: "showArtColumn",
  SHOW_PRICE_COLUMN: "showPriceColumn",
  ADDITIONAL_HIGHLIGHT_DIFFERENTS: "additionalHighlightDifferents",
  HIGHLIGHT_DIFFERENTS: "color_differents",
  PAGE_SIZE: "pageSize",
  SOUND: "sound",
  REVISIONS: {
    PRICE_TYPE: "revisionPriceType",
    SHOW_SUMM_BY_DIFFERENTS: "showSumByDiff",
  },
  INVOICES: {
    PRICE_TYPE: "invoicePriceType",
    IGNORE_STORAGE_SOURCE: "ignoreStorageSource",
  },
  COLORING: {
    NEGATIVE_DISCREPANCY: "negativeDiscrepancyColor",
    POSITIVE_DISCREPANCY: "positiveDiscrepancyColor",
    ZERO_DISCREPANCY: "zeroDiscrepancyColor",
    MISSING_QUANTITY: "missingQuantityColor",
  },
  COLORING_DARK_MODE: {
    NEGATIVE_DISCREPANCY_DARK: "negativeDiscrepancyColorDark",
    POSITIVE_DISCREPANCY_DARK: "positiveDiscrepancyColorDark",
    ZERO_DISCREPANCY_DARK: "zeroDiscrepancyColorDark",
    MISSING_QUANTITY_DARK: "missingQuantityColorDark",
  },
  IMPORT: {
    DELETE_DOCUMENTS_FILES_AFTER_IMPORT: "deleteDocumentsFilesAfterImport",
  },
  INTERFACE_SIZE: "interfaceSize",
  ENABLE_PUSH_NOTIFICATIONS: "enablePushNotifications",
  DRAWER_ENTER_PRODUCTS_POSITION: "drawerEnterProductsPosition",
  UPDATE_NOTIFICATION_ENABLED: "updateNotificationEnabled",
  USE_FULL_SCREEN: "useFullScreen",
};
