import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  getServiceSettingsObject,
  getUserSetting,
} from "../../../../../api/settings/settings";
import ModalBarcodeCameraReader from "../../../../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import {
  DOCUMENT_OPEN_ACTIONS,
  DOCUMENT_TITLE_IF_PRODUCT_NOT_IN_DOC_SOURCE,
  DOCUMENTS_TITLES,
} from "../../../../../components/documents/_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../../../../components/documents/_CONSTANTS/settings";
import {
  clearInvoiceSourceDocDataFromlocalStorage,
  saveInvoiceSourceDocDataToLocalStorage,
} from "../../../../../components/documents/CACHE/localstorage";
import ModalEnterProduct from "../../../../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import {
  getColumnsDataGlobal,
  getDefaultInvoiceColumns,
} from "../../../../../components/documents/manage-documents/table-components/documents-columns";
import BottomPageTitle from "../../../../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../../../../components/products/products-tree/ModalChooseProducts";
import { getDiffColumnVisibility } from "../../../../../components/table-utils/last-entered-products/Columns";
import BackButton from "../../../../../components/UI/BackButton";
import { TABLES } from "../../../../../db/constants/tables";
import { db } from "../../../../../db/dexie-db/db";
import { clearObjectStore } from "../../../../../db/dexie-db/functions";
import {
  changePriceTypeInDocument,
  getDocumentProductsWithoutDeleted,
  savePriceTypeForDocument,
} from "../../../../../db/documents-funtions";
import {
  getCurrentDocProducts,
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
  setNewProductToTable,
} from "../../../../../db/products-functions";
import { useAuth } from "../../../../../hook/useAuth";
import { listenToBackgroundEnteredBarcode } from "../../../../../utils/eventListeners";
import Footer from "./Footer";
import Main from "./Main";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.INVOICE;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_INVOICE;

let backgroundEnteredBarcode = "";

export default function CreateInvoice() {
  const { messageApi, notificationApi } = useOutletContext();
  const { user, updateCurrentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const docOrder = useMemo(
    () => location?.state?.document,
    [location?.state?.document]
  );

  const invoiceSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.INVOICES
  );

  if (invoiceSettings[SETTINGS_KEYS.INVOICES.IGNORE_STORAGE_SOURCE])
    delete docOrder?.storageGuid;

  const globalSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.GLOBAL
  );
  const defaultInvoiceColumns = getDefaultInvoiceColumns({
    action: docOrder
      ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
      : DOCUMENT_ACTION,
    settings: invoiceSettings,
  });
  const servicePriceType = getUserSetting(
    SETTINGS_KEYS.INVOICES.PRICE_TYPE,
    user.settings
  );

  const currentPriceType = servicePriceType ? servicePriceType : null;

  const columns = useMemo(() => {
    const res = getColumnsDataGlobal(defaultInvoiceColumns, invoiceSettings);
    return res;
  }, [invoiceSettings, defaultInvoiceColumns]);

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState({
    ...invoiceSettings,
    global: globalSettings,
  });

  const [productsFromOrder] = useState(
    docOrder ? JSON.parse(JSON.stringify(docOrder.products)) : ""
  );

  const [backgroundEnteredBarcodeValue, setBackgroundEnteredBarcodeValue] =
    useState("");

  const [priceType, setPriceType] = useState(currentPriceType);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", detectKeyDownEvent);
    };
  }, []);

  const detectKeyDownEvent = (e) => {
    backgroundEnteredBarcode = listenToBackgroundEnteredBarcode(
      e,
      setBackgroundEnteredBarcodeValue,
      backgroundEnteredBarcode
    );
  };

  const setDataFromSource = useCallback(async () => {
    const badProducts = productsFromOrder.filter(
      (prod) => prod.guid === undefined
    );
    if (badProducts.length > 0) {
      notificationApi.error({
        message: "Помилка",
        description:
          "У документі є невідомі товари, створити прихід неможливо!",
        duration: 5,
      });
      return navigate(-1, { replace: true });
    }
    saveInvoiceSourceDocDataToLocalStorage(docOrder);

    const timeAdd = +dayjs().valueOf().toString();

    const productsFromSourceWithoutQty = productsFromOrder.map((prod, ind) => ({
      ...prod,
      qty: 0,
      qtyFromSource: prod.qty,
      timeAdded: timeAdd + ind,
      key: timeAdd + ind,
    }));

    const sortedProductsWithIndexes = getProductsWithIndexes(
      productsFromSourceWithoutQty
    );
    setProducts(sortedProductsWithIndexes);
    await db.CurrentInvoice.bulkPut(sortedProductsWithIndexes);
  }, [productsFromOrder, navigate, docOrder, notificationApi]);

  const getData = useCallback(async () => {
    const products = await getCurrentDocProducts(CURRENT_DOCUMENT_TABLE);

    if (docOrder && !products.length) {
      setDataFromSource();
      return setLoading(false);
    }

    if (products.length > 0) {
      const sortedProducts = getSortedProductsByTimeAdded(products);
      const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
      setProducts(sortedProductsWithIndexes);
      return setLoading(false);
    }

    setLoading(false);
  }, [setDataFromSource, setProducts, setLoading, docOrder]);

  useEffect(() => {
    getData();
  }, [getData]);

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const onChangePriceType = async (priceType) => {
    await savePriceTypeForDocument(
      user,
      servicesSettingsTitles.INVOICES,
      SETTINGS_KEYS.INVOICES.PRICE_TYPE,
      priceType,
      updateCurrentUser
    );
    await changePriceTypeInDocument(
      priceType,
      setPriceType,
      products,
      setProducts,
      CURRENT_DOCUMENT_TABLE
    );
  };

  const afterChangeProductQty = (newProduct) => {
    if (!newProduct) return getData();

    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        document={docOrder}
        action={
          docOrder ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE : DOCUMENT_ACTION
        }
        createdFromSource={docOrder ? true : false}
        settings={settings}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={columns}
        dbTable={CURRENT_DOCUMENT_TABLE}
        priceType={priceType}
        setPriceType={setPriceType}
        onChangePriceType={onChangePriceType}
        IGNORE_STORAGE_SOURCE={SETTINGS_KEYS.INVOICES.IGNORE_STORAGE_SOURCE}
      ></Main>
      {(isModalProductOpen || backgroundEnteredBarcodeValue) && (
        <ModalEnterProduct
          backgroundEnteredBarcode={backgroundEnteredBarcodeValue}
          setBackgroundEnteredBarcodeValue={setBackgroundEnteredBarcodeValue}
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          dbTable={CURRENT_DOCUMENT_TABLE}
          products={products}
          setProducts={setProducts}
          isModalProductOpen={isModalProductOpen}
          setIsModalProductOpen={setIsModalProductOpen}
          settings={settings}
          weightTemplate={user.weightTemplate}
          serviceTitle={DOCUMENT_TITLE}
          showDiffColumn={getDiffColumnVisibility(
            DOCUMENT_TITLE,
            docOrder ? true : false
          )}
          focusInputQty={true}
          columns={columns}
          afterChangeProductQty={afterChangeProductQty}
          removeProductFromDocument={removeProductFromDocument}
          priceType={priceType}
          documentTitleIfProductNotInDocSouce={
            DOCUMENT_TITLE_IF_PRODUCT_NOT_IN_DOC_SOURCE.ORDER
          }
        ></ModalEnterProduct>
      )}
      {isModalCameraBarcodeReaderOpen && (
        <ModalBarcodeCameraReader
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
          focusInputQty={true}
          setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          settings={settings}
          weightTemplate={user.weightTemplate}
          updateProducts={getData}
          priceType={priceType}
          documentTitleIfProductNotInDocSouce={
            DOCUMENT_TITLE_IF_PRODUCT_NOT_IN_DOC_SOURCE.ORDER
          }
        ></ModalBarcodeCameraReader>
      )}
      {isModalChooseProductsOpen && (
        <ModalChooseProducts
          products={products}
          setProducts={setProducts}
          setNewProductToTable={setNewProductToTable}
          isModalChooseProductsOpen={isModalChooseProductsOpen}
          setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
          dbTable={CURRENT_DOCUMENT_TABLE}
          serviceTitle={DOCUMENT_TITLE}
          action={
            docOrder
              ? DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
              : DOCUMENT_ACTION
          }
          settings={settings}
          priceType={priceType}
          documentTitleIfProductNotInDocSouce={
            DOCUMENT_TITLE_IF_PRODUCT_NOT_IN_DOC_SOURCE.ORDER
          }
        ></ModalChooseProducts>
      )}
      <BackButton
        alertOnClick={true}
        message="Ви впевнені, що хочете вийти?"
        onBack={async () => {
          clearInvoiceSourceDocDataFromlocalStorage();
          const clearedCurrentOrder = await clearObjectStore(
            TABLES.CURRENT_INVOICE
          );
          if (!clearedCurrentOrder) {
            messageApi.error("Помилка очищення поточного документа");
            return false;
          }
          navigate(-1);
        }}
      ></BackButton>
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
        action={DOCUMENT_ACTION}
      ></Footer>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
