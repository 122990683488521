import { Col, Row, Select, Space } from "antd";
import Input from "antd/es/input/Input";
import React, { useEffect, useRef, useState } from "react";
import { getAllRecords } from "../../../../../db/dexie-db/functions";
import { toSelectItems } from "../../../../../utils/array-functions";

function InfoTab(props) {
  const { setSupplier, setStorage } = props;
  const [suppliers, setSuppliers] = useState([]);
  const [storages, setStorages] = useState([]);

  const storageRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      const suppliersFromDb = await getAllRecords("Suppliers");
      const suppliersToSelect = getSuppliersForSelect(suppliersFromDb);
      setSuppliers(suppliersToSelect);

      const storagesFromDb = await getAllRecords("Storages");
      const storagesToSelect = getStoragesForSelect(storagesFromDb);
      setStorages(storagesToSelect);
    };

    getData();
  }, []);

  const onChange = (value) => {
    setSupplier(value);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getStoragesForSelect = (suppliersFromDb) => {
    return suppliersFromDb.map((supplier) => {
      return { value: supplier.guid, label: supplier.title };
    });
  };
  const onChangeStorage = (value) => {
    storageRef.current.blur();
    setStorage(value);
  };

  const getSuppliersForSelect = (suppliersFromDb) => {
    return suppliersFromDb.map((supplier) => {
      return { value: supplier.guid, label: supplier.title };
    });
  };

  return (
    <>
      <Row style={{ padding: "20px" }}>
        <Col offset={2} span={20} style={{ width: "100%" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Select
              style={{ width: "100%" }}
              size={"large"}
              showSearch
              placeholder="Виберіть постачальника"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={suppliers}
              value={props?.supplier}
              disabled={props?.disabledFields}
            />
            <Select
              style={{ width: "100%" }}
              size={"large"}
              showSearch
              // open={isOpenStorageSelect}
              // onBlur={() => {
              //   setIsOpenStorageSelect(false);
              // }}
              // onFocus={() => {
              //   setIsOpenStorageSelect(true);
              // }}
              ref={storageRef}
              placeholder="Склад"
              optionFilterProp="children"
              onChange={onChangeStorage}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={storages}
              value={props?.storage}
              disabled={props?.disabledFields}
            />
            <Input
              style={{ width: "100%" }}
              size={"large"}
              placeholder="Коментар"
              value={props?.comment}
              disabled={props?.disabledFields}
              onChange={(value) => {
                props.setComment(value.target.value);
              }}
            ></Input>
            {props?.priceTypes && (
              <Select
                style={{ width: "100%" }}
                size={"large"}
                showSearch
                placeholder="Тип ціни"
                optionFilterProp="children"
                onChange={props.onChangePriceType}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={toSelectItems(props?.priceTypes)}
                value={props?.priceType}
                disabled={props?.disabledFields}
              />
            )}
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default InfoTab;
