import { DashOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

export const getColumnSearchProps = (dataIndex, searchInput) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        size={"large"}
        placeholder={`Введіть текст`}
        value={selectedKeys[0]}
        onChange={(e) => {
          const currentValue = e.target.value ? [e.target.value] : [];
          setSelectedKeys(currentValue);
          confirm(false);
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        {/* <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Шукати
        </Button> */}
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Очистити
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
});

const handleSearch = (_selectedKeys, confirm) => {
  confirm();
};
const handleReset = (clearFilters, confirm) => {
  clearFilters(false);
  confirm();
};

export const getDefaultColumns = ({
  settings,
  searchInput,
  useCharacteristics,
}) => {
  return [
    {
      title: "№",
      dataIndex: "timeAdded",
      width: "13%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.timeAdded - b.timeAdded,
      render: (_, record) => {
        const hasCharacteristics = record?.characteristics;
        if (!hasCharacteristics || !useCharacteristics) return record.index;
        return (
          <>
            <span>{record.index}</span>
            <br></br>
            <DashOutlined />
          </>
        );
      },
    },
    {
      title: "А",
      dataIndex: "art",
      width: "15%",
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.art}</span>;
      },
    },
    {
      title: "Назва",
      dataIndex: "title",
      width: "40%",
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      ...getColumnSearchProps("title", searchInput),
      render: (_, record) => {
        const textColor = record.color;
        return <span style={{ color: textColor }}>{record.title}</span>;
      },
    },
    {
      title: "Інфо",
      dataIndex: "info",
      sorter: (a, b) => a.info - b.info,
      width: "18%",
    },
    {
      title: "Ціна",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      width: "18%",
      render: (_, record) => {
        return (+record.price).toFixed(2);
      },
    },
    {
      title: "К-ть",
      dataIndex: "qty",
      sorter: (a, b) => a.qty - b.qty,
      width: "22%",
      render: (_, record) => {
        return (
          <>
            {settings?.showRealCount ? (
              <>
                <span style={{ fontWeight: "500" }}>
                  {record.isWeight
                    ? record.stockQty.toFixed(3)
                    : parseInt(record.stockQty)}
                </span>
                <br></br>
                <span>
                  {record.isWeight && record.qty
                    ? record?.qty.toFixed(3)
                    : record.qty
                    ? parseInt(record.qty)
                    : 0}
                </span>
              </>
            ) : (
              <span>
                {record.isWeight && record.qty
                  ? record?.qty.toFixed(3)
                  : record.qty
                  ? parseInt(record.qty)
                  : 0}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "+/-",
      dataIndex: "diff",
      width: "15%",
      render: (_, record) => {
        if (!settings?.showRealCount) return 0;
        const precision = record.isWeight ? 3 : 0;
        if (!record.qty) {
          return (0 - +record.stockQty).toFixed(precision);
        } else {
          return (+record.qty - +record.stockQty).toFixed(precision);
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      sorter: (a, b) => {
        const aQty = !a.qty ? 0 : +a.qty;
        const bQty = !b.qty ? 0 : +b.qty;
        const diffA = aQty - +a.stockQty;
        const diffB = bQty - +b.stockQty;
        return diffA - diffB;
      },
    },
    {
      title: "Сума",
      dataIndex: "sum",
      sorter: (a, b) => a.sum - b.sum,
      width: "20%",
      render: (_, record) => {
        if (settings?.showSumByDiff) {
          if (!record.qty) {
            const rowSum = ((0 - +record.stockQty) * +record.price).toFixed(2);
            return rowSum;
          } else {
            const rowSum = (
              (+record.qty - +record.stockQty) *
              +record.price
            ).toFixed(2);
            return rowSum;
          }
        } else {
          const sum = ((+record.qty ? +record.qty : 0) * +record.price).toFixed(
            2
          );
          return sum;
        }
      },
    },
  ];
};
