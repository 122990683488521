import { getServer } from "../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";

export async function statistics(user, docType) {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username: user.username,
        company: user.company.name,
        operation: docType.value,
        action: "statistics",
      }),
    });

    const res = await result.json();

    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function sendTGMessage(message) {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ message, action: "sendTGMessage" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function sendTGMessageTolPrivate(message) {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ message, action: "sendTGMessageToPrivate" }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    console.log(error);
  }
}
