import { message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getServiceSettingsObject } from "../../api/settings/settings";
import BackButton from "../../components/UI/BackButton";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import Main from "../../components/documents/diplacements/Main";
import {
  getColumnsDataGlobal,
  getDiplacementColumns,
} from "../../components/documents/manage-documents/table-components/documents-columns";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import { TABLES } from "../../db/constants/tables";
import { getRecord } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { useAuth } from "../../hook/useAuth";

const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.EDIT;

export default function EditOrder() {
  const params = useParams();
  const id = params.id;
  const { user } = useAuth();
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const diplacementsSettings = getServiceSettingsObject(
    user.settings,
    servicesSettingsTitles.DIPLACEMENTS
  );
  const diplacementColumns = getDiplacementColumns({ action: DOCUMENT_ACTION });
  const columns = useMemo(() => {
    const res = getColumnsDataGlobal(diplacementColumns, diplacementsSettings);
    return res;
  }, [diplacementsSettings, diplacementColumns]);

  const [settings] = useState({
    ...diplacementsSettings,
    showVirtualKeyboard: diplacementsSettings.virtualKeyboard,
    colorDifferents: diplacementsSettings.color_differents,
  });

  const getData = useCallback(async () => {
    const doc = await getRecord(TABLES.DIPLACEMENTS, Number(id));
    if (!doc) {
      message.error("Помилка отримання даних документа");
    }
    const products = doc.products;
    setDocument(doc);
    setProducts(getRecordsForTable(products));
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      TABLES.CURRENT_DIPLACEMENT,
      DOCUMENT_OPEN_ACTIONS.EDIT
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  const afterChangeProductQty = (newProduct) => {
    setProducts((prevProducts) => {
      const productToChange = prevProducts.find(
        (product) => product.guid === newProduct.guid
      );

      if (productToChange) productToChange.qty = newProduct.qty;
      return prevProducts;
    });
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        document={document}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={columns}
        settings={settings}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle title={"Редагування переміщення"}></BottomPageTitle>
    </>
  );
}
