import { getServer } from "../../utils/Environment";

const server = getServer();
const urlProducts = server + "/server/server.php";

export async function updateUserSettInSql(user, field, val, docType) {
  if (val === true) {
    val = 1;
  } else if (val === false) {
    val = 0;
  }

  try {
    const result = await fetch(urlProducts, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.id,
        settName: field,
        val: val,
        docType: docType,
        action: "updateUserSett",
      }),
    });
    const res = await result.json();
    return res;
  } catch (error) {
    // alert("Помилка оновлення налаштувань");
    console.log(error);
    throw error;
    // return false;
  }
}

export const getUserSetting = (settingName, settings, service = "revizia") => {
  if (!settings) return;
  const setting = settings.find(
    (sett) => sett.service === service && sett.name === settingName
  );
  return setting?.value;
};

export const getServiceSettingsObject = (settings, service) => {
  const serviceSettings = settings.filter((sett) => sett.service === service);

  const revisionSettingsObject = serviceSettings.reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
    {}
  );
  return revisionSettingsObject;
};

export const saveWeightTemplate = async (id, template) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ action: "save-weight-template", id, template }),
  });
  return await responce.json();
};

export const getUpdatedSettings = (settings, settName, settValue, service) => {
  const findedSett = settings.find(
    (sett) => sett.service === service && sett.name === settName
  );

  if (findedSett) {
    findedSett.value = settValue;
  } else {
    settings.push({ service, name: settName, value: settValue });
  }

  return settings;
};

export const updateUserWithNewSettings = (
  allSettings,
  settName,
  settValue,
  serviceName,
  user,
  updateCurrentUser
) => {
  const updatedSettings = getUpdatedSettings(
    allSettings,
    settName,
    settValue,
    serviceName
  );
  const updatedLoggedInUser = { ...user, settings: updatedSettings };
  updateCurrentUser(updatedLoggedInUser);
};

export const updateUserSetting = async (
  user,
  SETTING_KEY,
  SETTING_VALUE,
  updateCurrentUser,
  SERVICE
) => {
  const res = await updateUserSettInSql(
    user,
    SETTING_KEY,
    SETTING_VALUE,
    SERVICE
  );
  if (res) {
    updateUserWithNewSettings(
      user.settings,
      SETTING_KEY,
      SETTING_VALUE,
      SERVICE,
      user,
      updateCurrentUser
    );
  }
};
