import { Tabs } from "antd";
import React from "react";
import { getServiceSettingsObject } from "../../../api/settings/settings";
import { servicesSettingsTitles } from "../../../components/documents/_CONSTANTS/settings";
import Global from "./Global";
import Interface from "./Interface";
import Notifications from "./Notifications";

export default function SettingsTabs(props) {
  const globalSettings = getServiceSettingsObject(
    props.user.settings,
    servicesSettingsTitles.GLOBAL
  );

  const items = [
    {
      label: "Загальні",
      key: "main",
      children: (
        <Global
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
          globalSettings={globalSettings}
        ></Global>
      ),
    },
    {
      label: "Інтерфейс",
      key: "revision",
      children: (
        <Interface
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
          globalSettings={globalSettings}
        ></Interface>
      ),
    },
    {
      label: "Сповіщення",
      key: "update",
      children: (
        <Notifications
          user={props.user}
          updateCurrentUser={props.updateCurrentUser}
          globalSettings={globalSettings}
        ></Notifications>
      ),
    },
  ];

  return (
    <>
      <Tabs tabPosition={"top"} items={items} />
    </>
  );
}
