import { DownOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { Dropdown, Space, Tabs } from "antd";
import dayjs from "dayjs";
import { default as React, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  DOCUMENT_OPEN_ACTIONS,
  TABS_TITLES,
} from "../../../../../components/documents/_CONSTANTS/constants";
import { SETTINGS_KEYS } from "../../../../../components/documents/_CONSTANTS/settings";
import {
  clearInvoiceSourceDocDataFromlocalStorage,
  getInvoiceSourceDocDataFromLocalStorage,
} from "../../../../../components/documents/CACHE/localstorage";
import { TABLES } from "../../../../../db/constants/tables";
import {
  addRecord,
  clearObjectStore,
  getAllRecords,
  updateRecord,
} from "../../../../../db/dexie-db/functions";
import { generateDocBarcode } from "../../../../../db/documents-funtions";
import { getTableTotals } from "../../../../../utils/getTableTotals";
import { getOrderProductsQty } from "../../functions";
import InfoTab from "./InfoTab";
import TableProducts from "./TableProducts";

const Main = (props) => {
  const { messageApi, modalApi } = useOutletContext();
  const { priceType, setPriceType, createdFromSource, IGNORE_STORAGE_SOURCE } =
    props;
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const defaultActiveTab =
    props.settings[SETTINGS_KEYS.INVOICES.IGNORE_STORAGE_SOURCE] === true
      ? "1"
      : props.action === DOCUMENT_OPEN_ACTIONS.VIEW ||
        props.action === DOCUMENT_OPEN_ACTIONS.EDIT ||
        props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
      ? "2"
      : "1";

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");
  const [storage, setStorage] = useState();
  const [priceTypes, setPriceTypes] = useState();

  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  useEffect(() => {
    const fetchPriceTypes = async () => {
      const priceTypesFromDb = await getAllRecords(TABLES.PRICE_TYPES);
      if (priceTypesFromDb?.length) setPriceTypes(priceTypesFromDb);
    };
    fetchPriceTypes();
  }, []);

  useEffect(() => {
    if (documentForEdit) {
      setSupplier(documentForEdit.supplierGuid);
      setPriceType(documentForEdit?.priceType);
      setStorage(documentForEdit?.storageGuid);
      // setComment(documentForEdit.comment);
    }
  }, [documentForEdit, setPriceType]);

  useEffect(() => {
    if (!documentForEdit) {
      const sourceDoc = getInvoiceSourceDocDataFromLocalStorage();
      if (sourceDoc) setSupplier(sourceDoc.supplierGuid);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    modalApi.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(
          TABLES.CURRENT_INVOICE
        );
        if (!clearedCurrentOrder) {
          return messageApi.error("Помилка очищення документа");
        }
        clearInvoiceSourceDocDataFromlocalStorage();
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
      canselText: "Скасувати",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (!supplier) {
      return modalApi.confirm({
        title: "Контрагент не вибраний, продовжити?",
        onCancel: () => {
          return;
        },
        onOk: () => {
          saveDocumentHadler();
        },
        okText: "Так",
        canselText: "Скасувати",
      });
    }
    if (!storage && IGNORE_STORAGE_SOURCE) {
      setActiveTab("1");
      messageApi.error("Виберіть склад");
      return;
    }
    saveDocumentHadler();
  };

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE:
        const createdFromOrder = createInvoice(
          props,
          documentForEdit,
          supplier,
          priceType
        );
        if (!createdFromOrder) {
          return;
        }
        setLoadingSaveButton(false);
        clearInvoiceSourceDocDataFromlocalStorage();
        break;
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return messageApi.error("Помилка!");
        }
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return messageApi.error("Додайте товари");
        }
        const updatedDocument = {
          ...props.document,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
          supplierGuid: supplier ? supplier : "",
          sum: getTableTotals(props.products).total,
          comment,
          ...(storage && { storageGuid: storage }),
        };
        const updated = await updateRecord(
          TABLES.INVOICES,
          Number(updatedDocument.id),
          updatedDocument
        );
        if (!updated) {
          setLoadingSaveButton(false);
          return messageApi.error("Помилка оновлення документу!");
        }
        messageApi.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return messageApi.error("Додайте товари");
        }
        const created = await createInvoice(props, documentForEdit, supplier);
        if (!created) {
          return;
        }
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/invoices", { replace: true });
  };

  const createInvoice = async (props, documentForEdit, supplier) => {
    const newDocument = {
      date: dayjs().format("DD.MM.YYYY"),
      created: dayjs(),
      updated: dayjs(),
      exported: "false",
      docBarcode: generateDocBarcode(),
      products:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? getOrderProductsQty(props.products, documentForEdit.products)
          : props.products,
      supplierGuid: supplier ? supplier : "",
      sum: getTableTotals(props.products).total,
      comment,
      sourceGuid:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? documentForEdit?.guid
          : "",
      ...(priceType ? { priceType } : {}),
      ...(storage && { storageGuid: storage }),
    };

    const result = await addRecord(TABLES.INVOICES, newDocument);
    if (!result) {
      setLoadingSaveButton(false);
      messageApi.error(result.messageApi);
      return false;
    }
    const clearedCurrentOrder = await clearObjectStore(TABLES.CURRENT_INVOICE);
    if (!clearedCurrentOrder) {
      messageApi.error("Помилка очищення документа");
      return false;
    }
    messageApi.success("Збережено!");
    return true;
  };

  const shouldShowSaveButton = action !== DOCUMENT_OPEN_ACTIONS.VIEW;

  const operations = shouldShowSaveButton && (
    <Dropdown.Button
      loading={loadingSaveButton}
      size="middle"
      icon={<DownOutlined />}
      menu={{ items }}
      onClick={handleButtonClick}
    >
      <SaveOutlined /> Зберегти
    </Dropdown.Button>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          supplier={supplier}
          setSupplier={setSupplier}
          comment={comment}
          setComment={setComment}
          storage={storage}
          setStorage={setStorage}
          disabledFields={
            props.action !== DOCUMENT_OPEN_ACTIONS.CREATE &&
            props.action !== DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          }
          priceType={priceType}
          setPriceType={setPriceType}
          priceTypes={priceTypes}
          onChangePriceType={props.onChangePriceType}
          IGNORE_STORAGE_SOURCE={IGNORE_STORAGE_SOURCE}
          action={action}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          settings={props.settings}
          loading={props.loading}
          products={props.products}
          createdFromSource={createdFromSource}
          action={action}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          columns={props.columns}
          dbTable={props.dbTable}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        activeKey={activeTab}
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
