import { SearchOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { updateNewQtyDisplayData } from "../../UI/ui-functions";

export default function DefaultModalBody({
  inputDataRef,
  settings,
  enterHandler,
  onFocusDataInput,
  onBlurDataInput,
  decrementQty,
  inputQtyRef,
  onFocusQtyInput,
  onBlurQtyInput,
  currentProduct,
  incrementQty,
  processSearchProduct,
  hideKeyboard,
}) {
  const defaultinputDataMode = hideKeyboard
    ? "none"
    : settings?.digitalKeyboard
    ? "decimal"
    : "text";

  const [inputDataMode, setInputDataMode] = useState(defaultinputDataMode);

  return (
    <>
      <Row>
        <Col span={13}>
          <form onSubmit={() => false} autoComplete="off">
            <InputGroup>
              <Form.Control
                id="inputData"
                className="inputData"
                placeholder="Введіть дані"
                ref={inputDataRef}
                name="fake-name-field"
                aria-autocomplete="none"
                inputMode={inputDataMode}
                onDoubleClick={() => setInputDataMode("text")}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (hideKeyboard) {
                      setInputDataMode("none");
                    }
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    processSearchProduct(enteredData);
                  }
                }}
                autoFocus={true}
                onFocus={onFocusDataInput}
                onBlur={onBlurDataInput}
              />
              <InputGroup.Text
                id="search-icon"
                onClick={() => {
                  const enteredData = inputDataRef.current.value;
                  enterHandler(enteredData);
                  inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </form>
        </Col>
        <Col span={1}></Col>
        <Col span={10}>
          <InputGroup>
            <InputGroup.Text onClick={decrementQty}>-</InputGroup.Text>
            <Form.Control
              id={"inputData"}
              key={"inputData"}
              className="inputData"
              placeholder="К-ть"
              ref={inputQtyRef}
              inputMode={"decimal"}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  const enteredData = e.target.value;
                  e.target.value = "";
                  const formattedEnteredData = enteredData.replace(/,/g, ".");
                  enterHandler(formattedEnteredData);
                }
              }}
              autoFocus={false}
              onBlur={onBlurQtyInput}
              onFocus={onFocusQtyInput}
              onInput={(e) => {
                const value = e.target.value;
                const formattedValue = value.replace(/,/g, ".");
                let enteredQty = formattedValue;
                if (currentProduct)
                  if (!currentProduct.isWeight)
                    enteredQty = parseInt(formattedValue);
                updateNewQtyDisplayData(
                  enteredQty
                    ? +enteredQty
                    : currentProduct?.isWeight
                    ? 0.01
                    : 1,
                  currentProduct
                );
              }}
            />
            <InputGroup.Text onClick={incrementQty}>+</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}
