import { Button } from "antd";

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <h3>Виникла помилка!:</h3>
      <p style={{ color: "red" }}>{error.message}</p>
      <Button type="primary" onClick={resetErrorBoundary}>
        Повторити
      </Button>
    </div>
  );
}
