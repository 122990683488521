import { Divider, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  getUserSubscription,
  removeSubscription,
  saveSubscriptionIdDb,
} from "../../../api/push-notifications/subscription";
import { updateUserSetting } from "../../../api/settings/settings";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../../components/documents/_CONSTANTS/settings";

function Notifications(props) {
  const { user, globalSettings, updateCurrentUser } = props;
  const { messageApi } = useOutletContext();
  const [isSubscriptionEnabled, setIsSubscriptionEnabled] = useState(
    globalSettings[SETTINGS_KEYS.ENABLE_PUSH_NOTIFICATIONS]
  );
  const [isUpdateNotificationEnabled, setIsUpdateNotificationEnabled] =
    useState(globalSettings[SETTINGS_KEYS.UPDATE_NOTIFICATION_ENABLED]);

  useEffect(() => {
    const checkIsSubscribtionEnabled = async () => {
      const subscription = await getUserSubscription(messageApi);
      if (subscription) {
        saveSubscriptionIdDb(
          JSON.stringify(subscription),
          user.id,
          globalSettings[SETTINGS_KEYS.UPDATE_NOTIFICATION_ENABLED]
        );
        setIsSubscriptionEnabled(true);
      } else {
        setIsSubscriptionEnabled(false);
      }
    };

    if (globalSettings[SETTINGS_KEYS.ENABLE_PUSH_NOTIFICATIONS]) {
      // якщо у юзера у налаштуваннях включена підписка на сповіщення, тоді автоматично тут її оновлюємо
      // якщо юзер зайде з іншого телефону, то підписка буде включана, але може не бути збережена у БД
      checkIsSubscribtionEnabled();
    }
  }, []);

  const toggleNotifications = async (value) => {
    try {
      const subscription = await getUserSubscription(messageApi);

      updateUserSetting(
        user,
        SETTINGS_KEYS.ENABLE_PUSH_NOTIFICATIONS,
        value,
        updateCurrentUser,
        servicesSettingsTitles.GLOBAL
      );

      let result;
      if (value) {
        result = await saveSubscriptionIdDb(
          JSON.stringify(subscription),
          user.id
        );
        setIsSubscriptionEnabled(true);
      } else {
        result = await removeSubscription(
          JSON.stringify(subscription),
          user.id
        );
        setIsSubscriptionEnabled(false);
      }

      if (result.status === "error") {
        messageApi.error(result.message);
      } else {
        messageApi.success(result.message);
      }
    } catch (error) {
      console.error("Помилка при налаштуванні пуш-сповіщень:", error);
    }
  };

  const toggleUpdateDataNotification = async (value) => {
    updateUserSetting(
      user,
      SETTINGS_KEYS.UPDATE_NOTIFICATION_ENABLED,
      value,
      updateCurrentUser,
      servicesSettingsTitles.GLOBAL
    );
    const subscription = await getUserSubscription(messageApi);
    const response = await saveSubscriptionIdDb(
      JSON.stringify(subscription),
      user.id,
      value
    );
    if (response.status === "error") {
      messageApi.error(response.message);
    } else {
      messageApi.success(response.message);
      setIsUpdateNotificationEnabled(value);
    }
  };

  return (
    <div>
      <Space direction="horizontal">
        <Switch
          checked={isSubscriptionEnabled}
          onChange={(value) => toggleNotifications(value)}
        />
        Пуш-повідомлення
      </Space>
      {isSubscriptionEnabled && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Divider>Доступні сповіщення</Divider>
          </div>

          <Space direction="horizontal">
            <Switch
              checked={isUpdateNotificationEnabled}
              onChange={toggleUpdateDataNotification}
            />
            Сповіщення про оновлення даних
          </Space>
        </>
      )}
    </div>
  );
}

export default Notifications;
