import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getUserSetting } from "../../../../api/settings/settings";
import { getAllRecords } from "../../../../db/dexie-db/functions";
import {
  getProductsWithIndexes,
  getSortedProductsByTimeAdded,
  searchProductInDb,
  searchProductInDbByTitle,
} from "../../../../db/products-functions";
import { useAuth } from "../../../../hook/useAuth";
import { sortArrayOfObjectsByTitle } from "../../../../utils/array-functions";
import { playSound } from "../../../../utils/play-sound";
import sound from "../../../UI/sound/beep-09.mp3";

import { useOutletContext } from "react-router-dom";
import {
  getModalStyles,
  MODALS_STYLES,
} from "../../../../features/documents/components/modals/styles/ModalsStyles";
import { useKeyboardHeight } from "../../../../hook/keyboardHeight";
import FindedProductsTable from "../../../products/FindedProductsTable";
import { updateNewQtyDisplayData } from "../../UI/ui-functions";
import VirtualKeyboard from "../../VirtualKeyboard";
import {
  DOCUMENT_OPEN_ACTIONS,
  DOCUMENTS_TITLES,
  DRAWER_POSITIONS,
} from "../../_CONSTANTS/constants";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../../_CONSTANTS/settings";
import ModalEnterCharacteristics from "../modal-enter-characteristics/ModalEnterCharacteristics";
import { getProductCharacteristicsFromCurrentDocument } from "../modal-enter-characteristics/characteristics-functions";
import ModalEnterExpirationDate from "../modal-enter-expiration-date/ModalEnterExpirationDate";
import ModalProductInfo from "../modal-product-info/ModalProductInfo";
import DefaultModalBody from "./DefaultModalBody";
import DefaultModalFooter from "./DefaultModalFooter";
import { ModalTitle } from "./ModalTitle";
import TableLastEnteredProducts from "./TableLastEnteredProducts";
import {
  addProductToIndexDb,
  getExistingProductFromDocument,
  getProductPrice,
  getProductsToTable,
  getProductsWithNew,
  updateProductsInTable,
} from "./enter-products-functions";

function ModalEnterProduct(props) {
  const { priceType, setIsModalProductOpen } = props;
  const { documentTitleIfProductNotInDocSouce } = props;

  const { user } = useAuth();

  const inputDataRef = useRef();
  const inputQtyRef = useRef();
  const inputDataPrice = useRef();

  useEffect(() => {
    if (
      props?.backgroundEnteredBarcode &&
      props?.backgroundEnteredBarcode !== ""
    ) {
      processBackGroundEnteredBarcode(props.backgroundEnteredBarcode);
    }
  }, [props?.backgroundEnteredBarcode]);

  const { messageApi, notificationApi } = useOutletContext();

  const [last3Products, setLast3Products] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [findedProducts, setFindedProducts] = useState([]);
  const [isVisibleFindedProducts, setIsVisibleFindedProducts] = useState(false);
  const [focusedInput, setFocusedInput] = useState();
  const [isModalProductInfoOpen, setIsModalProductInfoOpen] = useState(false);
  const [productForShowInfo, setProductForShowInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [isVirtualKeyboardVisible, setIsVirtualKeyboardVisible] =
    useState(false);

  const [isModalEnterExpirationDateOpen, setIsModalEnterExpirationDateOpen] =
    useState(false);

  const keyboardHeight = useKeyboardHeight();

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const drawerPosition = getUserSetting(
    SETTINGS_KEYS.DRAWER_ENTER_PRODUCTS_POSITION,
    user.settings,
    servicesSettingsTitles.GLOBAL
  );

  const preventScroll = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (props.isModalProductOpen) {
      document.addEventListener("wheel", preventScroll, { passive: false });
      document.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    }

    return () => {
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    };
  }, [props.isModalProductOpen]);

  const useEnteringExpirationDates = getUserSetting(
    "useEnteringExpirationDates",
    user.settings,
    "global"
  );

  const FooterAddon = props?.FooterAddon ? props?.FooterAddon : "";

  const processBackGroundEnteredBarcode = async (enteredData) => {
    const product = await searchProductInDb(
      enteredData,
      props.weightTemplate,
      priceType
    );
    if (!product) {
      return processProductNotFound(enteredData);
    }
    setIsModalProductOpen(true);
    processSearchProduct(enteredData, props.products);
    props.setBackgroundEnteredBarcodeValue("");
  };

  const processProductNotFound = (enteredData) => {
    if (props?.settings?.sound) {
      playSound(sound);
    }
    messageApi.error("Товар " + enteredData + " не знайдено!");
    setCurrentProduct(undefined);
    setProductPrice("");
    inputDataRef?.current?.focus();
  };

  const onRowClick = async (record) => {
    if (useEnteringExpirationDates && useCharacteristics) {
      // знайти характеристики товару у документі
      const existingProductCharacteristics =
        await getProductCharacteristicsFromCurrentDocument(
          props.dbTable,
          record
        );
      if (existingProductCharacteristics)
        record.characteristics = existingProductCharacteristics;

      return showModalEnterExpirationDate(record);
    }

    if (useCharacteristics && Boolean(record?.characteristics))
      return showModalCharacteristics(record);

    if (props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE) {
      const productExists = props.products.find(
        (docProduct) => docProduct.guid === record.guid
      );
      if (!productExists) {
        if (props?.settings?.sound) {
          playSound(sound);
        }
        document.querySelector(".modalEnterProductDrawer").style.display =
          "none";
        notificationApi.warning({
          message: `${record.title} немає у ${
            documentTitleIfProductNotInDocSouce
              ? documentTitleIfProductNotInDocSouce
              : "документі"
          }!`,
          duration: 2.5,
          closable: false,
        });
        setTimeout(() => {
          document.querySelector(".modalEnterProductDrawer").style.display =
            "block";
          requestAnimationFrame(() => {
            inputQtyRef.current.focus();
          });
        }, 2500);
      }
    }
    setCurrentProduct(record);

    setProductPrice(record?.price);
    setIsVisibleFindedProducts(false);
    setFindedProducts([]);
    inputQtyRef.current.focus();
  };

  const onContextMenu = (record) => {
    setProductForShowInfo(record);
    setIsModalProductInfoOpen(true);
  };

  const processSearchProductsByTitle = async (enteredData) => {
    setIsLoading(true);
    const products = await searchProductInDbByTitle(enteredData, priceType);

    if (!products.length || !products[0]) {
      setIsLoading(false);
      return processProductNotFound(enteredData);
    }
    if (products.length === 1) {
      setIsLoading(false);
      return processFindedProduct(products[0], props.products);
    }
    setIsVisibleFindedProducts(true);

    const sortedByTitleProducts = products.sort(sortArrayOfObjectsByTitle);
    setFindedProducts(sortedByTitleProducts);
    setIsLoading(false);
    return;
  };

  const processSearchProduct = async (
    enteredData,
    products = props.products
  ) => {
    if (!enteredData) return messageApi.info("Введіть дані");
    if (!Number(enteredData)) {
      inputDataRef.current.blur();
      return processSearchProductsByTitle(enteredData);
    }
    if (isVisibleFindedProducts)
      setIsVisibleFindedProducts(!isVisibleFindedProducts);

    const product = await searchProductInDb(
      enteredData,
      props.weightTemplate,
      priceType
    );
    if (!product) {
      return processProductNotFound(enteredData);
    }
    if (props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE) {
      const productExists = products.find(
        (docProduct) => docProduct.guid === product.guid
      );
      if (!productExists) {
        if (props?.settings?.sound) {
          playSound(sound);
        }

        document.querySelector(".modalEnterProductDrawer").style.display =
          "none";

        notificationApi.warning({
          message: `${product.title} немає у ${
            documentTitleIfProductNotInDocSouce
              ? documentTitleIfProductNotInDocSouce
              : "документі"
          }!`,
          duration: 2.5,
          closable: false,
        });
        setTimeout(() => {
          document.querySelector(".modalEnterProductDrawer").style.display =
            "flex";
          requestAnimationFrame(() => {
            inputQtyRef.current.focus();
          });
        }, 2500);
      }
    }
    if (useEnteringExpirationDates && useCharacteristics) {
      // знайти характеристики товару у документі
      const existingProductCharacteristics =
        await getProductCharacteristicsFromCurrentDocument(
          props.dbTable,
          product
        );
      if (existingProductCharacteristics)
        product.characteristics = existingProductCharacteristics;

      return showModalEnterExpirationDate(product);
    }

    if (useCharacteristics && product?.characteristics) {
      return showModalCharacteristics(product);
    }

    return processFindedProduct(product, products);
  };

  const showModalCharacteristics = (product) => {
    setCurrentProduct(product);
    setIsModalEnterCharacteristicsOpen(true);
  };

  const showModalEnterExpirationDate = (product) => {
    setCurrentProduct(product);
    setIsModalEnterExpirationDateOpen(true);
  };

  const afterCloseModalEnterCharacteristics = async () => {
    if (findedProducts.length) setIsVisibleFindedProducts(false);
    afterChangeProductQty();
    inputDataRef.current.focus();
    setCurrentProduct(undefined);
  };

  const afterCloseModalEnterProduct = async () => {
    if (props?.setBackgroundEnteredBarcodeValue)
      props.setBackgroundEnteredBarcodeValue("");
    setCurrentProduct(undefined);
    setProductPrice("");
    inputQtyRef.current.value = "";
    inputDataRef.current.value = "";

    if (props.dbTable) {
      await updateProductsInTable(props.dbTable, props.setProducts);
    }
  };

  const onClickAddToTableCheckPrice = async () => {
    if (!currentProduct) {
      return messageApi.error("Виберіть товар!");
    }
    const product = { ...currentProduct };
    product.qty = +inputQtyRef.current.value ? +inputQtyRef.current.value : 1;

    const existingProduct = await getExistingProductFromDocument({
      product,
      products: props.products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    addProductToCurrentDocument(product, props.products);
    inputQtyRef.current.value = "";
    setCurrentProduct(undefined);
    inputDataRef.current.focus();
  };

  const enterHandlerCheckPrice = async (
    enteredData,
    products = props.products
  ) => {
    if (!enteredData) return messageApi.info("Введіть дані");

    if (enteredData.length < 6 && currentProduct) {
      return await processAddProductToDocument(
        currentProduct,
        enteredData,
        products
      );
    }
    processSearchProduct(enteredData, products);
  };

  const enterHandler = async (enteredData, products = props.products) => {
    if (!enteredData && !currentProduct) {
      return messageApi.info("Введіть дані");
    }
    if (!currentProduct) return processSearchProduct(enteredData, products);

    if (findedProducts.length) setIsVisibleFindedProducts(false);
    const product = { ...currentProduct };

    return processAddProductToDocument(product, enteredData, products);
  };

  const processAddProductToDocument = async (
    product,
    enteredData,
    products
  ) => {
    if (product?.isWeight) {
      if (!Number(enteredData) || enteredData.length > 6) {
        enteredData = "";
        return messageApi.error(`Введіть кількість для ${product.title}!`);
      }
      product.qty = parseFloat(enteredData);
      enteredData = "";
    } else {
      if (!Number(enteredData) || enteredData.length > 6) {
        product.qty = 1; // Для невагових товарів значення за замовчуванням
      } else {
        product.qty = parseInt(enteredData);
        enteredData = "";
      }
    }

    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    if (props?.settings?.askPrice && inputDataPrice.current) {
      const newPrice = getProductPrice(
        inputDataPrice,
        existingProduct,
        product,
        products
      );
      product.price = newPrice;
    }

    addProductToCurrentDocument(product, products);
    if (enteredData === "") {
      inputDataRef.current.focus();
      setCurrentProduct(undefined);
      setProductPrice("");
    } else {
      processSearchProduct(enteredData, products);
    }
  };

  const addProductWithCharacteristicsToCurrentDocument = (
    productStructure,
    products = props.products
  ) => {
    addProductToCurrentDocument(productStructure, products);
  };

  const addProductToCurrentDocument = async (product, products) => {
    product.timeAdded = +dayjs().valueOf().toString();
    product.key = product.timeAdded;

    if (props.dbTable) {
      const added = await addProductToIndexDb(props.dbTable, product);
      if (!added)
        return messageApi.error("Помилка додавання товару у документ!");
      setLast3Products(await get3LastProducts());
    } else {
      const newProducts = getProductsWithNew(product, products);
      const productsToTable = getProductsToTable(newProducts);
      props.setProducts(productsToTable);
    }
  };

  const get3LastProducts = async () => {
    const productsWithNew = await getAllRecords(props.dbTable);
    const sortedProducts = getSortedProductsByTimeAdded(productsWithNew);
    const sortedProductsWithIndexes = getProductsWithIndexes(sortedProducts);
    return sortedProductsWithIndexes.slice(-3);
  };

  const processFindedProduct = async (product, products) => {
    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    if (product.isWeight && product.qty) {
      const qtyFromBarcode = +product.qty;
      if (existingProduct) {
        product.qty = +existingProduct.qty + qtyFromBarcode;
      }

      addProductToCurrentDocument(product, products);
      setCurrentProduct(undefined);
      setProductPrice("");

      return messageApi.success(`Додано: ${qtyFromBarcode} ${product.title} `);
    }

    if (existingProduct) {
      product.qty = existingProduct.qty;
    }
    updateNewQtyDisplayData(false, product);

    setCurrentProduct(product);
    setProductPrice(product?.price);

    if (props?.focusInputQty) inputQtyRef.current.focus();
  };

  const setProductPrice = (price) => {
    if (inputDataPrice.current) inputDataPrice.current.value = price;
  };
  const onFocusDataInput = (event) => {
    if (props?.settings?.showVirtualKeyboard) {
      setFocusedInput(inputDataRef);
      setIsVirtualKeyboardVisible(true);
    }
    // setTimeout(() => {
    //   event.target.scrollIntoView({ behavior: "smooth", block: "nearest" });
    // }, 300);
  };

  const onFocusPriceInput = () => {
    if (props?.settings?.showVirtualKeyboard && inputDataPrice.current)
      setFocusedInput(inputDataPrice);
    inputDataPrice.current.value = "";
  };

  const onFocusQtyInput = () => {
    if (props?.settings?.showVirtualKeyboard) {
      setFocusedInput(inputQtyRef);
      setIsVirtualKeyboardVisible(true);
    }
  };

  const onBlurDataInput = () => {
    if (props?.settings?.showVirtualKeyboard && isVirtualKeyboardVisible)
      setIsVirtualKeyboardVisible(false);
  };
  const onBlurQtyInput = () => {
    if (props?.settings?.showVirtualKeyboard && isVirtualKeyboardVisible)
      setIsVirtualKeyboardVisible(false);
  };

  const decrementQty = () => {
    if (!currentProduct) return messageApi.error("Не вибраний товар!");
    const qtyInputValue = inputQtyRef.current.value;
    if (
      qtyInputValue === 0 ||
      qtyInputValue === "" ||
      qtyInputValue === 1 ||
      qtyInputValue <= 0
    ) {
      inputQtyRef.current.value = currentProduct?.isWeight ? 0.01 : 1;
    } else {
      inputQtyRef.current.value = qtyInputValue - 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const incrementQty = () => {
    if (!currentProduct) return messageApi.error("Не вибраний товар!");
    const qtyInputValue = +inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "") {
      inputQtyRef.current.value = 1;
    } else {
      inputQtyRef.current.value = qtyInputValue + 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const onClickAddToTable = () => {
    const enteredData = inputQtyRef.current.value;
    inputQtyRef.current.value = "";
    inputDataRef.current.value = "";
    const formattedEnteredData = enteredData.replace(/,/g, ".");
    if (inputDataPrice.current)
      inputDataPrice.current.value = inputDataPrice.current.value.replace(
        /,/g,
        "."
      );
    enterHandler(formattedEnteredData);
    inputDataRef.current.focus();
  };

  const afterChangeProductQty = async () => {
    if (props.dbTable) setLast3Products(await get3LastProducts());
    inputDataRef.current.focus();
  };

  const footer = (
    <div key={"modal-enter-product-footer"}>
      <DefaultModalFooter
        key="DefaultModalFooter"
        onClickAddToTable={
          currentProduct
            ? props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE
              ? onClickAddToTableCheckPrice
              : onClickAddToTable
            : processSearchProduct
        }
        askPrice={props?.settings?.askPrice}
        inputDataPrice={inputDataPrice}
        onFocusPriceInput={onFocusPriceInput}
        onBlurPriceInput={onBlurDataInput}
        FooterAddon={FooterAddon ? <FooterAddon></FooterAddon> : ""}
        currentProduct={currentProduct}
        inputDataRef={inputDataRef}
        inputQtyRef={inputDataRef}
      ></DefaultModalFooter>

      {isVisibleFindedProducts && (
        <>
          <FindedProductsTable
            key="FindedProductsTable"
            findedProducts={findedProducts}
            onRowClick={onRowClick}
            onContextMenu={onContextMenu}
            isloading={isLoading}
          ></FindedProductsTable>
          <ModalProductInfo
            open={isModalProductInfoOpen}
            setIsOpen={setIsModalProductInfoOpen}
            setIsModalProductInfoOpen={setIsModalProductInfoOpen}
            product={productForShowInfo}
          ></ModalProductInfo>
        </>
      )}
      {props?.action === DOCUMENT_OPEN_ACTIONS.CREATE &&
        props.columns &&
        last3Products &&
        drawerPosition !== DRAWER_POSITIONS.BOTTOM && (
          <TableLastEnteredProducts
            lastEnteredProducts={last3Products}
            afterChangeProductQty={afterChangeProductQty}
            isVisible={!isVisibleFindedProducts}
            removeProductFromDocument={props?.removeProductFromDocument}
            isloading={isLoading}
            afterCloseModalEnterCharacteristics={
              afterCloseModalEnterCharacteristics
            }
            addProductWithCharacteristicsToCurrentDocument={
              addProductWithCharacteristicsToCurrentDocument
            }
            settings={props.settings}
            dbTable={props.dbTable}
            columns={props.columns}
          ></TableLastEnteredProducts>
        )}
      {props?.settings?.showVirtualKeyboard && (
        <VirtualKeyboard
          key="VirtualKeyboard"
          isVirtualKeyboardVisible={isVirtualKeyboardVisible}
          focusedInput={focusedInput ? focusedInput : inputDataRef}
          updateNewQtyDisplayData={updateNewQtyDisplayData}
          currentProduct={currentProduct}
          onConfirm={
            props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE ||
            props?.autoAddProductAfterNextScan === false
              ? enterHandlerCheckPrice
              : enterHandler
          }
        ></VirtualKeyboard>
      )}
    </div>
  );

  const modalProps = {
    dbTable: props.dbTable,
    serviceTitle: props?.serviceTitle,
    addProductToCurrentDocument: addProductWithCharacteristicsToCurrentDocument,
    afterClose: afterCloseModalEnterCharacteristics,
    products: props.products,
    setIsModalProductOpen: setIsModalProductOpen,
    product: currentProduct,
  };

  const handleCloseDrawer = useCallback(async () => {
    setIsModalProductOpen(false);
    await afterCloseModalEnterProduct();
  }, []);

  return (
    <>
      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          afterChangeProductQty={afterCloseModalEnterCharacteristics}
          {...modalProps}
        ></ModalEnterCharacteristics>
      )}

      {isModalEnterExpirationDateOpen && (
        <ModalEnterExpirationDate
          setIsModalEnterExpirationDateOpen={setIsModalEnterExpirationDateOpen}
          isModalEnterExpirationDateOpen={isModalEnterExpirationDateOpen}
          {...modalProps}
        ></ModalEnterExpirationDate>
      )}
      <Drawer
        push={{ distance: 0 }}
        loading={isLoading}
        className="modalEnterProductDrawer"
        afterOpenChange={(open) => {
          if (open && !currentProduct) {
            requestAnimationFrame(() => {
              inputDataRef.current.focus();
            });
          }
        }}
        title={
          <ModalTitle
            product={currentProduct}
            action={props.action}
            showStockQty={props.showStockQty}
            products={props.products}
            isCheckPrice={props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE}
          />
        }
        open={props.isModalProductOpen}
        onClose={handleCloseDrawer}
        styles={getModalStyles()}
        style={{
          position:
            drawerPosition === DRAWER_POSITIONS.BOTTOM ? "fixed" : undefined,
          bottom:
            drawerPosition === DRAWER_POSITIONS.BOTTOM
              ? keyboardHeight + 50
              : undefined,
          height: "auto",
        }}
        placement="top"
        closable={false}
        extra={
          <span style={MODALS_STYLES.CLOSE_MODAL} onClick={handleCloseDrawer}>
            <CloseOutlined />
          </span>
        }
        footer={isLoading ? null : footer}
      >
        <DefaultModalBody
          inputDataRef={inputDataRef}
          settings={props.settings}
          enterHandler={
            props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE ||
            props?.autoAddProductAfterNextScan === false
              ? enterHandlerCheckPrice
              : enterHandler
          }
          onFocusDataInput={onFocusDataInput}
          decrementQty={decrementQty}
          inputQtyRef={inputQtyRef}
          onFocusQtyInput={onFocusQtyInput}
          currentProduct={currentProduct}
          incrementQty={incrementQty}
          // BodyAddon={props?.BodyAddon}
          processSearchProduct={processSearchProduct}
          onBlurDataInput={onBlurDataInput}
          onBlurQtyInput={onBlurQtyInput}
          hideKeyboard={props?.serviceTitle === DOCUMENTS_TITLES.CHECK_PRICE}
        ></DefaultModalBody>
      </Drawer>
    </>
  );
}

export default ModalEnterProduct;
