import { message } from "antd";
import { getServer } from "../../utils/Environment";

const server = getServer();

const checkUpdateUrl = server + "/server/server.php";

export async function isUpdateNeeded(
  latestUpdateDate,
  exchangeType,
  user,
  useFtp,
  userGroupPath = undefined,
  signOut
) {
  try {
    const result = await fetch(checkUpdateUrl, {
      method: "POST",
      body: JSON.stringify({
        type: exchangeType,
        user,
        action: "getLatestUpdateDateLocal",
        useFtp,
        userGroupPath,
      }),
    });

    if (result?.status === 401) {
      message.error("Час дії сесії сплив, авторизуйтесь будь-ласка!");
      return signOut(() => {});
    }

    const res = await result.json();

    if (!res) {
      message.error(
        "Помилка перевірки необхідності оновлення, перевірте вигрузку!"
      );
      return false;
    }
    return new Date(res) > new Date(latestUpdateDate) ? true : false;
  } catch (error) {
    message.error("Помилка перевірки необхідності оновлення");
    // console.log(error);
    // throw error;
    // return false;
  }
}
