import { registerGAEvent } from "../../../analytics/React-ga";
import { sendTGMessage } from "../../../statistics/statistics";
import { getServer } from "../../../utils/Environment";

const server = getServer();

const url = server + "/server/server.php";

export const exportDocument = async (docObj, docType, user) => {
  if (user.username === "milkh_01") {
    sendTGMessage(
      `Користувач: ${user.username} експортує документи з токеном: ${user.token}`
    );
  }

  if (!navigator.onLine) {
    return { status: "failed", message: "Немає інтернету!" };
  }

  let fileName = docObj.dateTime.split(" ").join("-");
  fileName = fileName.split(".").join("-");
  fileName = fileName.split(":").join("-");

  const result = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      docType, //це об'єкт: docType.title - Перевірка цін, docType.value - check-price
      username: user.username,
      company: user.company,
      doc: docObj,
      fileName,
      action: "exportDocument",
      useFtp: user.useFtp,
      userGroupPath: user?.userGroup?.path,
      token: user.token,
    }),
  });

  try {
    const res = await result.json();

    registerGAEvent({
      category: user.company.name,
      action: user.username + " - " + docType.title,
      label: "Експорт",
    });
    return res;
  } catch (error) {
    sendTGMessage(
      "Користувач: " + user.username + ", Помилка експорту: " + error
    );
    registerGAEvent({
      category: user.company.name,
      action: user.username + " - " + docType.title,
      label: "Помилка експорту",
    });
    return { status: "failed", message: error };
  }
};
