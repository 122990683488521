import { DownOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { Dropdown, Space, Tabs } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getUserSetting } from "../../../../api/settings/settings";
import { TABLES } from "../../../../db/constants/tables";
import {
  addRecord,
  clearObjectStore,
  getAllRecords,
  updateRecord,
} from "../../../../db/dexie-db/functions";
import { generateDocBarcode } from "../../../../db/documents-funtions";
import { useAuth } from "../../../../hook/useAuth";
import { getTableTotals } from "../../../../utils/getTableTotals";
import {
  DOCUMENT_OPEN_ACTIONS,
  LOCALSTORAGE_VARIABLES,
  TABS_TITLES,
} from "../../_CONSTANTS/constants";
import { SETTINGS_KEYS } from "../../_CONSTANTS/settings";
import InfoTab from "../create/InfoTab";
import TableProducts from "../create/TableProducts";

const Main = (props) => {
  const { priceType, setPriceType } = props;
  const { user } = useAuth();
  const { messageApi, modalApi } = useOutletContext();
  const showSumByDiff = getUserSetting(
    SETTINGS_KEYS.REVISIONS.SHOW_SUMM_BY_DIFFERENTS,
    user.settings
  );

  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [comment, setComment] = useState("");
  const [storage, setStorage] = useState();

  const [priceTypes, setPriceTypes] = useState();

  useEffect(() => {
    const fetchPriceTypes = async () => {
      const priceTypesFromDb = await getAllRecords(TABLES.PRICE_TYPES);
      if (priceTypesFromDb?.length) setPriceTypes(priceTypesFromDb);
    };
    fetchPriceTypes();
  }, []);

  useEffect(() => {
    if (documentForEdit) {
      setComment(documentForEdit.comment);
      setStorage(documentForEdit?.storage);
      setPriceType(documentForEdit?.priceType);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    modalApi.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(props.dbTable);
        if (!clearedCurrentOrder) {
          return messageApi.error("Помилка очищення документа");
        }
        props?.setProducts([]);

        if (props.action === DOCUMENT_OPEN_ACTIONS.EDIT) {
          localStorage.removeItem(LOCALSTORAGE_VARIABLES.EDITING_REVISION_ID);
        }

        localStorage.removeItem(
          LOCALSTORAGE_VARIABLES.CURRENT_REVISION_PRICE_TYPE
        );

        navigate("/revisions", { replace: true });
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    if (props.products.length === 0) {
      setLoadingSaveButton(false);
      return messageApi.error("Додайте товари");
    }
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return messageApi.error("Помилка взагалі не реальна");
        }

        const updatedDocument = {
          ...props.document,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
          sum: getTableTotals(props.products, showSumByDiff).total,
          comment,
          ...(storage && { storage }),
          ...(props?.priceType ? { priceType: props.priceType } : {}),
        };
        const updated = await updateRecord(
          TABLES.REVISIONS,
          Number(updatedDocument.id),
          updatedDocument
        );
        if (!updated) {
          setLoadingSaveButton(false);
          return messageApi.error("Помилка оновлення документу");
        }
        localStorage.removeItem(LOCALSTORAGE_VARIABLES.EDITING_REVISION_ID);

        await processClearDbTable(props.dbTable);
        messageApi.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        const newDocument = {
          date: dayjs().format("DD.MM.YYYY"),
          created: dayjs(),
          updated: dayjs(),
          exported: "false",
          products: props.products,
          sum: getTableTotals(props.products, showSumByDiff).total,
          comment,
          ...(storage && { storage }),
          docBarcode: generateDocBarcode(),
          ...(props?.priceType ? { priceType: props.priceType } : {}),
        };

        const result = await addRecord(TABLES.REVISIONS, newDocument);
        if (!result) {
          setLoadingSaveButton(false);
          return messageApi.error(result.message);
        }
        await processClearDbTable(props.dbTable);
        localStorage.removeItem(
          LOCALSTORAGE_VARIABLES.CURRENT_REVISION_PRICE_TYPE
        );
        messageApi.success("Збережено");
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/revisions");
  };

  const processClearDbTable = async (dbTable) => {
    const cleared = await clearObjectStore(dbTable);
    if (!cleared) {
      return messageApi.error("Помилка очищення поточного документа");
    }
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={() => saveDocumentHadler()}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: props.action === DOCUMENT_OPEN_ACTIONS.VIEW ? null : operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          disabledFields={props.action === DOCUMENT_OPEN_ACTIONS.VIEW}
          comment={comment}
          setComment={setComment}
          storage={storage}
          setStorage={setStorage}
          priceType={priceType}
          setPriceType={setPriceType}
          priceTypes={priceTypes}
          onChangePriceType={props.onChangePriceType}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          columns={props?.columns}
          loading={props.loading}
          products={props.products}
          settings={props.settings}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          dbTable={props.dbTable}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey={priceTypes && !props?.priceType ? "1" : "2"}
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
