import { Dropdown, Modal, Space, Tabs, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableProducts from "./create/TableProducts";

import { DownOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TABLES } from "../../../db/constants/tables";
import {
  addRecord,
  clearObjectStore,
  updateRecord,
} from "../../../db/dexie-db/functions";
import { generateDocBarcode } from "../../../db/documents-funtions";
import { getOrderProductsQty } from "../../../features/documents/invoice/functions";
import { getTableTotals } from "../../../utils/getTableTotals";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../_CONSTANTS/constants";
import InfoTab from "./create/InfoTab";

const Main = (props) => {
  const [modal, contextHolderModal] = Modal.useModal();
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [storageFrom, setStorageFrom] = useState();
  const [storageTo, setStorageTo] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (documentForEdit) {
      setStorageFrom(documentForEdit.storageFrom);
      setStorageTo(documentForEdit.storageTo);
      setComment(documentForEdit.comment);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    modal.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(
          TABLES.CURRENT_DIPLACEMENT
        );
        if (!clearedCurrentOrder) {
          return message.error("Помилка очищення документа");
        }
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (!storageFrom || !storageTo) {
      return modal.confirm({
        title: "Склади не вибрані, продовжити?",
        onCancel: () => {
          return;
        },
        onOk: () => {
          saveDocumentHadler();
        },
      });
    }
    saveDocumentHadler();
  };

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);
    switch (action) {
      case DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE:
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }

        const createdFromOrder = createDiplacement(
          props,
          documentForEdit,
          storageFrom,
          storageTo
        );
        if (!createdFromOrder) {
          return;
        }
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.EDIT:
        if (!documentForEdit || !action) {
          setLoadingSaveButton(false);
          return message.error("Помилка взагалі не реальна");
        }
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }
        const updatedDocument = {
          ...props.document,
          // date: props.document.date,
          updated: dayjs(),
          exported: "false",
          products: props.products,
          id: props.document.id,
          storageFrom,
          storageTo,
          sum: getTableTotals(props.products).total,
          comment,
        };
        const updated = await updateRecord(
          TABLES.DIPLACEMENTS,
          Number(updatedDocument.id),
          updatedDocument
        );
        if (!updated) {
          setLoadingSaveButton(false);
          return message.error("Помилка оновлення документу");
        }
        message.success("Оновлено!");
        setLoadingSaveButton(false);
        break;
      case DOCUMENT_OPEN_ACTIONS.CREATE:
        if (props.products.length === 0) {
          setLoadingSaveButton(false);
          return message.error("Додайте товари");
        }
        const created = createDiplacement(
          props,
          documentForEdit,
          storageFrom,
          storageTo
        );
        if (!created) {
          return;
        }
        setLoadingSaveButton(false);
        break;
      default:
        break;
    }

    return navigate("/diplacements", { replace: true });
  };

  const createDiplacement = async (
    props,
    documentForEdit,
    storageFrom,
    storageTo
  ) => {
    const newDocument = {
      date: dayjs().format("DD.MM.YYYY"),
      created: dayjs(),
      updated: dayjs(),
      exported: "false",
      docBarcode: generateDocBarcode(),
      storageFrom,
      storageTo,
      products:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? getOrderProductsQty(props.products, documentForEdit.products)
          : props.products,
      sum: getTableTotals(props.products).total,
      comment,
      sourceGuid:
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
          ? documentForEdit?.guid
          : "",
    };

    const result = await addRecord(TABLES.DIPLACEMENTS, newDocument);
    if (!result) {
      setLoadingSaveButton(false);
      message.error(result.message);
      return false;
    }
    const clearedCurrentOrder = await clearObjectStore(
      TABLES.CURRENT_DIPLACEMENT
    );
    if (!clearedCurrentOrder) {
      message.error("Помилка очищення поточного документа");
      return false;
    }
    message.success("Збережено");
    return true;
  };

  const operations = (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={handleButtonClick}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          storageFrom={storageFrom}
          storageTo={storageTo}
          setStorageFrom={setStorageFrom}
          setStorageTo={setStorageTo}
          comment={comment}
          setComment={setComment}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          dbTable={props.dbTable}
          action={action}
          afterChangeProductQty={props.afterChangeProductQty}
          removeProductFromDocument={props.removeProductFromDocument}
          settings={props.settings}
          columns={props.columns}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      {contextHolderModal}
      <Tabs
        // onChange={onTablClickFunction}
        // centered
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        // activeKey={activeKey}
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
