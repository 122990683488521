import {
  BarcodeOutlined,
  CameraOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import { DOCUMENT_OPEN_ACTIONS } from "../../../../../components/documents/_CONSTANTS/constants";
import { default as Data, default as MenuComponent } from "../menu/Menu";

function InvoiceFooter(props) {
  const { action } = props;
  const dataMenu = Data;
  return (
    <>
      <MenuComponent
        data={dataMenu}
        settings={props.settings}
        setSettings={props.setSettings}
      ></MenuComponent>
      {(action === DOCUMENT_OPEN_ACTIONS.CREATE ||
        action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE) && (
        <FloatButton.Group
          trigger="click"
          type="primary"
          icon={<PlusCircleOutlined />}
        >
          <FloatButton
            onClick={() => props.setIsModalProductOpen(true)}
            icon={<BarcodeOutlined />}
          />
          <FloatButton
            onClick={() => props.setIsModalCameraBarcodeReaderOpen(true)}
            icon={<CameraOutlined />}
          />
          <FloatButton
            onClick={() => props.setIsModalChooseProductsOpen(true)}
            icon={<UnorderedListOutlined />}
          />
        </FloatButton.Group>
      )}
    </>
  );
}
export default InvoiceFooter;
