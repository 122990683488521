import { useEffect } from "react";
import { getUserSetting } from "../api/settings/settings";
import {
  servicesSettingsTitles,
  SETTINGS_KEYS,
} from "../components/documents/_CONSTANTS/settings";
import { useAuth } from "./useAuth";

const useFullscreen = () => {
  const { user } = useAuth();

  const useFullScreen = getUserSetting(
    SETTINGS_KEYS.USE_FULL_SCREEN,
    user?.settings,
    servicesSettingsTitles.GLOBAL
  );

  useEffect(() => {
    let timeoutId;

    const enterFullscreen = () => {
      if (!document.fullscreenElement) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          try {
            document.documentElement.requestFullscreen();
          } catch (error) {
            console.log(error);
          }
        }, 100);
      }
    };

    const onFullscreenChange = () => {
      if (!document.fullscreenElement && useFullScreen) {
        enterFullscreen();
      }
    };

    // Якщо `useFullScreen` вимкнено – очищаємо обробники
    if (!useFullScreen) {
      document.removeEventListener("click", enterFullscreen);
      document.removeEventListener("touchstart", enterFullscreen);
      document.removeEventListener("pointermove", enterFullscreen);
      document.removeEventListener("fullscreenchange", onFullscreenChange);
      return;
    }

    // Додаємо обробники тільки якщо `useFullScreen === true`
    document.addEventListener("click", enterFullscreen);
    document.addEventListener("touchstart", enterFullscreen);
    document.addEventListener("pointermove", enterFullscreen);
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("click", enterFullscreen);
      document.removeEventListener("touchstart", enterFullscreen);
      document.removeEventListener("pointermove", enterFullscreen);
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, [useFullScreen]);
};

export default useFullscreen;
