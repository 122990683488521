import {
  CloudUploadOutlined,
  DeleteOutlined,
  DiffOutlined,
  EditOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { Col, List, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exportOrder } from "../../../../api/Export/txt/export";
import { exportDocument } from "../../../../api/Export/xml/export";
import { TABLES } from "../../../../db/constants/tables";
import {
  clearObjectStore,
  deleteRecord,
  getAllRecords,
  updateRecord,
} from "../../../../db/dexie-db/functions";
import { getDocForExport } from "../../../../db/documents-funtions";
import { useAuth } from "../../../../hook/useAuth";
import { getInvoiceSourceDocDataFromLocalStorage } from "../../CACHE/localstorage";
import { DOCUMENT_TYPES } from "../../_CONSTANTS/constants";

export default function ModalActions(props) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [modal, contextHolderModal] = Modal.useModal();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const document = props?.document;
  if (!document) return;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "createInvoice":
        if (props.useCheckingOrdersBarcodes)
          return message.error("Заборонено!");
        // перевірити чи порожня таблиця поточного приходу
        const currentInvoiceProducts = await getAllRecords(
          TABLES.CURRENT_INVOICE
        );
        if (currentInvoiceProducts.length > 0) {
          return modal.confirm({
            title: "У вас є незавершений прихід",
            cancelText: "Продовжити",
            closable: true,
            content:
              "Якщо створити новий, тоді товари з незавершеного приходу будуть втрачені!",
            okText: "Створити новий",
            onCancel: () => {
              const storedDocument = getInvoiceSourceDocDataFromLocalStorage();
              navigate(
                `/invoices/create/`,
                storedDocument
                  ? { state: { document: storedDocument } }
                  : undefined
              );
            },
            onOk: async () => {
              const cleared = await clearObjectStore(TABLES.CURRENT_INVOICE);
              if (!cleared) {
                return message.error("Помилка очищення поточного документу");
              } else {
                navigate(`/invoices/create/`, {
                  state: { document },
                });
              }
            },
          });
        }
        navigate(`/invoices/create/`, {
          state: { document },
        });

        break;
      case "createDiplacement":
        const currentDiplacementProducts = await getAllRecords(
          TABLES.CURRENT_DIPLACEMENT
        );
        if (currentDiplacementProducts.length > 0) {
          return modal.confirm({
            title: "У вас є незавершений документ переміщення",
            onCancel: () => {
              return;
            },
            closable: true,
            content:
              "Якщо створити новий, тоді товари з незавершеного переміщення будуть втрачені!",
            okText: "Створити новий",
            onOk: async () => {
              const cleared = await clearObjectStore(
                TABLES.CURRENT_DIPLACEMENT
              );
              if (!cleared) {
                return message.error("Помилка очищення поточного документу");
              } else {
                navigate(`/diplacements/create/`, {
                  state: { document },
                });
              }
            },
          });
        }
        navigate(`/diplacements/create/`, {
          state: { document },
        });

        break;
      case "export":
        setIsSpinning(true);
        let result = {};
        const docForExport = await getDocForExport(document);

        if (user.exchangeType === "xml") {
          result = await exportDocument(
            { ...docForExport, supplier: document.supplier },
            DOCUMENT_TYPES.ORDER,
            user
          );
        } else {
          result = await exportOrder(docForExport, user);
        }

        setIsSpinning(false);
        if (result.status === "failed") {
          return message.error("Помилка експорту документу. " + result.message);
        }
        message.success("Документ відправлено!");

        const newDoc = { ...document, exported: "true" };

        const updated = await updateRecord(TABLES.ORDERS, newDoc.id, newDoc);
        if (!updated) {
          message.error("Помилка оновлення статусу");
        }

        props.setDocumentsToTable();
        props.setIsModalActionsOpen(false);

        break;
      case "viewDocument":
        navigate(`view/${document.id}`);
        break;
      case "editDocument":
        if (props.useCheckingOrdersBarcodes)
          return message.error("Заборонено!");
        if (document.exported === "true") {
          modal.confirm({
            title: "Документ вже віправлено, продовжити?",
            onCancel: () => {
              return;
            },
            onOk: () => {
              navigate(`edit/${document.id}`);
            },
          });
        } else {
          navigate(`edit/${document.id}`);
        }
        break;
      case "deleteDocument":
        if (props.useCheckingOrdersBarcodes)
          return message.error("Заборонено!");

        modal.confirm({
          title: "Видалити документ?",
          onCancel: () => {
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord("Orders", document.id);
            if (!deleted) {
              return message.error("Помилка видалення документа");
            }
            props.setDocumentsToTable();
            props.setIsModalActionsOpen(false);

            message.success("Документ видалено!");
          },
        });

        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Створити прихід",
      key: "createInvoice",
      icon: <DiffOutlined />,
    },
    {
      title: " Створити переміщення",
      key: "createDiplacement",
      icon: <DiffOutlined />,
    },
    {
      title: " Експортувати",
      key: "export",
      icon: <CloudUploadOutlined />,
    },
    {
      title: " Переглянути",
      key: "viewDocument",
      icon: <FundViewOutlined />,
    },
    {
      title: " Змінити",
      key: "editDocument",
      icon: <EditOutlined />,
    },
    {
      title: " Видалити",
      key: "deleteDocument",
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      {contextHolderModal}
      <Modal
        title={"Дії з документом"}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
        closable={!isSpinning}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
}
