import { Button, Input, Space, Switch, message } from "antd";
import React, { useState } from "react";
import {
  getUserSetting,
  updateUserSettInSql,
  updateUserWithNewSettings,
} from "../../../../api/settings/settings";
import { useAuth } from "../../../../hook/useAuth";
import ImportModal from "../../../import/ImportModal";
import Menu from "../../../menu/Menu";
import { servicesSettingsTitles } from "../../_CONSTANTS/settings";
const { Search } = Input;

export default function MenuComponent(props) {
  const { user, updateCurrentUser } = useAuth();

  const printerIpAddress = getUserSetting(
    "printerIpAddress",
    user.settings,
    "global"
  );
  const [ipAddressPrinter] = useState(printerIpAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUpdateDataModal, setIsOpenUpdateDataModal] = useState(false);
  const [isCheckedAutoPrint, setIsCheckedAutoPrint] = useState(
    props?.settings?.autoPrint && printerIpAddress
  );

  const [isCheckedPrintArt, setIsCheckedPrintArt] = useState(
    props?.settings?.printArt
  );
  const onChangeUsePayment = (value) => {
    props.setSettings({ ...props.settings, usePayment: value });
    updateUserSettInSql(user, "usePayment", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "usePayment",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };

  const onChangeDigitalKeyboard = (value) => {
    props.setSettings({ ...props.settings, digitalKeyboard: value });
    updateUserSettInSql(user, "digitalKeyboard", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "digitalKeyboard",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };

  const onChangeVirtualKeyboard = (value) => {
    props.setSettings({ ...props.settings, showVirtualKeyboard: value });
    updateUserSettInSql(user, "showVirtualKeyboard", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "showVirtualKeyboard",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };
  const onChangeShowArtColumn = (value) => {
    props.setSettings({ ...props.settings, showArtColumn: value });
    updateUserSettInSql(user, "showArtColumn", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "showArtColumn",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };

  const onChangePrintArt = (value) => {
    setIsCheckedPrintArt(value);
    props.setSettings({ ...props.settings, printArt: value });
    updateUserSettInSql(user, "printArt", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "printArt",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };

  const onChangeAutoPrint = (value) => {
    if (!printerIpAddress) {
      setIsCheckedAutoPrint(false);
      return message.error(
        "Задайте IP адресу принтера у загальних налаштуваннях!"
      );
    }
    setIsCheckedAutoPrint(value);
    props.setSettings({ ...props.settings, autoPrint: value });
    updateUserSettInSql(user, "autoPrint", value, "seller");
    updateUserWithNewSettings(
      user.settings,
      "autoPrint",
      value,
      servicesSettingsTitles.SALES,
      user,
      updateCurrentUser
    );
  };

  const savePrinterIpAddress = async (value) => {
    if (!value) {
      return message.error("Введіть IP адресу!");
    }
    setIsLoading(true);
    const res = await updateUserSettInSql(
      user,
      "printerIpAddress",
      value,
      "global"
    );
    if (res) {
      message.success("Оновлено!");
      updateUserWithNewSettings(
        user.settings,
        "printerIpAddress",
        value,
        "global",
        user,
        updateCurrentUser
      );
    }
    setIsLoading(false);
  };

  const dataTab = (
    <Button
      onClick={() => {
        setIsOpenUpdateDataModal(true);
      }}
    >
      Оновити дані
    </Button>
  );
  const settingsTab = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.usePayment}
            size="default"
            onChange={onChangeUsePayment}
          />
          Оплата
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showVirtualKeyboard}
            size="default"
            onChange={onChangeVirtualKeyboard}
          />
          Віртуальна клавіатура клавіатура
        </Space>

        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.digitalKeyboard}
            size="default"
            onChange={onChangeDigitalKeyboard}
          />
          Цифрова клавіатура
        </Space>

        <Space direction="horizontal">
          <Switch
            checked={props?.settings?.showArtColumn}
            size="default"
            onChange={onChangeShowArtColumn}
          />
          Показувати артикул
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={isCheckedPrintArt}
            size="default"
            onChange={onChangePrintArt}
          />
          Друкувати артикул
        </Space>
        <Space direction="horizontal">
          <Switch
            checked={isCheckedAutoPrint}
            size="default"
            onChange={onChangeAutoPrint}
          />
          Автоматичний друк
        </Space>
        <br></br>
        <Space direction="vertical">
          <p style={{ margin: "0px" }}>
            Іп адреса принтера і порт
            <br />
            Приклад: 188.188.55.55:9100
          </p>
          <Search
            onKeyDown={(event) => {
              if (!/^[0-9.:]+$/.test(event.key)) {
                if (event.key == "Backspace") return;
                event.preventDefault();
              }
            }}
            type="ip"
            loading={isLoading}
            defaultValue={ipAddressPrinter}
            enterButton="Зберегти"
            allowClear
            onSearch={savePrinterIpAddress}
            // value={ipAddressPrinter}
          />
        </Space>
      </Space>
    </>
  );
  return (
    <>
      <Menu dataTab={dataTab} settingsTab={settingsTab}></Menu>
      <ImportModal
        open={isOpenUpdateDataModal}
        setIsOpenUpdateDataModal={setIsOpenUpdateDataModal}
      ></ImportModal>
    </>
  );
}
