import {
  CloudUploadOutlined,
  DeleteOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { Dropdown, message, Tabs } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { exportDocument } from "../../api/Export/xml/export";
import { getUserSetting } from "../../api/settings/settings";
import ModalBarcodeCameraReaderUniversal from "../../components/camera-barcode-reader/ModalBarcodeCameraReaderUniversal";
import { DOCUMENT_TYPES } from "../../components/documents/_CONSTANTS/constants";
import { servicesSettingsTitles } from "../../components/documents/_CONSTANTS/settings";
import Footer from "../../components/documents/orders/list/Footer";
import TableDocuments from "../../components/documents/orders/list/TableDocuments";
import ExportModal from "../../components/export/ExportModal";
import { TABLES } from "../../db/constants/tables";
import {
  deleteRecord,
  getAllRecords,
  getRecordByKeyValue,
  getRecords,
  updateRecord,
} from "../../db/dexie-db/functions";
import {
  getDocForExport,
  getDocumentByBarcode,
  getDocumentsForExport,
  setDocumentsToTable,
} from "../../db/documents-funtions";
import { useAuth } from "../../hook/useAuth";
import { removeDuplicatesFromArray } from "../../utils/array-functions";

const DOCUMENT_TABLE = TABLES.ORDERS;

export default function Orders() {
  const { user, signOut } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [documentsToExport, setDocumentsToExport] = useState([]);
  const [documentsToExportOriginal, setDocumentsToExportOriginal] = useState(
    []
  );
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [
    isModalCameraBarcodeReaderUniversalOpen,
    setIsModalCameraBarcodeReaderUniversalOpen,
  ] = useState(false);
  const [isBarcodeReaderScanning, setIsBarcodeReaderScanning] = useState(false);

  const useDefaultTableType = getUserSetting(
    "useDefaultTableType",
    user.settings,
    servicesSettingsTitles.ORDERS
  );

  const useCheckingOrdersBarcodes = getUserSetting(
    "useCheckingOrdersBarcodes",
    user.settings,
    servicesSettingsTitles.ORDERS
  );

  useEffect(() => {
    if (useDefaultTableType) {
      setDocumentsToTableDefault();
    } else {
      setDocumentsToTableBySuppliers();
    }
  }, []);

  const fetchDataFromDb = async () => {
    const ordersFromDb = await getAllRecords(TABLES.ORDERS);
    const suppliersGuids = await getSuppliersGuids(ordersFromDb);
    return getDocumentsBySuppliers(suppliersGuids);
  };

  const getDocumentsBySuppliers = async (suppliersGuids) => {
    return await Promise.all(
      suppliersGuids.map(async (guid) => {
        const documents = await getRecords(
          TABLES.ORDERS,
          "supplierGuid",
          guid === "Невідомий контрагент " ? "" : guid
        );
        return { supplierGuid: guid, documents };
      })
    );
  };

  const getSuppliersGuids = async (ordersFromDb) => {
    const suppliersGuids = [];
    ordersFromDb.map((order) => {
      if (!order.supplierGuid) order.supplierGuid = "Невідомий контрагент ";
      const exist = suppliersGuids.find(
        (supplierGuid) => supplierGuid === order.supplierGuid
      );
      if (!exist) suppliersGuids.push(order.supplierGuid);
    });
    return suppliersGuids;
  };

  async function getFormattedDocuments(records) {
    return await Promise.all(
      records.map(async (record, index) => {
        record.key = index + 1;
        record.index = index + 1;
        const supplier = await getRecordByKeyValue(TABLES.SUPPLIERS, {
          guid: record.supplierGuid,
        });
        record.supplierName = supplier?.title
          ? supplier.title
          : "Невідомий контрагент ";
        return record;
      })
    );
  }

  const setDocumentsToTableDefault = async () => {
    return setDocumentsToTable(
      setLoading,
      TABLES.ORDERS,
      user.settings,
      setDocuments,
      getFormattedDocuments
    );
  };

  const setDocumentsToTableBySuppliers = async () => {
    setLoading(true);
    const allDocuments = await fetchDataFromDb();
    setDocuments(await getFormattedDocuments(allDocuments));
    setLoading(false);
  };

  const handleExportTodayDocuments = async () => {
    const todayDocuments = await getRecords(
      TABLES.ORDERS,
      "date",
      dayjs().format("DD.MM.YYYY")
    );

    if (!todayDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(await getDocumentsForExport(todayDocuments));
    setDocumentsToExportOriginal(todayDocuments);
    setIsModalExportOpen(true);
  };

  const handleExportUnexported = async () => {
    const unexportedDocuments = await getRecords(
      TABLES.ORDERS,
      "exported",
      "false"
    );
    if (!unexportedDocuments.length)
      return message.info("Немає даних для відправки");
    setDocumentsToExport(await getDocumentsForExport(unexportedDocuments));
    setDocumentsToExportOriginal(unexportedDocuments);
    setIsModalExportOpen(true);
  };

  const handleDeleteExported = async () => {
    const documentsToDelete = await getRecords(
      TABLES.ORDERS,
      "exported",
      "true"
    );
    if (!documentsToDelete.length) {
      return message.info("Немає документів для видалення");
    }

    deleteDocuments(documentsToDelete);
  };

  const handleDeleteImported = async () => {
    const documentsToDelete = await getRecords(
      TABLES.ORDERS,
      "imported",
      "true"
    );
    if (!documentsToDelete.length) {
      return message.info("Немає документів для видалення");
    }
    deleteDocuments(documentsToDelete);
  };

  const deleteDocuments = async (documentsToDelete) => {
    const isSuccessDeleted = await Promise.all(
      documentsToDelete.map(async (doc) => {
        await deleteRecord(TABLES.ORDERS, doc.id);
      })
    );
    if (isSuccessDeleted) {
      message.success("Успішно!");
      const documentsToTable = await fetchDataFromDb();
      setDocuments(await getFormattedDocuments(documentsToTable));
    }
  };

  const getDocumentsObjectsFromSuppliers = (suppliersDocuments) => {
    const res = suppliersDocuments.map((supplier) => {
      const docs = supplier.documents;
      return docs;
    });
    return res[0];
  };

  const setDocumentsForSuppliers = async (documents) => {
    const suppliersGuids = documents.map((doc) => doc.supplierGuid);

    const uniqueSuppliersGuids = removeDuplicatesFromArray(suppliersGuids);

    const documentsBySuppliers = uniqueSuppliersGuids.map((supplier) => {
      const documentBySupplier = documents.filter(
        (doc) => doc.supplierGuid === supplier
      );
      return { supplierGuid: supplier, documents: documentBySupplier };
    });

    return await getFormattedDocuments(documentsBySuppliers);
    // return documentsBySuppliers;
  };

  const deleteItems = [
    {
      label: "Видалити відправлені",
      key: "1",

      onClick: useCheckingOrdersBarcodes
        ? () => {
            message.error("Заборонено!");
          }
        : handleDeleteExported,
    },
    {
      label: "Видалити імпортовані",
      key: "2",
      onClick: useCheckingOrdersBarcodes
        ? () => {
            message.error("Заборонено!");
          }
        : handleDeleteImported,
    },
  ];
  const exportItems = [
    {
      label: "Відправити сьогоднішні",
      key: "1",
      onClick: handleExportTodayDocuments,
    },
    {
      label: "Відправити невідправлені",
      key: "2",
      onClick: handleExportUnexported,
    },
  ];

  const exportOperations = (
    <Dropdown.Button
      loading={loadingExportButton}
      onClick={() => {
        handleExportUnexported();
      }}
      size="large"
      menu={{ items: exportItems }}
    >
      <CloudUploadOutlined />
    </Dropdown.Button>
  );

  const deleteOperations = (
    <Dropdown.Button size="large" menu={{ items: deleteItems }}>
      <DeleteOutlined />
    </Dropdown.Button>
  );

  const OperationsSlot = {
    left: deleteOperations,
    right: exportOperations,
  };

  const deftabs = [
    {
      label: (
        <span>
          <OrderedListOutlined /> Замовлення
        </span>
      ),
      key: "1",
      children: <></>,
    },
  ];

  const searchDocumentByBarcode = async (barcode, dbTable) => {
    setIsBarcodeReaderScanning(false);
    const document = await getDocumentByBarcode(barcode, dbTable);
    if (!document) {
      return message.error("Документ " + barcode + " не знайдено!");
    }

    const processedDocument = {
      ...document,
      processed: true,
      updated: dayjs(),
    };

    const updated = await updateRecord(
      dbTable,
      +processedDocument.id,
      processedDocument
    );

    if (!updated) return message.error("Помилка оновлення статусу документа");

    // відправити документ на сервер
    const docForExport = await getDocForExport(processedDocument);
    const result = await exportDocument(
      { ...docForExport, supplier: processedDocument.supplier },
      DOCUMENT_TYPES.ORDER,
      user
    );

    if (result.status === "failed") {
      message.error("Помилка експорту документу. " + result.message);
    } else {
      message.success("Документ відправлено!");
      const newDoc = { ...processedDocument, exported: "true" };

      const updated2 = await updateRecord(dbTable, newDoc.id, newDoc);
      if (!updated2) {
        message.error("Помилка оновлення статусу");
      }
    }

    setDocumentsToTable(
      setLoading,
      TABLES.ORDERS,
      user.settings,
      setDocuments,
      useDefaultTableType ? getFormattedDocuments : setDocumentsForSuppliers
    );
    setIsModalCameraBarcodeReaderUniversalOpen(false);
  };

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        centered
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        size={"large"}
        items={deftabs}
      />
      <TableDocuments
        loading={loading}
        documents={documents}
        setDocuments={setDocuments}
        useDefaultTableType={useDefaultTableType}
        useCheckingOrdersBarcodes={useCheckingOrdersBarcodes}
        setDocumentsToTable={
          useDefaultTableType
            ? setDocumentsToTableDefault
            : setDocumentsToTableBySuppliers
        }
      ></TableDocuments>
      {useCheckingOrdersBarcodes ? (
        <ModalBarcodeCameraReaderUniversal
          isModalCameraBarcodeReaderUniversalOpen={
            isModalCameraBarcodeReaderUniversalOpen
          }
          isScanning={isBarcodeReaderScanning}
          setIsScanning={setIsBarcodeReaderScanning}
          processSearchData={searchDocumentByBarcode}
          dbTable={DOCUMENT_TABLE}
          setIsModalCameraBarcodeReaderUniversalOpen={
            setIsModalCameraBarcodeReaderUniversalOpen
          }
        ></ModalBarcodeCameraReaderUniversal>
      ) : (
        ""
      )}
      <ExportModal
        isModalExportOpen={isModalExportOpen}
        setIsModalExportOpen={setIsModalExportOpen}
        docTypeData={DOCUMENT_TYPES.ORDER}
        documentsToExport={documentsToExport}
        setLoadingExportButton={setLoadingExportButton}
        documentsToExportOriginal={documentsToExportOriginal}
        DOCUMENT_TABLE={DOCUMENT_TABLE}
        setDocuments={setDocuments}
        getFormattedDocuments={
          useDefaultTableType ? getFormattedDocuments : setDocumentsForSuppliers
        }
        documents={
          useDefaultTableType
            ? documents
            : getDocumentsObjectsFromSuppliers(documents)
        }
      ></ExportModal>
      <Footer
        useCheckingOrdersBarcodes={useCheckingOrdersBarcodes}
        setIsModalCameraBarcodeReaderUniversalOpen={
          setIsModalCameraBarcodeReaderUniversalOpen
        }
      ></Footer>
    </>
  );
}
