import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getServiceSettingsObject,
  getUserSetting,
} from "../../api/settings/settings";
import { DOCUMENT_OPEN_ACTIONS } from "../../components/documents/_CONSTANTS/constants";
import { getSalesColumns } from "../../components/documents/manage-documents/table-components/documents-columns";
import Main from "../../components/documents/sales/view/Main";
import BackButton from "../../components/UI/BackButton";
import { TABLES } from "../../db/constants/tables";
import { getRecord } from "../../db/dexie-db/functions";
import { getRecordsForTable } from "../../db/documents-funtions";
import { useAuth } from "../../hook/useAuth";

export default function ViewSale() {
  const { user } = useAuth();

  const settingsObj = getServiceSettingsObject(user.settings, "seller");

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings] = useState(settingsObj);

  useEffect(() => {
    const getData = async () => {
      const doc = await getRecord(TABLES.SALES, Number(id));
      if (!doc) {
        message.error("Помилка отримання даних документа");
      }
      const products = doc.products;
      setDocument(doc);
      setProducts(getRecordsForTable(products));
      setLoading(false);
    };

    getData();
  }, [id]);

  const salesColumns = getSalesColumns({
    showArtColumn: settingsObj.showArtColumn,
    useCharacteristics,
  });

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        document={document}
        settings={settings}
        columns={salesColumns}
      ></Main>
      <BackButton></BackButton>
    </>
  );
}
