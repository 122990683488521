import { RollbackOutlined } from "@ant-design/icons";
import { FloatButton, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton(props) {
  const { message, alertOnClick, onBack } = props;
  const [modal, contextHolderModal] = Modal.useModal();

  const navigate = useNavigate();

  return (
    <>
      {contextHolderModal}
      <FloatButton
        icon={<RollbackOutlined />}
        type="primary"
        style={{
          left: 24,
        }}
        onClick={() => {
          if (alertOnClick) {
            return modal.confirm({
              title: message || "Зміни не будуть збережені, продовжити?",
              okText: "Так",
              cancelText: "Ні",
              onOk: () => {
                onBack ? onBack() : navigate(-1);
              },
            });
          } else {
            navigate(-1);
          }
        }}
      />
    </>
  );
}
