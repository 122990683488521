import { BarcodeOutlined, CameraOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";
import { default as Data, default as MenuComponent } from "../menu/Menu";

export default function InvoiceFooter(props) {
  const dataMenu = Data;
  return (
    <>
      <MenuComponent
        data={dataMenu}
        settings={props?.settings}
        setSettings={props?.setSettings}
      ></MenuComponent>
      <FloatButton.Group
        trigger="click"
        type="primary"
        icon={<BarcodeOutlined />}
      >
        <FloatButton
          onClick={() => props.setIsModalProductOpen(true)}
          icon={<BarcodeOutlined />}
        />
        <FloatButton
          onClick={() => props.setIsModalCameraBarcodeReaderOpen(true)}
          icon={<CameraOutlined />}
        />
      </FloatButton.Group>
    </>
  );
}
