import { message } from "antd";
import React, { useEffect, useState } from "react";
import {
  getServiceSettingsObject,
  getUserSetting,
} from "../../api/settings/settings";
import ModalBarcodeCameraReader from "../../components/camera-barcode-reader/ModalBarcodeCameraReader";
import {
  DOCUMENTS_TITLES,
  DOCUMENT_OPEN_ACTIONS,
} from "../../components/documents/_CONSTANTS/constants";
import ModalEnterProduct from "../../components/documents/manage-documents/modal-enter-product/ModalEnterProducts";
import { getSalesColumns } from "../../components/documents/manage-documents/table-components/documents-columns";
import Footer from "../../components/documents/sales/create/Footer";
import Main from "../../components/documents/sales/create/Main";
import BottomPageTitle from "../../components/header/BottomPageTitle";
import ModalChooseProducts from "../../components/products/products-tree/ModalChooseProducts";
import { getDiffColumnVisibility } from "../../components/table-utils/last-entered-products/Columns";
import { TABLES } from "../../db/constants/tables";
import { getAllRecords } from "../../db/dexie-db/functions";
import {
  getDocumentProductsWithoutDeleted,
  getRecordsForTable,
} from "../../db/documents-funtions";
import { setNewProductToTable } from "../../db/products-functions";
import { useAuth } from "../../hook/useAuth";

const DOCUMENT_TITLE = DOCUMENTS_TITLES.SALE;
const DOCUMENT_ACTION = DOCUMENT_OPEN_ACTIONS.CREATE;
const CURRENT_DOCUMENT_TABLE = TABLES.CURRENT_SALE;

export default function CreateSale() {
  const { user } = useAuth();

  const settingsObj = getServiceSettingsObject(user.settings, "seller");

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const [isModalProductOpen, setIsModalProductOpen] = useState(false);
  const [isModalChooseProductsOpen, setIsModalChooseProductsOpen] =
    useState(false);
  const [isModalCameraBarcodeReaderOpen, setIsModalCameraBarcodeReaderOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState(settingsObj);

  useEffect(() => {
    const getData = async () => {
      const products = await getCurrentDocProducts();

      setProducts(getRecordsForTable(products));
      setLoading(false);
    };

    getData();
  }, []);

  const afterChangeProductQty = async (newProduct) => {
    if (!newProduct?.id) return; // Запобігаємо помилкам

    setProducts((prevProducts) =>
      prevProducts.map((prod) =>
        prod.id === newProduct.id ? newProduct : prod
      )
    );
  };

  const getCurrentDocProducts = async () => {
    try {
      return await getAllRecords(CURRENT_DOCUMENT_TABLE);
    } catch (error) {
      message.error("Помилка отримання даних з поточної реалізації");
      throw error;
    }
  };

  const salesColumns = getSalesColumns({
    showArtColumn: settingsObj.showArtColumn,
    useCharacteristics,
  });

  const removeProductFromDocument = async (product) => {
    const arrayWithoutDeletedProduct = await getDocumentProductsWithoutDeleted(
      products,
      product,
      CURRENT_DOCUMENT_TABLE,
      DOCUMENT_ACTION
    );
    setProducts(arrayWithoutDeletedProduct);
  };

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_ACTION}
        settings={settings}
        afterChangeProductQty={afterChangeProductQty}
        removeProductFromDocument={removeProductFromDocument}
        columns={salesColumns}
        dbTable={CURRENT_DOCUMENT_TABLE}
      ></Main>
      <ModalEnterProduct
        action={DOCUMENT_ACTION}
        dbTable={CURRENT_DOCUMENT_TABLE}
        products={products}
        setProducts={setProducts}
        isModalProductOpen={isModalProductOpen}
        setIsModalProductOpen={setIsModalProductOpen}
        settings={settings}
        weightTemplate={user.weightTemplate}
        removeProductFromDocument={removeProductFromDocument}
        serviceTitle={DOCUMENT_TITLE}
        showDiffColumn={getDiffColumnVisibility(DOCUMENT_TITLE, false)}
        focusInputQty={true}
        columns={salesColumns}
      ></ModalEnterProduct>
      <ModalBarcodeCameraReader
        action={DOCUMENT_ACTION}
        products={products}
        setProducts={setProducts}
        setNewProductToTable={setNewProductToTable}
        focusInputQty={true}
        isModalCameraBarcodeReaderOpen={isModalCameraBarcodeReaderOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        dbTable={CURRENT_DOCUMENT_TABLE}
        serviceTitle={DOCUMENT_TITLE}
        settings={settings}
        weightTemplate={user.weightTemplate}
        // updateProducts={getData}
      ></ModalBarcodeCameraReader>
      <ModalChooseProducts
        products={products}
        setProducts={setProducts}
        setNewProductToTable={setNewProductToTable}
        isModalChooseProductsOpen={isModalChooseProductsOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        dbTable={CURRENT_DOCUMENT_TABLE}
        // afterClose={getData}
      ></ModalChooseProducts>
      <Footer
        setIsModalProductOpen={setIsModalProductOpen}
        setIsModalCameraBarcodeReaderOpen={setIsModalCameraBarcodeReaderOpen}
        setIsModalChooseProductsOpen={setIsModalChooseProductsOpen}
        settings={settings}
        setSettings={setSettings}
      ></Footer>
      <BottomPageTitle title={DOCUMENT_TITLE}></BottomPageTitle>
    </>
  );
}
