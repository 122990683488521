import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getUserSetting } from "../../../../../api/settings/settings";
import { DOCUMENT_OPEN_ACTIONS } from "../../../../../components/documents/_CONSTANTS/constants";
import BottomPageTitle from "../../../../../components/header/BottomPageTitle";
import BackButton from "../../../../../components/UI/BackButton";
import { TABLES } from "../../../../../db/constants/tables";
import { getRecord } from "../../../../../db/dexie-db/functions";
import { getRecordsForTable } from "../../../../../db/documents-funtions";
import { useAuth } from "../../../../../hook/useAuth";
import Main from "./Main";

export default function ViewVerification() {
  const { messageApi } = useOutletContext();
  const { user } = useAuth();
  const digitalKeyboard = getUserSetting("digitalKeyboard", user.settings);
  const sounds = getUserSetting("sound", user.settings);
  const colorDifferents = getUserSetting("color_differents", user.settings);
  const additionalColorDifferents = getUserSetting(
    "additionalHighlightDifferents",
    user.settings
  );
  const revisionSettings = {
    digitalKeyboard,
    sounds,
    colorDifferents,
    additionalColorDifferents,
  };

  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState();
  const [products, setProducts] = useState([]);
  const [settings] = useState(revisionSettings);

  useEffect(() => {
    const getData = async () => {
      const doc = await getRecord(TABLES.VERIFICATION, Number(id));

      if (!doc) {
        messageApi.error("Помилка отримання даних документа");
      }
      const products = doc.products;
      setDocument(doc);
      setProducts(getRecordsForTable(products));
      setLoading(false);
    };

    getData();
  }, [id]);

  return (
    <>
      <Main
        products={products}
        setProducts={setProducts}
        loading={loading}
        action={DOCUMENT_OPEN_ACTIONS.VIEW}
        document={document}
        settings={settings}
        disabledFields={true}
      ></Main>
      <BackButton></BackButton>
      <BottomPageTitle title={"Перегляд перевірки"}></BottomPageTitle>
    </>
  );
}
