import { Col, List, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { exportSales } from "../../../../api/Export/txt/export";
import { exportDocument } from "../../../../api/Export/xml/export";
import { processPrintDocument } from "../../../../api/Printing/print";
import { getUserSetting } from "../../../../api/settings/settings";
import { TABLES } from "../../../../db/constants/tables";
import { deleteRecord, updateRecord } from "../../../../db/dexie-db/functions";
import {
  getDocForExport,
  getRealizationForPrint,
  getRecordsForTable,
} from "../../../../db/documents-funtions";
import { useAuth } from "../../../../hook/useAuth";
import { DOCUMENT_TYPES } from "../../_CONSTANTS/constants";
import { tableRowActions } from "../../manage-documents/documents-table/TableRowActions";

export default function ModalActions(props) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [modal, contextHolderModal] = Modal.useModal();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const document = props?.document;
  if (!document) return;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case "print":
        setIsSpinning(true);
        const docForPrint = await getRealizationForPrint(
          user.settings,
          document
        );
        const res = await processPrintDocument(user.settings, docForPrint);
        setIsSpinning(false);
        if (res) {
          message.success("Успішно!");
          props.setIsModalActionsOpen(false);
        } else {
          message.error("Помилка друку!");
        }
        break;
      case "export":
        setIsSpinning(true);

        const docForExport = await getDocForExport(document);
        if (document.exported === "true") {
          setIsSpinning(false);
          return message.info("Документ вже відправлено!");
        }
        let result = {};
        if (user.exchangeType === "xml") {
          result = await exportDocument(
            docForExport,
            DOCUMENT_TYPES.SALE,
            user
          );
        } else {
          result = await exportSales(docForExport, user);
        }

        setIsSpinning(false);
        if (result.status === "failed") {
          return message.error("Помилка експорту документу. " + result.message);
        }
        message.success("Документ відправлено!");

        const newDoc = { ...document, exported: "true" };
        const updated = await updateRecord(TABLES.SALES, newDoc.id, newDoc);
        if (!updated) {
          message.error("Помилка оновлення статусу");
        }

        const newDocuments = props.documents.filter(
          (doc) => doc.id !== newDoc.id
        );
        newDoc.rowsCount = newDoc.products.length;
        props.setDocuments([...newDocuments, newDoc]);
        props.setIsModalActionsOpen(false);

        const autoPrint = getUserSetting("autoPrint", user.settings, "seller");
        const docForPrintOnExport = await getRealizationForPrint(
          user.settings,
          document
        );

        if (autoPrint) {
          const resPrint = await processPrintDocument(
            user.settings,
            docForPrintOnExport
          );

          if (resPrint) {
            message.success("Надруковано");
          } else {
            message.error(JSON.stringify(resPrint));
          }
        }

        break;
      case "viewDocument":
        navigate(`view/${document.id}`, {
          state: { documentNumber: props.documentNumber },
        });
        break;
      case "editDocument":
        if (document.exported === "true") {
          modal.confirm({
            title: "Документ вже віправлено, продовжити?",
            onCancel: () => {
              return;
            },
            onOk: () => {
              navigate(`edit/${document.id}`, {
                state: { documentNumber: props.documentNumber },
              });
            },
          });
        } else {
          navigate(`edit/${document.id}`, {
            state: { documentNumber: props.documentNumber },
          });
        }
        break;
      case "deleteDocument":
        modal.confirm({
          title: "Видалити документ №" + props.documentNumber + "?",
          onCancel: () => {
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord(TABLES.SALES, document.id);
            if (!deleted) {
              return message.error("Помилка видалення документа");
            }
            const newDocuments = props.documents.filter(
              (doc) => doc.id !== document.id
            );
            props.setDocuments(getRecordsForTable(newDocuments));
            props.setIsModalActionsOpen(false);
            message.success("Документ видалено!");
            if (!newDocuments.length) {
              navigate("/");
            }
          },
        });

        break;
      default:
        break;
    }
  };

  return (
    <>
      {contextHolderModal}
      <Modal
        title={"Дії з документом № " + props.documentNumber}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
        closable={!isSpinning}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={tableRowActions}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
}
