import { Typography } from "antd";
import React, { useEffect, useRef } from "react";
import { MODALS_STYLES } from "../../modals/styles/ModalsStyles";

const { Text } = Typography;

export const ModalTitle = ({ product, showStockQty }) => {
  const modalTitleRef = useRef(null);
  const stockRef = useRef(null);
  const priceRef = useRef(null);
  const qtyFromSourceRef = useRef(null);

  useEffect(() => {
    if (!modalTitleRef.current) return;

    if (!product) {
      modalTitleRef.current.textContent = "Введіть дані";
      if (stockRef.current) stockRef.current.textContent = "";
      if (priceRef.current) priceRef.current.textContent = "";
      if (qtyFromSourceRef.current) qtyFromSourceRef.current.textContent = "";
      return;
    }

    modalTitleRef.current.textContent = product.title;
    if (stockRef.current) {
      stockRef.current.textContent = `Залишок: ${
        showStockQty === false ? 0 : product.stockQty
      }`;
    }
    if (priceRef.current) {
      priceRef.current.textContent = `Ціна: ${product.price.toFixed(2)} грн`;
    }

    if (product.qtyFromSource && qtyFromSourceRef.current) {
      qtyFromSourceRef.current.textContent = `Очікувана к-ть: ${product.qtyFromSource}`;
    }
  }, [product, showStockQty]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Text strong ref={modalTitleRef} style={MODALS_STYLES.TITLE} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingTop: "10px",
        }}
      >
        <Text type="secondary" ref={priceRef} style={MODALS_STYLES.TEXT_BASE} />
        <Text type="secondary" ref={stockRef} style={MODALS_STYLES.TEXT_BASE} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "right",
          alignItems: "right",
          width: "100%",
        }}
      >
        <Text
          type="secondary"
          ref={qtyFromSourceRef}
          style={MODALS_STYLES.TEXT_BASE}
        />
      </div>
    </div>
  );
};

export default ModalTitle;
