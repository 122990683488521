import { Col, Row, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { getAllRecords, getRecords } from "../../../db/dexie-db/functions";
import { getProductsWithPricesByType } from "../../../db/products-functions";
import { listToTree } from "../../../utils/list-to-tree";

const { DirectoryTree } = Tree;

const Categories = (props) => {
  const { priceType } = props;
  const [categories, setCategories] = useState();
  // const [parentCategories, setparentCategories] = useState();

  useEffect(() => {
    async function getParentCategories() {
      const categories = await getAllRecords("Categories");
      const categoriesTree = listToTree(categories);

      setCategories(categoriesTree);
    }
    getParentCategories();
  }, []);

  const onSelect = async (keys, info) => {};

  const onExpand = async (keys, info) => {
    if (!info.expanded) {
      return;
    }
    let key = keys.slice(-1)[0];

    const productsFromCategory = await getRecords(
      "Products",
      "parentCode",
      key
    );
    if (productsFromCategory.length > 0) {
      if (priceType) {
        const productsWithPricesByType = await getProductsWithPricesByType(
          productsFromCategory,
          priceType
        );
        props.setProductsToTable(productsWithPricesByType);
      } else {
        props.setProductsToTable(productsFromCategory);
      }

      props.setLoading(false);
      props.setActiveKey("2");
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <DirectoryTree
            style={{ textAlign: "left" }}
            multiple
            defaultExpandParent={false}
            onSelect={onSelect}
            onExpand={onExpand}
            treeData={categories}
          />
        </Col>
      </Row>
    </>
  );
};

export default Categories;
