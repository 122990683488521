import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Modal, notification, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useOutletContext } from "react-router-dom";
import { getUserSetting } from "../../api/settings/settings";
import { searchProductInDb } from "../../db/products-functions";
import { useAuth } from "../../hook/useAuth";
import { playSound } from "../../utils/play-sound";
import { DOCUMENT_OPEN_ACTIONS } from "../documents/_CONSTANTS/constants";
import { getProductCharacteristicsFromCurrentDocument } from "../documents/manage-documents/modal-enter-characteristics/characteristics-functions";
import ModalEnterCharacteristics from "../documents/manage-documents/modal-enter-characteristics/ModalEnterCharacteristics";
import ModalEnterExpirationDate from "../documents/manage-documents/modal-enter-expiration-date/ModalEnterExpirationDate";
import ModalEnterProductFooterAddonExpirationDate from "../documents/manage-documents/modal-enter-expiration-date/ModalEnterProductFooterAddonExpirationDate";
import {
  addProductToIndexDb,
  getExistingProductFromDocument,
  getProductsToTable,
  getProductsWithNew,
  updateProductsInTable,
} from "../documents/manage-documents/modal-enter-product/enter-products-functions";
import { updateNewQtyDisplayData } from "../documents/UI/ui-functions";
import sound from "../UI/sound/beep-09.mp3";
import DefaulModalBodyAddonCamera from "./DefaulModalBodyAddon";
import ScannerDefault from "./default/ScannerDefault";
import Scanner from "./quagga/Scanner";

function ModalBarcodeCameraReader(props) {
  const { priceType } = props;
  const { documentTitleIfProductNotInDocSouce } = props;

  const inputDataRef = useRef();
  const inputQtyRef = useRef();
  const { user } = useAuth();

  const useCharacteristics = getUserSetting(
    "useCharacteristics",
    user.settings,
    "global"
  );

  const useEnteringExpirationDates = getUserSetting(
    "useEnteringExpirationDates",
    user.settings,
    "global"
  );
  const { messageApi, notificationApi } = useOutletContext();
  const [isModalEnterCharacteristicsOpen, setIsModalEnterCharacteristicsOpen] =
    useState(false);
  const [barcodeForSearch, setBarcodeForSearch] = useState();
  const [isScanning, setIsScanning] = useState(true);
  const [currentProduct, setCurrentProduct] = useState();

  const [isModalEnterExpirationDateOpen, setIsModalEnterExpirationDateOpen] =
    useState(false);

  const checkIsWebApiSupport = () => {
    if (!("BarcodeDetector" in window)) {
      return false;
    } else {
      return true;
    }
  };

  const isWebApiSupported = checkIsWebApiSupport();

  useEffect(() => {
    if (props.isModalCameraBarcodeReaderOpen) {
      setIsScanning(true);
    }
  }, [props.isModalCameraBarcodeReaderOpen]);

  useEffect(() => {
    if (barcodeForSearch) {
      processSearchProduct(barcodeForSearch);
      setBarcodeForSearch(null);
    }
  }, [barcodeForSearch]);

  const closeBarcodeReader = async () => {
    setIsScanning(false);
    setCurrentProduct(null);
    setModalTitle(null);
    props.setIsModalCameraBarcodeReaderOpen(false);
    if (props.dbTable) {
      await updateProductsInTable(props.dbTable, props.setProducts);
    }
  };

  const addProductToCurrentDocument = async (product, products) => {
    product.timeAdded = +dayjs().valueOf().toString();
    product.key = product.timeAdded;

    if (props.dbTable) {
      const added = await addProductToIndexDb(props.dbTable, product);
      if (!added) {
        messageApi.error("Помилка додавання товару у документ!");
        return false;
      }
      return true;
    } else {
      const newProducts = getProductsWithNew(product, products);
      const productsToTable = getProductsToTable(newProducts);
      props.setProducts(productsToTable);
    }
  };

  const afterCloseModalEnterCharacteristics = () => {
    setIsScanning(true);
    setCurrentProduct(undefined);
    setModalTitle(null);
  };

  const showModalEnterExpirationDate = (product) => {
    setCurrentProduct(product);
    setIsModalEnterExpirationDateOpen(true);
  };

  const addProductWithCharacteristicsToCurrentDocument = (
    productStructure,
    products
  ) => {
    addProductToCurrentDocument(productStructure, products);
  };

  const processSearchProduct = async (
    enteredData,
    products = props.products
  ) => {
    setIsScanning(false);
    const product = await searchProductInDb(
      enteredData,
      props.weightTemplate,
      priceType
    );

    if (!product) {
      processProductNotFound(enteredData);
      return setTimeout(() => {
        setIsScanning(true);
      }, 1000);
    }
    if (props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE) {
      const productExists = products.find(
        (docProduct) => docProduct.id === product.id
      );
      if (!productExists)
        if (props?.settings?.sound) {
          playSound(sound);
        }
      notificationApi.warning({
        message: `Товару ${product.title} немає у ${
          documentTitleIfProductNotInDocSouce
            ? documentTitleIfProductNotInDocSouce
            : "документі"
        }!`,
        placement: "top",
        closable: false,
      });
    }
    if (useEnteringExpirationDates && useCharacteristics) {
      const existingProductCharacteristics =
        await getProductCharacteristicsFromCurrentDocument(
          props.dbTable,
          product
        );
      if (existingProductCharacteristics)
        product.characteristics = existingProductCharacteristics;

      return showModalEnterExpirationDate(product);
    }

    if (useCharacteristics && product?.characteristics) {
      // const characteristicsToTable = await getProductCharacteristicsToTable(
      //   product,
      //   enteredData,
      //   props.dbTable,
      //   products
      // );
      // product.characteristics = characteristicsToTable;
      setIsScanning(false);
      return showModalCharacteristics(product);
    }

    return processFindedProduct(product, products);
  };

  const showModalCharacteristics = (product) => {
    setCurrentProduct(product);
    setIsModalEnterCharacteristicsOpen(true);
  };

  const processFindedProduct = async (product, products) => {
    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    setIsScanning(false);
    if (product.isWeight && product.qty) {
      const qtyFromBarcode = product.qty;
      if (existingProduct) {
        product.qty = existingProduct.qty + qtyFromBarcode;
      }

      const added = addProductToCurrentDocument(product, products);
      if (added) {
        notification.success({
          description: `${product.title}`,
          message: `Додано: ${qtyFromBarcode.toFixed(3)}`,
          duration: 1,
          placement: "bottom",
          closeIcon: false,
        });

        setModalTitle(undefined);
        inputDataRef.current.blur();
        inputQtyRef.current.blur();
        setCurrentProduct(undefined);
        return setTimeout(() => {
          setIsScanning(true);
        }, 1000);
      }
    }

    if (existingProduct) {
      product.qty = existingProduct.qty;
    }
    setCurrentProduct(product);
    setModalTitle(product);
    updateNewQtyDisplayData(false, product);

    if (props?.focusInputQty) inputQtyRef.current.focus();
  };

  const setModalTitle = (product) => {
    const modalTitle = document.getElementById("cameraModalTitle");
    const currentProductStockData = document.getElementById(
      "currentProductStockDataInCameraModal"
    );
    const currentProductPriceData = document.getElementById(
      "currentProductPriceDataInCameraModal"
    );
    const enteredQtyData = document.getElementById("enteredQtyData");
    if (!product) {
      return setTimeout(() => {
        modalTitle.textContent = "Наведіть на штрих-код";
        currentProductStockData.textContent = "";
        if (currentProductPriceData) currentProductPriceData.textContent = "";
        enteredQtyData.textContent = "";
      }, 100);
    }

    const productTitle = product.title;
    const price = "Ціна: " + product.price.toFixed(2);
    const showStockQty = props?.showStockQty;
    const stockQty = showStockQty === false ? 0 : product.stockQty;
    const stock = "Залишок: " + stockQty;

    setTimeout(() => {
      modalTitle.textContent = productTitle;
      currentProductStockData.textContent = stock;
      currentProductStockData.append(document.createElement("br"));
      if (currentProductPriceData) currentProductPriceData.textContent = price;
    }, 100);
  };

  const processProductNotFound = (enteredData) => {
    if (props?.settings?.sound) {
      playSound(sound);
    }
    messageApi.error("Товар " + enteredData + " не знайдено!");
    setCurrentProduct(undefined);
    setModalTitle(undefined);
  };

  const enterHandler = async (enteredData, products = props.products) => {
    if (!enteredData && !currentProduct) {
      return messageApi.info("Введіть дані");
    }
    if (!currentProduct) return processSearchProduct(enteredData, products);

    const product = { ...currentProduct };

    processAddProductToDocument(product, enteredData, products);
    setIsScanning(true);
  };

  const processAddProductToDocument = async (
    product,
    enteredData,
    products
  ) => {
    if (enteredData.length > 6 || !Number(enteredData)) {
      product.qty = product?.isWeight ? 0.01 : 1;
    } else {
      const enteredQty = product?.isWeight
        ? +enteredData
        : parseInt(+enteredData);
      product.qty = enteredQty;
      enteredData = "";
    }

    const existingProduct = await getExistingProductFromDocument({
      product,
      products,
      dbTable: props.dbTable,
    });

    if (existingProduct) {
      product.qty = existingProduct.qty + product.qty;
    }

    inputDataRef.current.blur();
    inputQtyRef.current.blur();
    addProductToCurrentDocument(product, products);
    if (enteredData === "") {
      setCurrentProduct(undefined);
      setModalTitle(undefined);
    } else {
      processSearchProduct(enteredData, products);
    }
  };

  const decrementQty = () => {
    if (!currentProduct) return messageApi.error("Не вибраний товар!");
    const qtyInputValue = inputQtyRef.current.value;
    if (
      qtyInputValue === 0 ||
      qtyInputValue === "" ||
      qtyInputValue === 1 ||
      qtyInputValue <= 0
    ) {
      inputQtyRef.current.value = currentProduct?.isWeight ? 0.01 : 1;
    } else {
      inputQtyRef.current.value = qtyInputValue - 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const incrementQty = () => {
    if (!currentProduct) return messageApi.error("Не вибраний товар!");
    const qtyInputValue = +inputQtyRef.current.value;
    if (qtyInputValue === 0 || qtyInputValue === "") {
      inputQtyRef.current.value = 1;
    } else {
      inputQtyRef.current.value = qtyInputValue + 1;
    }
    updateNewQtyDisplayData(+inputQtyRef.current.value, currentProduct);
  };

  const toggleScanning = () => {
    setIsScanning(!isScanning);
    setCurrentProduct(null);
    setModalTitle(null);
  };

  const addButtonHandler = async (qty) => {
    if (!qty) qty = 1;

    let product = currentProduct;
    const existingProduct = await getExistingProductFromDocument({
      product,
      products: props.products,
      dbTable: props.dbTable,
    });
    if (existingProduct) {
      product = existingProduct;
    }
    product.timeAdded = +dayjs().valueOf().toString();
    product.key = product.timeAdded;

    product.qty = (product?.qty ? product?.qty : 0) + qty;
    await addProductToCurrentDocument(product, props.products);
    setModalTitle(undefined);
    setCurrentProduct(undefined);
    return setIsScanning(true);
  };

  const newModalTitle = (
    <span id="cameraModalTitle">Наведіть на штрих-код</span>
  );
  const footer = (
    <Row>
      <Col span={11}>
        <Button
          style={{ width: "100%" }}
          key="continue"
          id="modal-barcode-camera-reader-continue"
          onClick={toggleScanning}
        >
          {isScanning ? "Зупинити" : "Продовжити"}
        </Button>
      </Col>
      <Col span={2}></Col>
      <Col span={11}>
        <Button
          style={{ margin: "0px 0px 15px 0px", width: "100%" }}
          key="submit"
          id="modal-barcode-camera-reader-submit"
          type="primary"
          onClick={() => {
            addButtonHandler(+inputQtyRef.current.value);
            inputDataRef.current.value = "";
            inputQtyRef.current.value = "";
            setIsScanning(true);
          }}
        >
          Додати
        </Button>
      </Col>
    </Row>
  );

  const clickAddExpirationDate = (product = currentProduct) => {
    if (!product) return messageApi.info("Введіть дані!");
    setIsModalEnterExpirationDateOpen(true);
  };

  return (
    <>
      {isModalEnterCharacteristicsOpen && (
        <ModalEnterCharacteristics
          key="ModalEnterCharacteristics"
          isModalEnterCharacteristicsOpen={isModalEnterCharacteristicsOpen}
          setIsModalEnterCharacteristicsOpen={
            setIsModalEnterCharacteristicsOpen
          }
          product={currentProduct}
          products={props.products}
          afterClose={afterCloseModalEnterCharacteristics}
          addProductToCurrentDocument={
            addProductWithCharacteristicsToCurrentDocument
          }
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
        ></ModalEnterCharacteristics>
      )}
      {isModalEnterExpirationDateOpen && (
        <ModalEnterExpirationDate
          key="ModalEnterExpirationDate"
          setIsModalEnterExpirationDateOpen={setIsModalEnterExpirationDateOpen}
          isModalEnterExpirationDateOpen={isModalEnterExpirationDateOpen}
          product={currentProduct}
          setIsModalProductOpen={props.setIsModalProductOpen}
          products={props.products}
          afterClose={afterCloseModalEnterCharacteristics}
          addProductToCurrentDocument={
            addProductWithCharacteristicsToCurrentDocument
          }
          dbTable={props.dbTable}
          serviceTitle={props?.serviceTitle}
        ></ModalEnterExpirationDate>
      )}

      <Modal
        title={newModalTitle}
        style={{ top: "10px", padding: "10px 15px !important" }}
        className="modalEnterProduct"
        open={props.isModalCameraBarcodeReaderOpen}
        onCancel={closeBarcodeReader}
        footer={footer}
      >
        {currentProduct?.info && !currentProduct?.characteristics ? (
          <i style={{ textDecoration: "underline" }}>{currentProduct.info}</i>
        ) : (
          ""
        )}
        <Row>
          <Col style={{ padding: "10px 0px" }} span={14}>
            <DefaulModalBodyAddonCamera
              askPrice={props?.settings?.askPrice}
            ></DefaulModalBodyAddonCamera>
            {useEnteringExpirationDates && currentProduct ? (
              <ModalEnterProductFooterAddonExpirationDate
                clickAddExpirationDate={clickAddExpirationDate}
              ></ModalEnterProductFooterAddonExpirationDate>
            ) : (
              ""
            )}
          </Col>
          <Col
            id="enteredQtyData"
            style={{
              textAlign: "right",
              padding: "10px 0px",
              fontSize: "16px",
            }}
            span={10}
          ></Col>
        </Row>
        <span
          style={{
            height: window.screen.height * 0.2,
            position: "relative",
            display: "block",
            overflow: "hidden",
            marginBottom: "10px",
          }}
        >
          {isWebApiSupported ? (
            <>
              <canvas
                id="canvas"
                style={{ width: "100%", height: window.screen.height * 0.2 }}
              ></canvas>
              <ScannerDefault
                isScanning={isScanning}
                setBarcodeForSearch={setBarcodeForSearch}
                searchProduct={processSearchProduct}
              ></ScannerDefault>
            </>
          ) : (
            <>
              <span
                id="video"
                style={{
                  display: "block",
                  position: "absolute",
                  top: -window.screen.height * 0.1,
                }}
              ></span>
              <Scanner
                isScanning={isScanning}
                setBarcodeForSearch={setBarcodeForSearch}
              ></Scanner>
            </>
          )}
        </span>
        <Row>
          <Col span={13}>
            <InputGroup className="mb-3">
              <Form.Control
                className="inputData"
                placeholder="Введіть дані"
                ref={inputDataRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    enterHandler(enteredData);
                  }
                }}
                autoFocus={false}
              />
              <InputGroup.Text
                onClick={() => {
                  const enteredData = inputDataRef.current.value;
                  enterHandler(enteredData);
                  inputDataRef.current.value = "";
                }}
              >
                <SearchOutlined></SearchOutlined>
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col span={1}></Col>
          <Col span={10}>
            <InputGroup className="mb-3">
              <InputGroup.Text onClick={decrementQty}>-</InputGroup.Text>
              <Form.Control
                className="inputData"
                type="tel"
                placeholder="К-ть"
                ref={inputQtyRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    const enteredData = e.target.value;
                    e.target.value = "";
                    const formattedEnteredData = enteredData.replace(/,/g, ".");
                    enterHandler(formattedEnteredData);
                  }
                }}
                onInput={(e) => {
                  const value = e.target.value;
                  if (currentProduct)
                    updateNewQtyDisplayData(value ? +value : 1, currentProduct);
                }}
                autoFocus={false}
              />
              <InputGroup.Text onClick={incrementQty}>+</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default ModalBarcodeCameraReader;
