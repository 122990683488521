export const updateNewQtyDisplayData = (value, product) => {
  if (!product) return;
  const isWeight = product?.isWeight;
  const maxDigits = 6;
  const precision = isWeight ? 3 : 0;

  let numericValue = Number(value);

  if (
    !numericValue ||
    isNaN(numericValue) ||
    value.toString().length > maxDigits
  ) {
    numericValue = isWeight ? 0.01 : 1;
  }

  const previousQty = Number(product.qty || product.enteredQty || 0);
  const newQty = (previousQty + numericValue).toFixed(precision);

  requestAnimationFrame(() => {
    const enteredQtyElement = document.getElementById("enteredQtyData");
    if (enteredQtyElement) {
      enteredQtyElement.textContent =
        numericValue === 0.01 && previousQty === 0
          ? ""
          : `${previousQty} \u2192 ${newQty}`;
    }
  });
};
