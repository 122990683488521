import { Col, List, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { exportInvoice } from "../../../../../api/Export/txt/export";
import { exportDocument } from "../../../../../api/Export/xml/export";
import { getVerificationSourceDocFromLocalStorage } from "../../../../../components/documents/CACHE/localstorage";
import {
  DOCUMENT_ACTIONS,
  DOCUMENT_TYPES,
} from "../../../../../components/documents/_CONSTANTS/constants";
import { TABLES } from "../../../../../db/constants/tables";
import {
  clearObjectStore,
  deleteRecord,
  getAllRecords,
  updateRecord,
} from "../../../../../db/dexie-db/functions";
import {
  getDocForExport,
  getRecordsForTable,
} from "../../../../../db/documents-funtions";
import { useAuth } from "../../../../../hook/useAuth";
import { ROUTES } from "../../../../../router/ROUTES";

export default function ModalActions(props) {
  const { messageApi, modalApi } = useOutletContext();
  const [isSpinning, setIsSpinning] = useState(false);

  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const document = props?.document;
  if (!document) return;

  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (e, record) => {
    switch (record.key) {
      case DOCUMENT_ACTIONS.EXPORT:
        setIsSpinning(true);

        let result = {};
        const docForExport = await getDocForExport(document);

        if (user.exchangeType === "xml") {
          result = await exportDocument(
            { ...docForExport, supplier: document.supplier },
            DOCUMENT_TYPES.INVOICE,
            user
          );
        } else {
          result = await exportInvoice(docForExport, user);
        }

        setIsSpinning(false);
        if (result.status === "failed") {
          return messageApi.error(
            "Помилка експорту документу. " + result.message
          );
        }
        messageApi.success("Документ відправлено!");

        const newDoc = { ...document, exported: "true" };

        const updated = await updateRecord(TABLES.INVOICES, newDoc.id, newDoc);
        if (!updated) {
          messageApi.error("Помилка оновлення статусу");
        }

        const newDocuments = props.documents.filter(
          (doc) => doc.id !== newDoc.id
        );
        newDoc.rowsCount = newDoc.products.length;
        // newDoc.key = newDocuments.length + 1;
        // newDoc.index = newDoc.key;
        props.setDocuments([...newDocuments, newDoc]);
        props.setIsModalActionsOpen(false);

        break;
      case DOCUMENT_ACTIONS.VIEW:
        navigate(`view/${document.id}`, {
          state: { documentNumber: props.documentNumber },
        });
        break;
      case DOCUMENT_ACTIONS.EDIT:
        if (document.exported === "true") {
          modalApi.confirm({
            title: "Документ вже віправлено, продовжити?",
            onCancel: () => {
              return;
            },
            onOk: () => {
              navigate(`edit/${document.id}`, {
                state: { documentNumber: props.documentNumber },
              });
            },
          });
        } else {
          navigate(`edit/${document.id}`, {
            state: { documentNumber: props.documentNumber },
          });
        }
        break;
      case DOCUMENT_ACTIONS.DELETE:
        modalApi.confirm({
          title: "Видалити документ №" + props.documentNumber + "?",
          onCancel: () => {
            return;
          },
          onOk: async () => {
            const deleted = await deleteRecord("Invoices", document.id);
            if (!deleted) {
              return messageApi.error("Помилка видалення документа");
            }
            const newDocuments = props.documents.filter(
              (doc) => doc.id !== document.id
            );
            props.setDocuments(getRecordsForTable(newDocuments));
            props.setIsModalActionsOpen(false);
            messageApi.success("Документ видалено!");
            if (!newDocuments.length) {
              navigate("/");
            }
          },
        });
        break;
      case DOCUMENT_ACTIONS.VERIFY:
        const currentVerificationProduct = await getAllRecords(
          TABLES.CURRENT_VERIFICATION
        );
        if (currentVerificationProduct.length > 0) {
          return modalApi.confirm({
            title: "У вас є незавершена перевірка",
            cancelText: "Продовжити",
            closable: true,
            content:
              "Якщо створити нову, тоді товари з незавершеної перевірки будуть втрачені!",
            okText: "Створити нову",
            onCancel: () => {
              const storedDocument = getVerificationSourceDocFromLocalStorage();
              return navigate(
                ROUTES.VERIFICATION.CREATE,
                storedDocument
                  ? {
                      state: {
                        document: storedDocument,
                      },
                    }
                  : undefined
              );
            },
            onOk: async () => {
              const cleared = await clearObjectStore(
                TABLES.CURRENT_VERIFICATION
              );
              if (!cleared) {
                return messageApi.error("Помилка очищення поточного документу");
              } else {
                return navigate(ROUTES.VERIFICATION.CREATE, {
                  state: { document, createdFromAnotherSource: true },
                });
              }
            },
          });
        }
        navigate(ROUTES.VERIFICATION.CREATE, {
          state: { document, createdFromAnotherSource: true },
        });

        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: " Експортувати",
      key: DOCUMENT_ACTIONS.EXPORT,
      icon: <CloudUploadOutlined />,
    },
    {
      title: " Перевірити",
      key: DOCUMENT_ACTIONS.VERIFY,
      icon: <CheckCircleOutlined />,
    },
    {
      title: " Переглянути",
      key: DOCUMENT_ACTIONS.VIEW,
      icon: <FundViewOutlined />,
    },
    {
      title: " Змінити",
      key: DOCUMENT_ACTIONS.EDIT,
      icon: <EditOutlined />,
    },
    {
      title: " Видалити",
      key: DOCUMENT_ACTIONS.DELETE,
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <Modal
        title={"Дії з документом № " + props.documentNumber}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
        closable={!isSpinning}
      >
        <Spin spinning={isSpinning}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            size={"default"}
            renderItem={(item) => (
              <List.Item
                onClick={(e) => handleMenuClick(e, item)}
                style={{ paddingLeft: "0px" }}
              >
                <Col span={2}>{item.icon}</Col>
                <Col span={22}>{item.title}</Col>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
}
