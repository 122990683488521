import { CloudUploadOutlined } from "@ant-design/icons";
import { Checkbox, Table } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useState } from "react";
import ModalActions from "./ModalActions";
dayjs.extend(isBetween);

export default function (props) {
  const [isModalActionsOpen, setIsModalActionsOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(null);

  const columns = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      width: "10%",
      sorter: (a, b) => a.index - b.index,
      defaultSortOrder: "descend",
    },
    {
      title: "Дата",
      dataIndex: "created",
      width: "15%",
      key: "created",
      render: (_, record) => {
        const created = dayjs(record.created.$d).format("DD.MM HH:mm");
        return created;
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Склад",
      dataIndex: "storageName",
      key: "storageName",
      width: "40%",
    },
    // {
    //   title: "Коментар",
    //   dataIndex: "comment",
    //   key: "comment",
    //   width: "40%",
    // },
    {
      title: "Сума",
      dataIndex: "sum",
      key: "sum",
      width: "25%",
      render: (_, record) => {
        return Number(record.sum).toFixed(2);
      },
    },
    {
      title: <CloudUploadOutlined />,
      dataIndex: "exported",
      key: "exported",
      width: "10%",
      render: (_, record) => {
        return (
          <Checkbox
            id={record.index}
            disabled
            checked={record.exported === "true" ? true : false}
          ></Checkbox>
        );
      },
    },
  ];

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setIsModalActionsOpen(true);
        setDocument(record);
        setDocumentNumber(record.index);
      },
    };
  };

  const expandedRowKeys = props.documents
    .filter((record) => record.comment)
    .map((record) => record.key);

  return (
    <>
      <Table
        loading={props.loading}
        pagination={{ position: ["bottomCenter"], hideOnSinglePage: true }}
        onRow={onRowAction}
        columns={columns}
        dataSource={props.documents}
        size={"middle"}
        tableLayout="fixed"
        expandable={{
          expandedRowClassName: "expanded-comment",
          expandedRowRender: (record) =>
            record.comment ? (
              <span style={{ margin: 0 }}>Коментар: {record.comment}</span>
            ) : null,
          childrenColumnName: "children",
          rowExpandable: (record) => !!record.comment,
          showExpandColumn: false,
          expandedRowKeys: expandedRowKeys,
        }}
      />
      <ModalActions
        document={document}
        setDocument={setDocument}
        documentNumber={documentNumber}
        setIsModalActionsOpen={setIsModalActionsOpen}
        isModalActionsOpen={isModalActionsOpen}
        setDocuments={props.setDocuments}
        documents={props.documents}
      ></ModalActions>
    </>
  );
}
