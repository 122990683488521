import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { DOCUMENT_OPEN_ACTIONS, TABS_TITLES } from "../../_CONSTANTS/constants";
import InfoTab from "../create/InfoTab";
import TableProducts from "../create/TableProducts";

const Main = (props) => {
  const documentForEdit = props?.document;

  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (props?.document) {
      setSupplier(documentForEdit.supplierGuid);
      setComment(documentForEdit.comment);
    }
  }, [props?.document]);

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <InfoTab
          disabledFields={true}
          supplier={supplier}
          setSupplier={setSupplier}
          comment={documentForEdit?.comment}
          setComment={setComment}
        ></InfoTab>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <TableProducts
          loading={props.loading}
          products={props.products}
          setProducts={props.setProducts}
          disabledFields={true}
          action={props.action}
          columns={props.columns}
          settings={props.settings}
        ></TableProducts>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        defaultActiveKey={
          props.action === DOCUMENT_OPEN_ACTIONS.VIEW ||
          props.action === DOCUMENT_OPEN_ACTIONS.EDIT ||
          props.action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE
            ? "2"
            : "1"
        }
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
