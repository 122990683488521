import { Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DOCUMENT_OPEN_ACTIONS } from "../../../../../components/documents/_CONSTANTS/constants";
import ModalEditProductQty from "../../../../../components/documents/manage-documents/ModalEditProductQty";
import {
  applyUserColors,
  getRowClassNameGlobal,
} from "../../../../../components/documents/manage-documents/table-components/documents-columns";
import { TableTotals } from "../../../../../components/table-utils/TableTotals";
import { getTableTotals } from "../../../../../utils/getTableTotals";

export default function TableProducts(props) {
  const { createdFromSource } = props;
  useEffect(() => {
    applyUserColors(props?.settings);
  }, [props?.settings]);

  const [isModalChangeProductQtyOpen, setIsModalChangeProductQtyOpen] =
    useState(false);
  const [productToChangeQty, setProductToChangeQty] = useState();

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onDoubleClickHandler = (record) => {
    setProductToChangeQty(record);
    setIsModalChangeProductQtyOpen(true);
  };

  const memoizedRowClassName = useCallback(
    (record) => {
      return getRowClassNameGlobal(record, createdFromSource, props?.settings);
    },
    [createdFromSource, props?.settings]
  );
  return (
    <div>
      <Table
        onRow={(record) => {
          if (props?.action === DOCUMENT_OPEN_ACTIONS.VIEW) return;
          return {
            onDoubleClick: (event) => {
              onDoubleClickHandler(record);
            },
            onContextMenu: (event) => {
              onDoubleClickHandler(record);
            },
          };
        }}
        tableLayout="fixed"
        columns={props.columns}
        pagination={{
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: props?.settings?.pageSize ? props?.settings?.pageSize : 100,
        }}
        dataSource={props?.products}
        rowClassName={memoizedRowClassName}
        onChange={onChange}
        loading={props.loading}
        title={() => {
          const totals = getTableTotals(props?.products);
          return (
            <TableTotals
              quantity={totals.quantity.toFixed(3)}
              total={totals.total.toFixed(2)}
              productsCount={props?.products.length}
            />
          );
        }}
      />
      {isModalChangeProductQtyOpen &&
        props?.action !== DOCUMENT_OPEN_ACTIONS.VIEW && (
          <ModalEditProductQty
            dbTable={props.dbTable}
            isModalChangeLastProductOpen={isModalChangeProductQtyOpen}
            setIsModalChangeLastProductOpen={setIsModalChangeProductQtyOpen}
            afterChangeProductQty={props.afterChangeProductQty}
            productToChangeQty={productToChangeQty}
            removeProductFromDocument={props.removeProductFromDocument}
            settings={props.settings}
          ></ModalEditProductQty>
        )}
    </div>
  );
}
