import { Typography } from "antd";
import React, { useEffect, useRef } from "react";

import { MODALS_STYLES } from "../../../../features/documents/components/modals/styles/ModalsStyles";
import { DOCUMENT_OPEN_ACTIONS } from "../../_CONSTANTS/constants";

const { Text } = Typography;

export const ModalTitle = ({
  product,
  action,
  showStockQty,
  products,
  isCheckPrice,
}) => {
  const modalTitleRef = useRef(null);
  const stockRef = useRef(null);
  const priceRef = useRef(null);
  const qtyFromSourceRef = useRef(null);
  const newQtyRef = useRef(null);

  useEffect(() => {
    if (!modalTitleRef.current) return;

    if (!product) {
      modalTitleRef.current.textContent = "Введіть дані";
      if (stockRef.current) stockRef.current.textContent = "";
      if (priceRef.current) priceRef.current.textContent = "";
      if (qtyFromSourceRef.current) qtyFromSourceRef.current.textContent = "";
      if (newQtyRef.current) newQtyRef.current.textContent = "";
      return;
    }

    modalTitleRef.current.textContent = product.title;
    if (stockRef.current) {
      stockRef.current.textContent = `Залишок: ${
        showStockQty === false ? 0 : product.stockQty
      }`;
    }
    if (priceRef.current) {
      priceRef.current.textContent = `Ціна: ${product.price.toFixed(2)}`;
    }
    if (
      (action === DOCUMENT_OPEN_ACTIONS.CREATE_FROM_SOURCE ||
        action === DOCUMENT_OPEN_ACTIONS.VERIFY) &&
      qtyFromSourceRef.current
    ) {
      const qtyFromSource =
        products.find((prod) => prod.guid === product.guid)?.qtyFromSource || 0;
      qtyFromSourceRef.current.textContent =
        qtyFromSource > 0 ? `Очікувана к-ть: ${qtyFromSource}` : "";
    }
  }, [product, action, showStockQty, products]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div>
        <Text
          strong
          ref={modalTitleRef}
          style={{ ...MODALS_STYLES.TITLE, width: "100%" }}
        />
        {/* Контейнер для залишку та ціни */}
        <div
          style={{
            display: "flex",
            flexDirection: isCheckPrice ? "row" : "column",
            justifyContent: isCheckPrice ? "space-between" : "flex-start",
            alignItems: "center",
            width: "100%",
            paddingTop: "10px",
          }}
        >
          <Text
            type="secondary"
            ref={priceRef}
            style={{
              ...MODALS_STYLES.TEXT_BASE,
              textAlign: "left",
              width: isCheckPrice ? "auto" : "100%",
              fontSize: isCheckPrice && "large",
              color: isCheckPrice && "green",
            }}
          />
          <Text
            type="secondary"
            ref={stockRef}
            style={{
              ...MODALS_STYLES.TEXT_BASE,
              textAlign: isCheckPrice ? "right" : "left",
              width: isCheckPrice ? "auto" : "100%",
              fontSize: isCheckPrice && "large",
              color: isCheckPrice && "green",
            }}
          />
        </div>

        <Text
          type="secondary"
          ref={qtyFromSourceRef}
          style={MODALS_STYLES.TEXT_BASE}
        />
      </div>

      {product?.info && !product.characteristics && (
        <Text italic underline={true}>
          {product.info}
        </Text>
      )}

      {product && !isCheckPrice && (
        <div
          id="enteredQtyData"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            padding: "10px",
            fontSize: "16px",
          }}
        ></div>
      )}
    </div>
  );
};
