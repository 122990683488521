import { message } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  getProductsFileModifiedDate,
  setProductsFileModifiedDate,
} from "../../../components/documents/CACHE/localstorage";
import { TABLES } from "../../../db/constants/tables";
import { getRecordByKeyValue } from "../../../db/dexie-db/functions";
import { getServer } from "../../../utils/Environment";
import { getNumberFromString } from "../../../utils/NormalizeData";
dayjs.extend(customParseFormat);

const server = getServer();

const url = server + "/server/server.php";

const getDataFromServer = async (
  username,
  action,
  useFtp,
  userGroupPath,
  deleteDocumentsFilesAfterImport,
  productsFileModifiedDate
) => {
  try {
    const result = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        username,
        action,
        useFtp,
        userGroupPath,
        deleteDocumentsFilesAfterImport,
        productsFileModifiedDate,
      }),
    });
    const res = await result.json();

    return res;
  } catch (error) {
    return { status: "error", message: `Помилка оновлення. ${error}` };
  }
};

const getProductsFromXmlElement = async (childrens) => {
  return await Promise.all(
    childrens.map(async (element, ind) => {
      if (!element.guid) return;
      const product = await getRecordByKeyValue(TABLES.PRODUCTS, {
        guid: element.guid,
      });

      const timeAdded = +dayjs().valueOf().toString() + ind;
      if (element?.stockQty && product) product.stockQty = element?.stockQty;
      return {
        ...product,
        qty: getNumberFromString(element.qty),

        price: getNumberFromString(element.price),
        timeAdded,
        index: ind + 1,
      };
    })
  );
};

const getProductsFromXmlElementForVerification = async (childrens) => {
  return await Promise.all(
    childrens.map(async (element) => {
      const product = await getRecordByKeyValue(TABLES.PRODUCTS, {
        guid: element.guid,
      });
      if (!product) {
        message.error("Помилка отримання товару з документа");
        console.log("Помилка отримання товару з документа", product);
      }
      return {
        ...product,
        qtyFromSource: getNumberFromString(element.qty),
        price: getNumberFromString(element.price),
      };
    })
  );
};

export const getFormattedDocs = async (documents) => {
  let badProductsExists = false;
  const res = await Promise.all(
    documents.map(async (doc) => {
      const products = await getProductsFromXmlElement(doc.products);
      const badProducts = products.filter(
        (product) => product.guid === undefined
      );

      if (badProducts.length) {
        badProductsExists = true;
        return false;
      }
      const id = getNumberFromString(doc.id);
      if (!Number(id)) {
        message.error(
          `Помилка завантаження документа з id: ${doc.id}. Id документа має бути числом!`
        );
        return false;
      }
      const created = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const updated = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const sum = getNumberFromString(doc.sum);

      return {
        ...doc,
        imported: "true",
        exported: "false",
        sum,
        products,
        id,
        created,
        updated,
        date: dayjs(created.$d).format("DD.MM.YYYY"),
      };
    })
  );

  if (badProductsExists)
    message.error(
      "Не всі документи завантажено, частину товарів не знайдено у вигрузці!"
    );
  return res;
};

export const getFormattedDocsForVerification = (documents) => {
  return Promise.all(
    documents.map(async (doc) => {
      const products = await getProductsFromXmlElementForVerification(
        doc.products
      );
      const id = getNumberFromString(doc.id);
      const created = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const updated = dayjs(doc.dateTime, "DD.MM.YYYY HH:mm:ss");
      const sum = getNumberFromString(doc.sum);

      return {
        ...doc,
        exported: "false",
        verified: "false",
        sum,
        products,
        id,
        created,
        updated,
        date: dayjs(created.$d).format("DD.MM.YYYY"),
      };
    })
  );
};

export async function getDataFromXml(
  username,
  useFtp,
  userGroupPath = undefined,
  deleteDocumentsFilesAfterImport = undefined
) {
  const productsFileModifiedDate = getProductsFileModifiedDate();

  const response = await getDataFromServer(
    username,
    "import-all-data",
    useFtp,
    userGroupPath,
    deleteDocumentsFilesAfterImport,
    productsFileModifiedDate
  );
  if (response.status === "error") {
    message.error(response.message);
    console.log(response.message);
    return false;
  }

  if (response.productsFileModifiedDate)
    setProductsFileModifiedDate(response.productsFileModifiedDate);

  const isProductsFileUpdated = response.productsFileModifiedDate
    ? response.productsFileModifiedDate !== productsFileModifiedDate
    : true;

  return {
    documents: JSON.parse(response.data.documents),
    products: JSON.parse(response.data.products),
    categories: JSON.parse(response.data.categories),
    storages: JSON.parse(response.data.storages),
    suppliers: JSON.parse(response.data.suppliers),
    documentsForVerification: JSON.parse(
      response.data.documentsForVerification
    ),
    priceTypes: JSON.parse(response.data.priceTypes),
    productsPrices: JSON.parse(response.data.productsPrices),
    isProductsFileUpdated,
  };
}
