import { DownOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { Dropdown, Space, Tabs } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TABLES } from "../../../../../db/constants/tables";
import {
  addRecord,
  clearObjectStore,
  updateRecord,
} from "../../../../../db/dexie-db/functions";
import { generateDocBarcode } from "../../../../../db/documents-funtions";
import { ROUTES } from "../../../../../router/ROUTES";

import { TABS_TITLES } from "../../../../../components/documents/_CONSTANTS/constants";
import { getTableTotals } from "../../../../../utils/getTableTotals";
import { ErrorFallback } from "../../../../error-boundary/ErrorFallBack";
import { checkIsDocVerified } from "../functions";
import InfoTab from "./InfoTab";
import TableProducts from "./TableProducts";

const Main = (props) => {
  const { messageApi, modalApi } = useOutletContext();
  const { createdFromAnotherSource, disabledFields } = props;
  const navigate = useNavigate();

  const action = props?.action;
  const documentForEdit = props?.document;

  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [supplier, setSupplier] = useState();
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (documentForEdit) {
      setSupplier(documentForEdit.supplierGuid);
      setComment(documentForEdit.comment);
    }
  }, [documentForEdit]);

  const handleMenuClick = async () => {
    modalApi.confirm({
      title: "Не зберігати?",
      onCancel: () => {
        return;
      },
      onOk: async () => {
        const clearedCurrentOrder = await clearObjectStore(
          TABLES.CURRENT_VERIFICATION
        );
        if (!clearedCurrentOrder) {
          return messageApi.error("Помилка очищення документа");
        }
        props?.setProducts([]);
        navigate(-1);
      },
      okText: "Ні",
    });
  };

  const items = [
    {
      label: (
        <Space>
          <StopOutlined />
          Скасувати
        </Space>
      ),
      key: "1",
      onClick: handleMenuClick,
    },
  ];

  const handleButtonClick = async () => {
    if (!supplier) {
      return modalApi.confirm({
        title: "Контрагент не вибраний, продовжити?",
        onCancel: () => {
          return;
        },
        onOk: () => {
          saveDocumentHadler();
        },
      });
    }
    saveDocumentHadler();
  };

  const saveDocumentHadler = async () => {
    setLoadingSaveButton(true);

    if (props.products.length === 0) {
      setLoadingSaveButton(false);
      return messageApi.error("Додайте товари");
    }

    // перевірити розбіжність по товарах, якщо немає, тоді зберегти, якщо є, тоді повідомлення
    const docVerified = await checkIsDocVerified(props.products);
    const newDocument = {
      date: dayjs().format("DD.MM.YYYY"),
      created: dayjs(),
      updated: dayjs(),
      products: props.products,
      sum: getTableTotals(props.products).total,
      comment,
      verified: docVerified,
      processed: true,
      docBarcode: generateDocBarcode(),
      supplierGuid: supplier,
    };

    let saved = false;
    if (createdFromAnotherSource) {
      saved = await addRecord(TABLES.VERIFICATION, {
        ...newDocument,
        sourceGuid: documentForEdit.guid,
      });
    } else {
      saved = await updateRecord(
        TABLES.VERIFICATION,
        Number(props.document.id),
        newDocument
      );
    }

    if (!saved) {
      setLoadingSaveButton(false);
      return messageApi.error("Помилка збереження документу");
    }
    const clearedCurrentVerification = await clearObjectStore(
      TABLES.CURRENT_VERIFICATION
    );
    if (!clearedCurrentVerification) {
      messageApi.error("Помилка очищення поточного документа");
      return false;
    }
    messageApi.success("Оновлено!");
    setLoadingSaveButton(false);

    return navigate(ROUTES.VERIFICATION.LIST);
  };

  const operations = !disabledFields && (
    <>
      <Dropdown.Button
        loading={loadingSaveButton}
        size="middle"
        icon={<DownOutlined />}
        menu={{
          items,
        }}
        onClick={handleButtonClick}
      >
        <SaveOutlined /> Зберегти
      </Dropdown.Button>
    </>
  );

  const OperationsSlot = {
    right: operations,
  };

  const deftabs = [
    {
      label: TABS_TITLES.INFO,
      key: "1",
      children: (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <InfoTab
            supplier={supplier}
            setSupplier={setSupplier}
            comment={comment}
            setComment={setComment}
            document={documentForEdit}
            disabledFields={disabledFields || createdFromAnotherSource}
          ></InfoTab>
        </ErrorBoundary>
      ),
    },
    {
      label: TABS_TITLES.PRODUCTS,
      key: "2",
      children: (
        <ErrorBoundary FallbackRender={ErrorFallback}>
          <TableProducts
            loading={props.loading}
            products={props.products}
            setProducts={props.setProducts}
            action={action}
            settings={props.settings}
            afterChangeProductQty={props.afterChangeProductQty}
            removeProductFromDocument={props.removeProductFromDocument}
            dbTable={props.dbTable}
            createdFromAnotherSource={documentForEdit ? true : false}
          ></TableProducts>
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <>
      <Tabs
        tabBarStyle={{ marginBottom: "5px" }}
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="2"
        size={"middle"}
        items={deftabs}
      />
    </>
  );
};
export default Main;
