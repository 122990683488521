import { Modal, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { exportDocumentToTxt } from "../../api/Export/txt/export";
import { exportDocument } from "../../api/Export/xml/export";
import { updateRecords } from "../../db/dexie-db/functions";
import { filterNotExportedDocuments } from "../../db/documents-funtions";
import { useAuth } from "../../hook/useAuth";

export default function ExportModal(props) {
  const { messageApi } = useOutletContext();
  const { user, signOut } = useAuth();
  const [progress, setProgress] = useState(0);
  const [isExportSuccess, setIsExportSuccess] = useState();

  useEffect(() => {
    if (props.isModalExportOpen) {
      const documentsToExport = props.documentsToExport;
      exportData(documentsToExport);
    }
  }, [props.isModalExportOpen]);

  const exportData = async (todayDocumentsForExport) => {
    for (let index = 0; index < todayDocumentsForExport.length; index++) {
      const docForExport = todayDocumentsForExport[index];
      let result = {};
      if (user.exchangeType === "xml") {
        result = await exportDocument(docForExport, props.docTypeData, user);
      } else {
        result = await exportDocumentToTxt(
          docForExport,
          props.docTypeData,
          user
        );
      }

      if (result.status === "failed") {
        setProgress(0);
        setIsExportSuccess(false);
        props.setIsModalExportOpen(false);
        return messageApi.error("Помилка експорту. " + result.message);
      }
      let persent = parseInt(
        (index + 1 * 100) / todayDocumentsForExport.length
      );
      setProgress(persent);
    }
    setIsExportSuccess(true);
    props.setIsModalExportOpen(false);
  };

  const afterExportFunction = async (
    setLoadingExportButton,
    exportSuccess,
    documentsToExportOriginal,
    DOCUMENT_TABLE,
    setDocuments,
    getFormattedDocuments,
    documents
  ) => {
    setLoadingExportButton(false);
    if (!exportSuccess) {
      return messageApi.error("Помилка експорту документів!");
    }
    messageApi.success("Всі документи успішно відправлено!");
    const exportedDocuments = documentsToExportOriginal.map((exportedDoc) => {
      // exportedDoc має не всі параметри
      return { ...exportedDoc, exported: "true" };
    });
    const updated = await updateRecords(DOCUMENT_TABLE, exportedDocuments);
    if (!updated) {
      return messageApi.error("Помилка оновлення документів");
    }
    const notExportedDocuments = filterNotExportedDocuments(
      exportedDocuments,
      documents
    );

    const formattedDocuments = await getFormattedDocuments([
      ...notExportedDocuments,
      ...exportedDocuments,
    ]);
    setDocuments(formattedDocuments);
  };

  return (
    <>
      <Modal
        afterClose={() =>
          afterExportFunction(
            props.setLoadingExportButton,
            isExportSuccess,
            props.documentsToExportOriginal,
            props.DOCUMENT_TABLE,
            props.setDocuments,
            props.getFormattedDocuments,
            props.documents
          )
        }
        title={"Експорт документів"}
        closable={false}
        footer={null}
        open={props.isModalExportOpen}
        onCancel={() => props.setIsModalExportOpen(false)}
      >
        <Progress
          style={{ width: "50%" }}
          percent={progress}
          format={(percent) => `${percent}% Відправлено`}
          // strokeColor={"#001529"}
          strokeLinecap={"butt"}
        />
      </Modal>
    </>
  );
}
